import React from 'react'
import { Form, Breadcrumb, Card, Affix, Button, Spin, Modal, Input, Radio, message, Divider, Tooltip } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, ProfileOutlined } from '@ant-design/icons'
import dateFns from 'date-fns'
import './ManageLocalThemesPage.scss'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext, useMemo } from 'react'
import { FrontlinesContext } from '../../store/frontlines'
import { TranslationsContext } from '../../store/translations'
import { createErrorMessage } from '../../utils/error-utils'


const THEME_BG_COLORS = [
  '#C6D66C', // light green
  '#3B4245', // black
  '#F6A800', // orange
  '#004987', // navy blue
  '#86C2E6', // light blue
]

function ManageLocalThemesPage(props) {
  const { match, history } = props

  const {
    frontline,
    loadFrontline,
    updateLocalTheme,
    createLocalTheme,
    deleteLocalTheme,
  } = useContext(FrontlinesContext)

  const [ modalOpen, setModalOpen ] = useState(false)
  const [ modalOptions, setModalOptions ] = useState({})
  const [ themeToEdit, setThemeToEdit ] = useState(null)
  const [ savingTheme, setSavingTheme ] = useState(false)

  const { getText } = useContext(TranslationsContext)

  const { country, productId } = match.params

  const productName = getText(`product-${productId}`)

  const { pathname } = history.location
  
  const themes = useMemo(() => {
    if (!frontline) return
    const { themes = {} } = frontline

    return themes[productId] || []
  }, [frontline])
  

  useEffect(() => {
    if (country.toLowerCase() === 'global') {
      history.push('/')
      return
    }

    loadFrontline({ country })
      .then(() => {}, err => {
        console.error('Failed to load frontline:', err)
        history.push('/')
      })
  }, [country])

  const createOrUpdateTheme = async (theme) => {
    setSavingTheme(true)

    if (themeToEdit) {
      try {
        await updateLocalTheme(frontline, {
          newValues: theme, 
          themeId: themeToEdit.id, 
          productId
        })
        
      } catch (err) {
        const errorMessage = createErrorMessage(err, {
          attemptedOperation: 'Updating a theme',
          attemptedItem: 'frontline'
        })

        message.error(errorMessage, 5)
        setSavingTheme(false)
        throw (err)
      }
    } else {
      try {
        await createLocalTheme(frontline, { theme, productId }) 
      } catch (err) {
        const errorMessage = createErrorMessage(err, {
          attemptedOperation: 'Creating a theme',
          attemptedItem: 'frontline'
        })

        message.error(errorMessage, 5)
        setSavingTheme(false)
        throw(err)
      }
    }
    setSavingTheme(false)
  }

  const handleDelete = async ({ productId, themeId }) => {
    try {
      await deleteLocalTheme(frontline, { productId, themeId })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Deleting the theme',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  const confirmDelete = (e, theme) => {
    e.stopPropagation()

    Modal.confirm({
      title: `Are you sure you want to delete the theme "${theme.name}"?`,
      content: 'This action will permanently delete the theme and all of its designs.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleDelete({ productId, themeId: theme.id })
      },
    })
  }

  const openModal = (options) => {
    setModalOptions(options)
    setModalOpen(true)
  }

  const attemptClosing = () => {
    if (savingTheme) return false
    setModalOptions({})
    setModalOpen(false)
    setThemeToEdit(null)
    return true
  }

  const onEditClick = (theme) => {
    setThemeToEdit(theme)
    openModal({
      title: 'Edit theme',
      theme,
    })

  }

  if (!themes) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  
  return (
    <div className="ManageLocalThemesPage page">
       <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
          <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/products`}>Products</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/product/${productId}`}>{productName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Local themes
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">Local themes for {productName}</h1>
      <Affix style={{ textAlign: 'right' }} offsetTop={80}>
        <Button 
          onClick={() => openModal({ 
            title: "New theme",
          })}
          type="primary" 
          icon={<PlusOutlined />}>Create a theme</Button>
      </Affix>
      <div className="block">
        <h2 className="block__title">
          Local themes
        </h2>
        <div className="cards-container">
          {
            themes.length === 0 ? <p>No local themes created yet</p> :
            themes.map(theme => {
              return (
                <Card 
                  className="card theme-card" 
                  key={theme.id} 
                  title={theme.name}
                  actions={[
                    <Tooltip title="Manage designs">
                      <Link to={`local-theme/${theme.id}/designs`}>
                        <ProfileOutlined key="designs" />
                      </Link>
                    </Tooltip>,
                    <Tooltip title="Edit theme">
                      <EditOutlined 
                      onClick={() => onEditClick(theme)}
                      key="edit" />
                    </Tooltip>,
                    <Tooltip title="Delete theme">
                      <DeleteOutlined 
                      onClick={(e) => confirmDelete(e, theme)} key="delete" />
                    </Tooltip>,
                  ]}
                  extra={<div className="color-block"
                    style={{backgroundColor: theme.bgColor}}
                  ></div>}
                >
                  <p className="theme-description">{theme.description}</p>
                  <Divider />
                  <p>Last modified: {dateFns.format(new Date(theme.lastModified), 'MMMM Do, YYYY, HH:mm')}</p>
                  { theme.designs.length === 1 ?
                  <p className="theme-designs">{theme.designs.length} design</p> :
                  <p className="theme-designs">{theme.designs.length} designs</p>
                  }
                  {/* <div className="buttons-container">
                    <Button onClick={(e) => confirmDelete(e, theme)} type="link">Delete</Button>
                    <Button type="link" onClick={() => onEditClick(theme)}>Edit theme</Button>
                  </div> */}
                </Card>
              )
            })
          }
        </div>
      </div>
      <Modal
        title={modalOptions.title}
        visible={modalOpen}
        footer={null}
        maskClosable={true}
        className="new-theme-modal"
        destroyOnClose={true}
        onCancel={() => attemptClosing()}
      >
        <NewThemeForm theme={modalOptions.theme} onSubmit={createOrUpdateTheme} onClose={() => attemptClosing()} />
      </Modal>
    </div>
  )
}

const NewThemeForm = ({ theme, onClose, onSubmit }) => {
  const [form] = Form.useForm()
  const [ loading, setLoading ] = useState(false)

  const handleSubmit = async (fieldsValues) => {
    setLoading(true)

    try {
      await onSubmit(fieldsValues)
      setLoading(false)
      form.resetFields()
      onClose()
    } catch (err) {
      console.log('>>> err', err)
      setLoading(false)
    }
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Form form={form} onFinish={handleSubmit} 
      initialValues={{
        name: theme ? theme.name : '',
        description: theme ? theme.description : '',
        bgColor: (theme && theme.bgColor) ? theme.bgColor : THEME_BG_COLORS[0]
      }}
      className="new-theme-form">
      <Form.Item 
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Theme name is required!' }]}
        >
          <Input
            placeholder="Theme name"
            maxLength={30}
            style={{ width: "16rem" }}
          />
      </Form.Item>
      <Form.Item 
        label="Description"
        name="description"
        rules={[{ required: true, message: 'Description is required!' }]}
      >
          <Input.TextArea
            placeholder="Description"
            maxLength={220}
          />
      </Form.Item>
      <Form.Item 
        label="Background color"
        name="bgColor"
        rules={[{ required: true, message: 'Color is required!' }]}
        >
          <Radio.Group className="color-group">
            {
              THEME_BG_COLORS.map(color => {
                return (
                  <Radio.Button 
                    key={color} 
                    style={{backgroundColor: color}} 
                    className="color-group__option" 
                    value={color}>
                    
                  </Radio.Button>
                )
              })
            }
          </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button disabled={loading} style={{marginRight: "1rem"}} onClick={onCancel} className="login-form-button">
          Cancel
        </Button>
        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
          {theme ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageLocalThemesPage