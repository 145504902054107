import React, { useState, useEffect } from 'react'
import { message, Tabs } from 'antd'
import Sections from './sections'
import useService from '../../services/useService.js'
import './APIDashboardPage.scss'

/* const tabs = [
  {
    id: 'analysis',
    label: 'Analysis'
  },
  {
    id: 'image-gen',
    label: 'Image Generator',
    adminOnly: true
  }
] */

function APIDashboardPage(props) {
  const logService = useService('log')
  const [availableProducts, setAvailableProducts] = useState()
  // const { adminRights } = useContext(AuthContext)

  // const params = useParams()
  // const { tab } = params
  // const availableTabs = adminRights ? tabs : tabs.filter(x => !x.adminOnly)

  useEffect(() => {
    logService.getKTOCProducts().then(result => {
      setAvailableProducts(result)
    }).catch(err => {
      console.error(err)
      message.error('Failed to get available products', 5)
    })
  }, [])

  return (
    <div className="APIDashboardPage page">
      <h1 className="page__title">API dashboard</h1>

      <Tabs animated={false}>
        <Tabs.TabPane tab="Mobile application" key="1">
          <Sections.Mobile className="block" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="KTOC" key="2">
          <Sections.KTOC
            className="block"
            availableProducts={availableProducts}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="GenDoc units" key="3">
          <Sections.GenDoc
            className="block"
            availableProducts={availableProducts}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="RICK data" key="4">
          <Sections.RICK title="RICK data (elevators)" filter={item => item.projectType === 'elevator'} />
          <Sections.RICK title="RICK data (escalators)" filter={item => item.projectType === 'escalator'}  />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Leads" key="5">
          <Sections.Salesforce title="Leads" />
        </Tabs.TabPane>
      </Tabs>
      {/* <Sections.Web className="block" /> */}
    </div>
  )
}

export default APIDashboardPage