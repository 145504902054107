import React, { useState } from 'react'
import { Card, Checkbox } from 'antd'
import CheckboxGroup from '../../../components/CheckboxGroup'
import { useComponentSelectionError } from './tab-component-utils'

const ModelAndLayout = ({ initRestrictions = {}, product, setRestrictions, disableSaving, managingGlobal, hideDisabled, title }) => {
  const [modelAndLayoutRestrictions, setModelAndLayoutRestrictions] = useState(initRestrictions)

  const { handleError } = useComponentSelectionError(disableSaving)

  // Set restrictions for this element.
  const handleChange = (checkedValues, name) => {
    const restrictions = { ...modelAndLayoutRestrictions }
    restrictions[name] = checkedValues
    setModelAndLayoutRestrictions(restrictions)
    setRestrictions(restrictions)
  }

  const { regulations = [], carshapes = [], cartypes = [] } = product.componentsData

  return (
    <Card className="ModelAndLayout component-card">
      <h2 className="component-card__title">{title}</h2>
      {
        regulations.length > 0 &&
        <CheckboxGroup
          title="KONE accessibility pack"
          items={regulations}
          name="regulations"
          setError={handleError}
          handleChange={handleChange}
          defaultValue={modelAndLayoutRestrictions.regulations}
          managingGlobal={managingGlobal}
        />
      }
      <CheckboxGroup
        title="Car shapes"
        items={carshapes.map(x => {
          return ({
            ...x,
            stringid: `ui-layout-size-${x.id.toLowerCase()}`
          })
        })}
        requireSelection={true}
        setError={handleError}
        name="carshapes"
        handleChange={handleChange}
        defaultValue={modelAndLayoutRestrictions.carshapes}
        managingGlobal={managingGlobal}
      />
      <CheckboxGroup
        title="Car types"
        items={cartypes}
        name="cartypes"
        requireSelection={true}
        setError={handleError}
        handleChange={handleChange}
        defaultValue={modelAndLayoutRestrictions.cartypes}
        managingGlobal={managingGlobal}
      />
    </Card>
  )
}

export default ModelAndLayout