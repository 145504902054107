import React, { useState, useContext } from 'react'
import { Switch, message } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import "./GlobalFrontlinePage.scss"

import ProductSelection from '../../components/ProductSelection'

import { FrontlinesContext } from '../../store/frontlines';
import { createErrorMessage } from '../../utils/error-utils'
import WelcomePageManager from '../../components/WelcomePageManager';
import VersionManager from '../../components/VersionManager';
import { Link } from 'react-router-dom';
import AddressFieldManager from '../../components/AddressFieldManager';

const GlobalFrontlinePage = ({ history }) => {

  const {
    globalFrontline,
    saveVersionState,
    saveAddressState,
    saveWelcomePageUIState,
    allProducts,
    save3DDesignerActiveState,
    saveExistingBuildingsUIState
  } = useContext(FrontlinesContext)

  const [ toggleLoading, setToggleLoading ] = useState(false)

  const handle3DToggle = async (checked, e) => {
    try {
      await save3DDesignerActiveState(globalFrontline, checked)
      const successMessage = checked ? `3D Car Designer enabled for global domains.` : `3D Car Designer disabled for global domains. Using 2D Car Designer instead.`
      message.success(successMessage)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      console.log(">err", err)

      message.error(errorMessage, 5)
    }
  }

  async function handleExistingBuildingsUIToggle(checked) {
    try {
      setToggleLoading(true)
      await saveExistingBuildingsUIState(checked)
      setToggleLoading(false)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      console.log(">err", err)

      message.error(errorMessage, 5)
      setToggleLoading(false)
    }
  }
 

  return (
    <div className="GlobalFrontlinePage page">
      <h1 className="page__title">Manage the global frontline settings
      <Switch
          onChange={handle3DToggle}
          checked={!!globalFrontline.active3D}
          checkedChildren={<span>3D</span>}
          unCheckedChildren={<span>2D</span>}
        />
      </h1>
      <div className="top-container">
        <Link to={`${history.location.pathname}/update_all`}>Update all products</Link>
        <Link style={{marginLeft: '2rem'}} to={`/releases`}>Manage release versions</Link>
        <div className="toggle-container">
          <span className="toggle-label">Display existing buildings flow:</span>
          <Switch
            onChange={handleExistingBuildingsUIToggle}
            checked={globalFrontline.displayExistingBuildingsUI}
            loading={toggleLoading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </div>
      </div>
      <div className="block">
        <ProductSelection
          frontline={globalFrontline}
          managingGlobal={true}
          admin={true}
          allProducts={allProducts}
        />
      </div>
      <div className="block">
        <h2 className="block__title">Welcome page sections' visibility</h2>
        <WelcomePageManager 
          frontline={globalFrontline}
          saveWelcomePageUIState={saveWelcomePageUIState}
        />
      </div>
      <div className="block">
        <h2 className="block__title">Application versions</h2>
        <VersionManager 
          globalFrontline={globalFrontline}
          saveVersionState={saveVersionState}
        />
      </div>
      <div className="block">
        <h2 className="block__title">Address fields</h2>
        <AddressFieldManager
          frontline={globalFrontline}
          saveAddressState={async args => {
            await saveAddressState({
            ...args,
            frontline: globalFrontline,
          })
          }}
        />
      </div>
    </div>
  )
}

export default GlobalFrontlinePage