import React from 'react'

import './ErrorPage.scss'

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null }
  }

  componentDidCatch(error, info) {
    this.setState({ error: error })
    console.trace(error, info)
  }

  render() {
    // console.log('>>> this.state.error', this.state.error)
    if(this.state.error) return (
      <div className="ErrorPage page">
          <h2>Unexpected error has occured</h2>
          { this.state.error.message &&
            <div className="error-container">
              <p>"{this.state.error.message}"</p>
              <br/>
              {
                this.state.error.stack &&
                <p>{this.state.error.stack}</p>
              }
            </div>
          }
          <a href="/">Home</a>
      </div>
    )
    else return this.props.children
  }
}

export default ErrorPage