import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash'
import useService from '../../services/useService'
import { APIContext } from '../api';

export const FrontlinesContext = React.createContext();

export const FrontlinesProvider = ({ children }) => {
  const [frontline, _setFrontline] = useState()
  const [frontlines, setFrontlines] = useState([])
  const [ globalFrontline, _setGlobalFrontline ] = useState()
  const [ allProducts, setAllProducts ] = useState()


  const frontlineService = useService('frontline')
  const themeService = useService('theme')
  const userService = useService('user')
  const productService = useService('product')
  const packageService = useService('package')
  const translationService = useService('translation')

  function setFrontline(fl) {
    if (!fl) return

    if (fl.id === 'global') {
      _setGlobalFrontline(fl)
    } else {
      _setFrontline(fl)
    }
  }

  useEffect(() => {
    frontlineService.getGlobal().then(result => {
      _setGlobalFrontline(result)
    }, err => {
      console.error(err)
      throw Error("Unable to load global frontline settings.")
    })

    frontlineService.getAllProducts().then(products => {
      setAllProducts(products)
    }, err => {
      console.error(err)
      throw Error('Unable to load global product data')
    })
  }, [])

  async function loadFrontlines() {
    const result = await userService.getOwnedFrontlines()
    setFrontlines(result)
    return result
  }

  async function loadFrontline({ country }) {
    const result = await frontlineService.getOneByCountry(country)
    setFrontline(result)
    return result
  }

  async function loadGlobalOffering({ productId, releaseId }) {
    const result = await frontlineService.loadGlobalOffering({ productId, releaseId })
    setFrontline(result)
    return result
  }

  function loadUsersForFrontline(frontline) {
    return frontlineService.getUsersForFrontline(frontline)
  }

  async function saveFeedbackBannerSettings(settings) {
   
    const result = await frontlineService.setFeedbackBannerSettings(settings)
    setFrontline(result)
    return result
  }
  async function saveEnableHighResSettings(settings) {
    const result = await frontlineService.setHighResSettings(settings)
    setFrontline(result)
    return result
  }

  async function saveReleaseRights({ state }) {
    const result = await frontlineService.setReleaseRights(state)
    setFrontline(result)
    return result
  }

  async function saveReleaseStatus(args) {
    const result = await frontlineService.setReleaseStatus(args)
    setFrontline(result)
    return result
  }

  async function saveServeOriginalState(args) {
    const result = await frontlineService.setServeOriginalState(args)
    setFrontline(result)
    return result
  }

  /**
   *
   * @param {Object} args 
   * @param {string} args.country 
   * @param {string} args.groupName 
   * @param {string} args.releaseId 
   * @param {boolean} args.selectableForKonePeople 
   */ 
  async function saveReleaseVisibility(args) {
    const result = await frontlineService.setReleaseVisibility(args)
    setFrontline(result)
    return result
  }

  /**
   * 
   * @param {Object} args 
   * @param {string} args.country 
   * @param {string} args.groupName 
   * @param {string} args.releaseId 
   */ 
  async function saveDefaultRelease(args) {
    const result = await frontlineService.setDefaultRelease(args)
    setFrontline(result)
    return result
  }

  async function saveWelcomePageUIState(frontline, options) {
    const result = await frontlineService.setWelcomePageUIState(frontline, options)
    setFrontline(result)
    return result
  }

  function saveServiceURL(frontline, options) {
    // Not setting the frontline state here because that would wipe any restrictions set by the user
    // before saving...
    // Logic is wonky because the url is in the offering management page and it was only designed to
    // handle the offering related logic.
    return frontlineService.saveServiceURL(frontline, options) 
  }

  /**
   * Saves a new frontline to the database and adds the created frontline to frontlines array state.
   * @param {string} frontlineName frontline name.
   */
  async function createFrontline(frontlineName) {
    const result = await frontlineService.create(frontlineName)
    setFrontlines([...frontlines, result])
    return result
  }

  async function addLanguagesForFrontline(fl, languages) {
    const result = await frontlineService.addLanguages(fl, languages)
    setFrontline(result)
    return result
  }

  async function removeLanguageFromFrontline(fl, code) {
    const result = await frontlineService.removeLanguage(fl, code)
    setFrontline(result)
    return result
  }

  async function saveDefaultLanguageForFrontline(fl, code) {
    const result = await frontlineService.setDefaultLanguage(fl, code)
    setFrontline(result)
    return result
  }

  function loadDomainData() {
    return frontlineService.getDomainData()  
  }

  /**
   * 
   * @param {string[]} languages - List of language codes of languages to enable
   */
  async function saveAvailableLanguages(languages) {
    const result = await frontlineService.setAvailableLanguages(languages)
    setFrontline(result)
    return result
  }

  const publishTranslation = async (code, hiddenId) => {
    const result = await translationService.publish(code, hiddenId)
    const { translation, frontline: newFrontline } = result
    setFrontline(newFrontline)

    return {translation, frontline: newFrontline }
  }
  

  const updateRestrictionsForProduct = async (frontline, productId, restrictions, releaseId) => {
      const result = await frontlineService.updateRestrictionsForProduct(frontline, productId, restrictions, releaseId)
      
      setFrontline(result.frontline)

      return result
  }

  const save3DDesignerActiveState = async (frontline, active) => {
    const result = await frontlineService.set3DActiveState(frontline, active)
    setFrontline(result)
    return result
  }

  const saveExistingBuildingsUIState = async (shouldDisplay) => {
    const result = await frontlineService.setDisplayExistingBuildings(shouldDisplay)
    setFrontline(result)
    return result
  } 

  async function getUsersForFrontline(frontline) {
    const result = await frontlineService.getUsersForFrontline(frontline)
    return result
  }

  const releaseProduct = async (frontline, productId, releaseId) => {
    const result = await productService.release(frontline.hiddenId, productId, releaseId)
    const { frontline: newFrontline } = result

    setFrontline(newFrontline)

    return result
  }

  const givePublishingRights = async (frontline, code) => {
    const newFrontline = await translationService.givePublishingRights(frontline, code)
    setFrontline(newFrontline)
    return newFrontline
  }

  const revokePublishingRights = async (frontline, code) => {
    const newFrontline = await translationService.revokePublishingRights(frontline, code)
    setFrontline(newFrontline)
    return newFrontline
  }

  const addProduct = async (frontline, productId) => {
    const newFrontline = await frontlineService.addProduct(frontline, productId)

    setFrontline(newFrontline)

    return newFrontline
  }

  const removeProduct = async (frontline, productId) => {
    const newFrontline = await frontlineService.removeProduct(frontline, productId)

    setFrontline(newFrontline)

    return newFrontline
  }

  const setIsKoneOnly = async (frontline, productId, isKoneOnly) => {
    const newFrontline = await frontlineService.setIsKoneOnly(frontline, productId, isKoneOnly)

    setFrontline(newFrontline)

    return newFrontline 
  }

  const saveDisabledState = async (frontline, args, disabled) => {
    const newFrontline = await frontlineService.setDisabledState(frontline, args, disabled)

    setFrontline(newFrontline)

    return newFrontline
  }

  const saveFullReplacementStatus = async (frontline, product, newState) => {
    const newFrontline = await frontlineService.setFullReplacementState(frontline, product, newState)

    setFrontline(newFrontline)

    return newFrontline
  }

  const releaseLocalThemes = async (frontline) => {
    const result = await themeService.releaseAll(frontline)

    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {string} options.themeId
   * @param {string} options.designId
   * @param {string} options.newName
   */
  const updateLocalDesignName = async (frontline, options) => {
    const result = await themeService.updateDesignName(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {string} options.themeId
   * @param {string} options.designId
   */
  const removeDesignFromLocalTheme = async (frontline, options) => {
    const result = await themeService.removeDesignFromTheme(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {string} options.themeId
   * @param {string} options.designURL
   */
  const addDesignToLocalTheme = async (frontline, options) => {
    const result = await themeService.addDesignToTheme(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {string} options.themeId
   */
  const deleteLocalTheme = async (frontline, options) => {
    const result = await themeService.deleteTheme(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {string} options.themeId
   * @param {Object} options.newValues
   */
  const updateLocalTheme = async (frontline, options) => {
    const result = await themeService.updateTheme(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * @param {Object} frontline
   * @param {Object} frontline.country
   * @param {string} frontline.country.name
   * @param {Object} options
   * @param {string} options.productId
   * @param {Object} options.theme
   */
  const createLocalTheme = async (frontline, options) => {
    const result = await themeService.createTheme(frontline, options)
    setFrontline(result)
    return result
  }

  /**
   * 
   * @param {Object} frontline 
   * @param {Object} options 
   * @param {string} options.group
   * @param {string[]} options.predesigns
   */
  const saveAvailablePredesigns = async (frontline, options) => {
    const result = await frontlineService.setPredesigns(frontline, options)
    setFrontline(result.frontline)
    return result
  }

  const setLanguageMobileVisibility = async (params) => {
    const result = await translationService.setMobileVisibility(params)
    setFrontline(result)
    return result
  } 

  const saveVersionState = async (params) => {
    const result = await frontlineService.saveVersionState(params)
    setFrontline(result)
    return result
  }

  const addAddressFieldGroup = async (params) => {
    const result = await frontlineService.addAddressFieldGroup(params)
    setFrontline(result)
    return result
  }

  const removeAddressFieldGroup = async (params) => {
    const result = await frontlineService.removeAddressFieldGroup(params)
    setFrontline(result)
    return result
  }

  const saveAddressState = async (params) => {
    const result = await frontlineService.saveAddressState(params)
    setFrontline(result)
    return result
  }

  const publishVersionState = async (params) => {
    const result = await frontlineService.publishVersionState(params)
    setFrontline(result)
    return result
  }

  async function deleteFrontline(fl) {
    const result = await frontlineService.deleteFrontline(fl)
    setFrontline(null)
    return result
  }

  return (
    <FrontlinesContext.Provider value={{
      frontline, frontlines, globalFrontline, allProducts, loadFrontlines, loadFrontline, releaseProduct, loadUsersForFrontline,
      createFrontline, publishTranslation, saveAvailableLanguages, saveDefaultLanguageForFrontline, getUsersForFrontline, updateRestrictionsForProduct,
      save3DDesignerActiveState,
      loadGlobalOffering,
      saveExistingBuildingsUIState,
      saveReleaseRights,
      addLanguagesForFrontline, removeLanguageFromFrontline, givePublishingRights, revokePublishingRights, setIsKoneOnly, saveDisabledState, addProduct, removeProduct,
      loadDomainData,
      saveWelcomePageUIState,
      createLocalTheme,
      updateLocalTheme,
      deleteLocalTheme,
      releaseLocalThemes,
      addDesignToLocalTheme,
      removeDesignFromLocalTheme,
      updateLocalDesignName,
      saveAvailablePredesigns,
      saveServiceURL,
      setLanguageMobileVisibility,
      saveVersionState,
      saveAddressState,
      publishVersionState,
      saveFullReplacementStatus,
      saveReleaseStatus,
      saveServeOriginalState,
      saveReleaseVisibility,
      saveDefaultRelease,
      addAddressFieldGroup,
      removeAddressFieldGroup,
      deleteFrontline,
      saveFeedbackBannerSettings,
      saveEnableHighResSettings
    }}>
      {globalFrontline && children}
    </FrontlinesContext.Provider>
  )
}
export default FrontlinesProvider;

export const FrontlinesConsumer = FrontlinesContext.Consumer;

export function useFrontlinesContext() {
  return useContext(FrontlinesContext)
}
