import React, { useState, useEffect, useContext } from 'react'
import { Card, Checkbox, Tabs } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import { useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'
import { useFrontline } from '../../../../store/frontlines/useFrontline'
import URLInput from '../../../../components/URLInput/URLInput'
import { FrontlinesContext } from '../../../../store/frontlines'

const { TabPane } = Tabs

const Accessories = (props) => {
  const {
    initRestrictions = {},
    setRestrictions, disableSaving, product, managingGlobal,
    title
  } = props

  const { getText } = useContext(TranslationsContext)

  const frontlineCtx = useContext(FrontlinesContext)
  const frontline = useFrontline()

  const [
    accessoriesRestrictions,
    setAccessoriesRestrictions
  ] = useState(initRestrictions.accessories || [])
 
  const [
    mirrorsRestrictions,
    setMirrorsRestrictions
  ] = useState(initRestrictions.mirrors || {})

  const [
    handrailsRestrictions,
    setHandrailsRestrictions
  ] = useState(initRestrictions.handrails || {})

  const [
    skirtingsRestrictions,
    setSkirtingsRestrictions
  ] = useState(initRestrictions.skirtings || {})

  const [
    bufferRailsRestrictions,
    setBufferRailsRestrictions
  ] = useState(initRestrictions.bufferRails || {})

  const [
    infoAndMediaScreensRestrictions,
    setInfoAndMediaScreensRestrictions
  ] = useState(initRestrictions.infoAndMediaScreens || {})

  const [
    seatsRestrictions,
    setSeatsRestrictions
  ] = useState(initRestrictions.seats || {})

  const [
    tenantDirectoriesRestrictions,
    setTenantDirectoriesRestrictions
  ] = useState(initRestrictions.tenantDirectories || {})

  const [
    airPurifierRestrictions,
    setAirPurifierRestrictions
  ] = useState(initRestrictions.airPurifier || {})

  const { handleError, errors } = useComponentSelectionError(disableSaving)

  const accessoriesData = product.componentsData.accessories

  useEffect(() => {
    setRestrictions({
      accessories: accessoriesRestrictions,
      mirrors: mirrorsRestrictions,
      handrails: handrailsRestrictions,
      skirtings: skirtingsRestrictions,
      bufferRails: bufferRailsRestrictions,
      infoAndMediaScreens: infoAndMediaScreensRestrictions,
      seats: seatsRestrictions,
      tenantDirectories: tenantDirectoriesRestrictions,
      airPurifier: airPurifierRestrictions,
    })
  }, [
    accessoriesRestrictions,
    mirrorsRestrictions,
    handrailsRestrictions,
    skirtingsRestrictions,
    bufferRailsRestrictions,
    infoAndMediaScreensRestrictions,
    seatsRestrictions,
    tenantDirectoriesRestrictions,
    airPurifierRestrictions
  ])

  function toggleAccessoryTypeRestriction(type) {
    setAccessoriesRestrictions(prev => {
      if (prev.includes(type)) {
        return prev.filter(x => x !== type)
      }

      return [...prev, type]
    })
  }

  const handleAccessoriesChange = (checkedValues, name) => {
    setAccessoriesRestrictions(checkedValues)
  }

  const handleMirrorsChange = (checkedValues, name) => {
    const restrictions = { ...mirrorsRestrictions }
    restrictions[name] = checkedValues
    setMirrorsRestrictions(restrictions)
  }

  const handleSkirtingsChange = (checkedValues, name) => {
    const restrictions = { ...skirtingsRestrictions }
    restrictions[name] = checkedValues
    setSkirtingsRestrictions(restrictions)
  }

  const handleBufferRailsChange = (checkedValues, name) => {
    const restrictions = { ...bufferRailsRestrictions }
    restrictions[name] = checkedValues
    setBufferRailsRestrictions(restrictions)
  }

  const handleInfoAndMediaScreensChange = (checkedValues, name) => {
    const restrictions = { ...infoAndMediaScreensRestrictions }

    if (!restrictions.models) restrictions.models = {}
    if (!restrictions.models[name]) restrictions.models[name] = {}

    restrictions.models[name].components = checkedValues
    setInfoAndMediaScreensRestrictions(restrictions)
  }

  const handleInfoAndMediaScreensPositionChange = (checkedValues, name) => {
    const restrictions = { ...infoAndMediaScreensRestrictions }

    restrictions[name] = checkedValues
    setInfoAndMediaScreensRestrictions(restrictions)
  }

  const handleInfoAndMediaScreensCheckAllChange = (name, checked) => {
    const restrictions = { ...infoAndMediaScreensRestrictions }

    if (!restrictions.models) restrictions.models = {}
    if (!restrictions.models[name]) restrictions.models[name] = {}

    restrictions.models[name].disabled = checked
    setInfoAndMediaScreensRestrictions(restrictions)
  }

  const handleSeatsChange = (checkedValues, name) => {
    const restrictions = { ...seatsRestrictions }
    restrictions[name] = checkedValues
    setSeatsRestrictions(restrictions)
  }

  const handleTenantDirectoriesChange = (checkedValues, name) => {
    const restrictions = { ...tenantDirectoriesRestrictions }
    restrictions[name] = checkedValues
    setTenantDirectoriesRestrictions(restrictions)
  }

  const handleHandrailsPositionChange = (checkedValues, name) => {
    const restrictions = { ...handrailsRestrictions }
    restrictions[name] = checkedValues
    setHandrailsRestrictions(restrictions)
  }

  const handleHandrailFinishChange = (checkedValues, name) => {
    const restrictions = { ...handrailsRestrictions }

    if (!restrictions.models) restrictions.models = {}
    if (!restrictions.models[name]) restrictions.models[name] = {}

    restrictions.models[name].finishes = checkedValues
  }

  const handleHandrailsCheckAllChange = (name, checked) => {
    const restrictions = { ...handrailsRestrictions }

    if (!restrictions.models) restrictions.models = {}
    if (!restrictions.models[name]) restrictions.models[name] = {}

    restrictions.models[name].disabled = checked
    setHandrailsRestrictions(restrictions)

    const allSelected = Object.values(restrictions.models).filter(v => v.disabled).length ===
      Object.keys(accessoriesData.handrails.models).length

    if (allSelected) {
      handleError("handrails", "You need to select at least one available handrail model.")
    } else {
      handleError("handrails", '')
    }
  }

  return (
    <Card className="Accessories component-card">
      <h2 className="component-card__title">{title}</h2>
      <Tabs className="component-card__main-tab" animated={false}>

        { accessoriesData.mirrors?.types?.length > 0 &&
          !accessoriesData.mirrors.disabled &&
          <TabPane className="tab-pane" key="mirrors" tab="Mirrors">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type='mirrors' label="Mirrors" onToggle={toggleAccessoryTypeRestriction} />
            { !accessoriesRestrictions.includes("mirrors") && 
              !accessoriesData.mirrors.disabled &&
              <>
                <CheckboxGroup
                  title="Positions"
                  name="positions"
                  handleChange={handleMirrorsChange}
                  defaultValue={mirrorsRestrictions.positions}
                  items={accessoriesData.mirrors.positions}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <CheckboxGroup
                  title="Mirror types"
                  name="types"
                  handleChange={handleMirrorsChange}
                  defaultValue={mirrorsRestrictions.types}
                  items={accessoriesData.mirrors.types}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.handrails?.models?.length > 0 &&
          !accessoriesData.handrails.disabled &&
          <TabPane className="tab-pane" key="handrails" tab="Handrails">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type="handrails" label="Handrails" onToggle={toggleAccessoryTypeRestriction} />
            {!accessoriesRestrictions.includes("handrails") &&
              !accessoriesData.handrails.disabled &&
              <>
                <CheckboxGroup
                  title="Positions"
                  name="positions"
                  items={accessoriesData.handrails.positions}
                  setError={handleError}
                  defaultValue={handrailsRestrictions.positions}
                  requireSelection
                  handleChange={handleHandrailsPositionChange}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <h3 className="block-title" style={{ marginTop: "1.5rem", marginBottom: "-.5rem" }}>Models
            {errors.handrails && <span className="title-error">{errors.handrails}</span>}</h3>
                {accessoriesData.handrails.models
                  .filter(handrail => (managingGlobal || !handrail.disabled) && !handrail.treatAs)
                  .map(handrail => (
                    <CheckboxGroup
                      title={handrail.name}
                      items={handrail.finishes}
                      subtitle="Finishes"
                      key={handrail.id}
                      name={handrail.id}
                      checkAll
                      errorMsg="You have to select at least one finish."
                      defaultValue={handrailsRestrictions?.models?.[handrail.id]}
                      requireSelection
                      handleChange={handleHandrailFinishChange}
                      handleCheckAllChange={handleHandrailsCheckAllChange}
                      setError={handleError}
                      managingGlobal={managingGlobal}
                    ></CheckboxGroup>
                  ))}
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.skirtings?.finishes?.length > 0 &&
          !accessoriesData.skirtings.disabled &&
          <TabPane className="tab-pane" key="skirtings" tab="Skirtings">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type="skirtings" label="Skirtings" onToggle={toggleAccessoryTypeRestriction} />
            {
              !accessoriesRestrictions.includes("skirtings") &&
              <>
                <CheckboxGroup
                  title="Finishes"
                  name="finishes"
                  handleChange={handleSkirtingsChange}
                  defaultValue={skirtingsRestrictions.finishes}
                  items={accessoriesData.skirtings.finishes}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.bufferRails?.finishes?.length > 0 &&
          !accessoriesData.bufferRails.disabled &&
          <TabPane className="tab-pane" key="bufferRails" tab="Buffer rails">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type="bufferRails" label="Buffer rails" onToggle={toggleAccessoryTypeRestriction} />
            {
              !accessoriesRestrictions.includes("bufferRails") &&
              accessoriesData.bufferRails &&
              <>
                <CheckboxGroup
                  title="Positions"
                  name="positions"
                  handleChange={handleBufferRailsChange}
                  defaultValue={bufferRailsRestrictions.positions}
                  items={accessoriesData.bufferRails.positions}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <CheckboxGroup
                  title="Finishes"
                  name="finishes"
                  handleChange={handleBufferRailsChange}
                  defaultValue={bufferRailsRestrictions.finishes}
                  items={accessoriesData.bufferRails.finishes}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <CheckboxGroup
                  title="Layouts"
                  name="layouts"
                  handleChange={handleBufferRailsChange}
                  defaultValue={bufferRailsRestrictions.layouts}
                  items={accessoriesData.bufferRails.layouts}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </>
            }
          </TabPane>
        }
        {
          (
            accessoriesData.infoAndMediaScreens?.mediaScreens?.length > 0 ||
            accessoriesData.infoAndMediaScreens?.infoScreens?.length > 0
          ) &&
          <TabPane className="tab-pane" key="infoAndMediaScreens" tab="Info & media screens">
            {/* <ToggleTypeButton restrictions={accessoriesRestrictions} type="infoAndMediaScreens" label="Info & media screens" onToggle={toggleAccessoryTypeRestriction} /> */}
            {
              // Show if not restricted
              !accessoriesRestrictions.includes("infoAndMediaScreens") &&
              // Show if infoAndMediaScreens have not been disabled globally.
              <>
                { accessoriesData.infoAndMediaScreens?.mediaScreens?.length > 0 &&
                  <CheckboxGroup
                  title="Media screens"
                  name="mediaScreens"
                  handleChange={handleInfoAndMediaScreensChange}
                  defaultValue={infoAndMediaScreensRestrictions.models?.mediaScreens}
                  checkAll
                  handleCheckAllChange={handleInfoAndMediaScreensCheckAllChange}
                  subtitle="Types"
                  requireSelection
                  items={accessoriesData.infoAndMediaScreens.mediaScreens}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                }
                { accessoriesData.infoAndMediaScreens?.infoScreens?.length > 0 &&
                  <CheckboxGroup
                  title="Info screens"
                  name="infoScreens"
                  handleChange={handleInfoAndMediaScreensChange}
                  checkAll
                  handleCheckAllChange={handleInfoAndMediaScreensCheckAllChange}
                  subtitle="Types"
                  defaultValue={infoAndMediaScreensRestrictions.models?.infoScreens}
                  items={accessoriesData.infoAndMediaScreens.infoScreens}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                }
                { accessoriesData.infoAndMediaScreens?.mediaScreens?.length > 0 &&
                  <CheckboxGroup
                  title="Media screen positions"
                  name="mediaScreenPositions"
                  handleChange={handleInfoAndMediaScreensPositionChange}
                  defaultValue={infoAndMediaScreensRestrictions.mediaScreenPositions}
                  items={accessoriesData.infoAndMediaScreens.mediaScreenPositions}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                }
                {
                  accessoriesData.infoAndMediaScreens?.infoScreens?.length > 0 &&
                  <CheckboxGroup
                    title="Info screen positions"
                    name="infoScreenPositions"
                    handleChange={handleInfoAndMediaScreensChange}
                    defaultValue={infoAndMediaScreensRestrictions.infoScreenPositions}
                    items={accessoriesData.infoAndMediaScreens.infoScreenPositions}
                    requireSelection
                    setError={handleError}
                    managingGlobal={managingGlobal}
                  ></CheckboxGroup>
                }
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.seats?.types?.length > 0 &&
          !accessoriesData.seats.disabled &&
          <TabPane className="tab-pane" key="seats" tab="Seats">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type="seats" label="Seats" onToggle={toggleAccessoryTypeRestriction} />
            {
              // Show if not restricted
              !accessoriesRestrictions.includes("seats") &&
              <>
                <CheckboxGroup
                  title="Positions"
                  name="positions"
                  handleChange={handleSeatsChange}
                  defaultValue={seatsRestrictions.positions}
                  items={accessoriesData.seats.positions}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <CheckboxGroup
                  title="Types"
                  name="types"
                  handleChange={handleSeatsChange}
                  defaultValue={seatsRestrictions.types}
                  items={accessoriesData.seats.types}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.tenantDirectories?.finishes?.length > 0 &&
          !accessoriesData.tenantDirectories.disabled &&
          <TabPane className="tab-pane" key="tenantDirectories" tab="Tenant directories">
            <ToggleTypeButton restrictions={accessoriesRestrictions} type="tenantDirectories" label="Tenant directories" onToggle={toggleAccessoryTypeRestriction} />
            {
              // Show if not restricted
              !accessoriesRestrictions.includes("tenantDirectories") &&
              <>
                <CheckboxGroup
                  title="Finishes"
                  name="finishes"
                  handleChange={handleTenantDirectoriesChange}
                  defaultValue={tenantDirectoriesRestrictions.finishes}
                  items={accessoriesData.tenantDirectories.finishes}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
                <CheckboxGroup
                  title="Sizes"
                  name="sizes"
                  handleChange={handleTenantDirectoriesChange}
                  defaultValue={tenantDirectoriesRestrictions.sizes}
                  items={accessoriesData.tenantDirectories.sizes}
                  requireSelection
                  setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </>
            }
          </TabPane>
        }
        {
          accessoriesData.airPurifier && !accessoriesData.airPurifier.disabled &&
          <TabPane className="tab-pane" key="airPurifier" tab={"Air purifier"}>
            <Checkbox checked={!airPurifierRestrictions.disabled} onChange={() => {
              setAirPurifierRestrictions(prev => {
                return {
                  ...prev,
                  disabled: !prev.disabled
                }
              })
            }}>
              {getText(accessoriesData.airPurifier.label)} enabled
            </Checkbox>
            <URLInput
              className="ReadMoreInput air-purifier-editable-link"
              label={'Read more link'}
              defaultValue={frontline.carDesignerUI?.services?.['KCSM_AIR_PURIFIER']?.url || ''}
              onSave={async (url) => {
                await frontlineCtx.saveServiceURL(frontline, { url, service: 'KCSM_AIR_PURIFIER' })
              }}
            />
          </TabPane>

        }
      </Tabs>
    </Card>
  )
}

function ToggleTypeButton(props) {
  const { onToggle, type, label, restrictions = [] } = props

  return (
    <div className="ToggleTypeButton">
      <Checkbox checked={!restrictions.includes(type)} onChange={() => onToggle(type)}>
        { label } enabled
      </Checkbox>
    </div>
  )
}

export default Accessories