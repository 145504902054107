const createThemeService = api => {
  return {
    createTheme (frontline, options) {
      const { hiddenId } = frontline
      const { theme, productId } = options

      return api.post(`/theme/${hiddenId}/new`, { theme, productId })
    },

    updateTheme(frontline, options) {
      const { hiddenId } = frontline
      const { newValues, themeId, productId } = options
      return api.post(`/theme/${hiddenId}/product/${productId}/update/${themeId}`, newValues)
    },

    deleteTheme(frontline, options) {
      const { hiddenId } = frontline
      const { productId, themeId } = options
      return api.delete(`/theme/${hiddenId}/${productId}/${themeId}`)
    },

    addDesignToTheme(frontline, options) {
      const { hiddenId } = frontline
      const { productId, themeId, designURL } = options
      return api.post(`/theme/${hiddenId}/${productId}/${themeId}/design/add`, { designURL })
    },

    removeDesignFromTheme(frontline, options) {
      const { hiddenId } = frontline
      const { productId, themeId, designId } = options
      return api.post(`/theme/${hiddenId}/${productId}/${themeId}/design/remove/${designId}`)
    },

    updateDesignName(frontline, options) {
      const { hiddenId } = frontline
      const { productId, themeId, designId, newName } = options
      return api.post(`/theme/${hiddenId}/${productId}/${themeId}/design/edit/${designId}/name`, { newName })
    },

    releaseAll({ hiddenId }) {
      return api.post(`/theme/${hiddenId}/publish`)
    },
  }
}

export default createThemeService