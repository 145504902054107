import React, { useState, useMemo, useContext } from 'react'
import ReactExport from "react-export-excel";
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import './ExportDataButton.scss'
import { categorizeItemsBy } from '../../utils/general-utils';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


/**
 * Component for generating excel files from data.
 * @param {Object} props
 * @param {string=} props.className
 * @param {Object[]} props.data - Data to save
 * @param {string=} props.filename - Name of the file without extension
 * @param {string=} props.sheetName - Name of the sheet in case of just one sheet
 * @param {Object[]} props.sheets - Sheet definitions
 * @param {string=} props.sheets.label - Sheet name 
 * @param {string=} props.sheets.key - Data items with this sheet key will go to this sheet 
 * @param {string=} props.text - Button text
 * @param {string=} props.type - Button type
 * @param {boolean=} props.disabled
 * @param {Object[]} props.headers - test
 * @param {string=} props.headers.key - Field property name in data
 * @param {string=} props.headers.label - Label to use in Excel
 * @param {boolean=} props.headers.required - If a field is required, any item that does not have a value for that field gets filtered out
 */
function ExportDataButton(props) {
  const {
    className = '',
    headers,
    text = 'Export as CSV',
    sheetName = 'Sheet 1',
    filename = 'exported-data',
    sheets: originalSheets,
    disabled,
    type = 'default',
    data = [],
  } = props

  const defaultSheetKey = 'sheet1'
  
  // In case only the sheet name is explicitly given because just
  // one sheet is wanted, the default sheets element must match that.
  const sheets = Array.isArray(originalSheets) ? originalSheets : [{ name: sheetName, key: defaultSheetKey }] 

  const filteredData = useMemo(() => {
    if (!headers) return data
    const requiredFields = headers
      .filter(x => x.required)
      .map(x => x.key)
    
    if (requiredFields.length === 0) return data

    // Filter out items that do not have values for some required fields.
    return data.filter(item => {
      let allowed = true
      requiredFields.forEach(field => {
        if (!item[field]) {
          allowed = false
        }
      })

      return allowed
    }).map(item => {
      const restructured = {}
      headers.forEach(header => {
        restructured[header.key] = item[header.key]
      })
      return restructured
    })
  }, [headers, data])
  
  const sheetsWithData = useMemo(() => {
    const defaultCategory = originalSheets ? null : sheets[0].key
    const categorizedData = categorizeItemsBy(filteredData, 'sheet', defaultCategory)

    return sheets.map(sheet => {
      const categoryItem = categorizedData.find(item => item[0] === sheet.key)

      // There is no data that matches the sheet key ===> filter out the sheet 
      if (!categoryItem) return null 

      const data = categoryItem[1]
        .map(dataItem => {
          const newItem = {}

          Object.entries(dataItem).forEach(entry => {
            const [ key, value ] = entry

            // Only allow string or number properties. Others get filtered out.
            if (typeof value === 'string' || typeof value === 'number') {
              newItem[key] = value
            }
          })
          
          return newItem
        })

      const headersToUse = sheet.headers || headers || getHeaders(data) 

      return {
        ...sheet,
        headers: headersToUse,
        data
      }
    }).filter(x => x) // Filter out null
  }, [sheets, filteredData, headers, originalSheets])

  /**
   * If headers have been specified in props, use those.
   * Otherwise generate headers from the data.
   */
  function getHeaders(data) {
    const result = {}

    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (!result[key]) {
          result[key] = key
        }
      })
    })
    return Object.entries(result)
      .map(([ key, label ]) => ({ key, label }))
  }

  return (
    <div className={`ExportDataButton ${className}`}>
      <ExcelFile
        filename={filename}
        element={
          <Button type={type} disabled={disabled || sheetsWithData.length === 0} icon={<DownloadOutlined />}>{text}</Button>
        }>
        {
          sheetsWithData.map(sheet => {

            return (
              <ExcelSheet data={sheet.data} name={sheet.name} key={sheet.key}>
                {
                  sheet.headers.map(header => {
                    
                    return (
                      <ExcelColumn key={header.key} label={header.label} value={header.key} />
                    )
                  })
                }
              </ExcelSheet>
            )
          })
        }
      </ExcelFile>
    </div>
  );
}

export default ExportDataButton