import dateFns from 'date-fns'
import frontlineUtils from '../../utils/frontline-utils'
import productMapping from '../../constants/productMapping.json'
import { getDateForName } from '../utils'

export function getHeaders() {
  return [
    { key: 'frontline', label: 'Frontline' }, 
    { key: 'lastUpdated', label: 'Last updated' }, 
    { key: 'defaultLanguage', label: 'Default language' }, 
    { key: 'releasedProducts', label: 'Released products' },
    { key: 'state3D', label: '3D Status' },
  ]
}

export function getData(frontlines) {
  return frontlines.map(fl => {
    const { country = {}, languages = [], products = [] } = fl
    
    const result = {}

    result.frontline = country.name
    result.lastUpdated = dateFns.format(new Date(fl._ts * 1000), 'MMMM Do, YYYY')
    const defaultFound = languages.find(x => x.default)

    if (defaultFound) {
      result.defaultLanguage = defaultFound.language 
    }

    result.releasedProducts = products
      .filter(product => {
        const isReleased = frontlineUtils.isProductReleased(product)
        return isReleased
      })
      .map(x => productMapping[x.id] || x.id)
      .join(', ')

    result.state3D = fl.active3D ? '3D' : '2D'
    
    return result
  })
}

function getName() {
  return `cd_frontlines_${getDateForName()}`
}

export default { getData, getHeaders, getName }