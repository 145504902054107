import React, { useMemo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FrontlinesContext } from './FrontlinesProvider'

export function useFrontline() {
  const { country } = useParams()
  const { frontline, globalFrontline } = useContext(FrontlinesContext)

  const frontlineToManage = useMemo(() => {
    if (country && country.toLowerCase() === 'global') {
      return globalFrontline
    }
    
    return frontline
  }, [country, frontline, globalFrontline])
  
  return frontlineToManage
}