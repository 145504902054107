import React, { useContext, useMemo } from 'react'
import { message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import './AddressFieldManager.scss'
import { createErrorMessage } from '../../utils/error-utils'
import EditableInput from '../EditableInput/EditableInput'
import { FrontlinesContext } from '../../store/frontlines'
import AsyncButton from '../AsyncButton'

function AddressFieldManager(props) {
  const { frontline } = props 

  const { 
    saveAddressState,
    addAddressFieldGroup,
    removeAddressFieldGroup,
  } = useContext(FrontlinesContext)

  const dummyArray = useMemo(() => {
    let amount
    if (!frontline.addresses || !frontline.addresses.amount) {
      amount = 1
    } else {
      amount = frontline.addresses.amount
    }

    return Array(amount).fill('x' + amount) 
  }, [ frontline ])

  async function handleAddGroup() {    
    try {
      await addAddressFieldGroup({ frontline })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: `Adding an address group`,
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  async function handleRemoveGroup(group) {
    try {
      await removeAddressFieldGroup({ frontline, group })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: `Removing an address group`,
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  return (
    <div className="AddressFieldManager">
      {
        dummyArray.map((val, i) => {

          return (
            <AddressFields 
              frontline={frontline} 
              saveAddressState={saveAddressState}
              removable={dummyArray.length > 1}
              onRemove={() => handleRemoveGroup(i)}
              group={i} key={val + i} 
            />
          )
        })
      }
      <div className="add-button-container">
        <AsyncButton
          className="add-button"
          disabled={dummyArray.length >= 5}
          disabledTooltipText="Address group limit reached"
          confirmation={false}
          onConfirm={handleAddGroup}
        >Add an address group</AsyncButton>
        </div>
    </div>
  )
}

function AddressFields(props) {
  const { frontline, saveAddressState, group, removable, onRemove } = props


  return (
    <div className={`AddressFields`}>
      { removable && 
      <h3>Group {group + 1} 
        <AsyncButton 
          size="small"
          type="text"
          danger={true}
          confirmText="Delete the address group?"
          tooltipText="Delete group?"
          className="remove-button"
          icon={<DeleteOutlined />}
          onConfirm={onRemove}>
        </AsyncButton>
      </h3>
      }
      <AddressInput 
        group={group}
        frontline={frontline} 
        label="Office name"
        name="officeName"
        saveAddressState={saveAddressState}
      />
      <AddressInput 
        group={group}
        frontline={frontline} 
        label="Office address"
        name="officeAddress"
        saveAddressState={saveAddressState}
      />
      <AddressInput 
        group={group}
        frontline={frontline} 
        label="Office phone"
        name="officePhone"
        saveAddressState={saveAddressState}
      />
      <AddressInput 
        group={group}
        frontline={frontline} 
        label="Office URL"
        name="officeUrl"
        saveAddressState={saveAddressState}
      />
    </div>
  )
}

function AddressInput(props) {
  const { name, label, frontline, saveAddressState, group } = props

  const { addresses } = frontline

  const address = useMemo(() => {
    if (addresses && addresses[name] && addresses[name].savedAddress) {
      if (typeof addresses[name].savedAddress === 'string') {
        if (group === 0) {
          return addresses[name].savedAddress
        } else {
          return ''
        }
      } else {
        return addresses[name].savedAddress[group] || ''
      }
    } else {
      return ''
    }
  }, [addresses])

  async function handleSave(value) {
    try {
      await saveAddressState({ frontline, name, value, group })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: `Saving the value for ${label}`,
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }
  
  return (
    <EditableInput
      className="AddressInput"
      label={label}
      onOKClick={handleSave}
      okText="Save"
      text={address}
      textArea={true}
    />
  )
}

export default AddressFieldManager