const createFrontlineService = (api) => {
  return {
    getAllProducts() {
      return api.get('/frontline/products')
    },

    getGlobal() {
      return api.get(`/frontline/global`)
    },

    getOneByCountry(countryName) {
      return api.get(`/frontline/${countryName}`)
    },

    getGlobalFrontline() {
      return api.get(`/frontline/global`)
    },

    getUsersForFrontline(frontline) {
      return api.get(`/frontline/${frontline.id}/${frontline.hiddenId}/users`)
    },

    getDomainData() {
      return api.get('/public/frontline/domain_data')
    },

    create(countryName) {
      return api.post(`/frontline`, { countryName })
    },

    addProduct(frontline, productId) {
      const { id, hiddenId } = frontline
      return api.post(`/frontline/${id}/${hiddenId}/add/product/${productId}`)
    },

    removeProduct(frontline, productId) {
      const { id, hiddenId } = frontline
      return api.post(`/frontline/${id}/${hiddenId}/remove/product/${productId}`)
    },

    setFeedbackBannerSettings(settings) {
      return api.post(`/frontline/global/feedback-banner-settings`, settings)
    },

    setHighResSettings(settings) {
      return api.post(`/frontline/global/high-res-settings`, settings)
    },

    loadGlobalOffering({ productId, releaseId }) {
      return api.get(`/frontline/global/offering/${productId}/${releaseId}`)
    },

    setReleaseRights(state) {
      return api.post(`/frontline/global/releasing_allowed`, { state })
    },

    setReleaseStatus({ groupName, releaseId, enabled }) {
      return api.post(`/frontline/global/set_release_status/${groupName}/${releaseId}`, { enabled })
    },

    setServeOriginalState({ groupName, releaseId, serveOriginal }) {
      return api.post(`/frontline/global/set_serve_original/${groupName}/${releaseId}`, { serveOriginal })
    },

    setReleaseVisibility({ hiddenId, groupName, releaseId, selectableForKonePeople }) {
      return api.post(`/frontline/${hiddenId}/set_release_visibility/${groupName}/${releaseId}`, { selectableForKonePeople })
    },

    setDefaultRelease({ hiddenId, groupName, releaseId }) {
      return api.post(`/frontline/${hiddenId}/set_default_release/${groupName}`, { releaseId })
    },

    addLanguages(frontline, languages) {
      const { id, hiddenId } = frontline

      return api.post(`/frontline/${id}/${hiddenId}/add_languages`, { languages })
    },

    removeLanguage(frontline, code) {
      const { id, hiddenId } = frontline

      return api.post(`/frontline/${id}/${hiddenId}/remove_language`, { code })
    },

    setLanguages(frontline, languages) {
      const { id, hiddenId } = frontline

      return api.post(`/frontline/${id}/${hiddenId}/set_languages`, { languages })
    },

    setDefaultLanguage(frontline, code) {
      const { id, hiddenId } = frontline

      return api.post(`/frontline/${id}/${hiddenId}/set_default_language`, { code })
    },

    setAvailableLanguages(languages) {
      return api.post(`/frontline/global/set_available_languages`, { languages })
    },

    setIsKoneOnly(frontline, productId, isKoneOnly) {
      return api.post(`/frontline/${frontline.id}/${frontline.hiddenId}/${productId}/koneOnly`, { isKoneOnly })
    },

    setDisabledState(frontline, args, disabled) {
      return api.post(`/frontline/${frontline.id}/${frontline.hiddenId}/${args.id}/state/${disabled ? 'disable' : 'enable'}`, { releaseId: args.releaseId })
    },

    setFullReplacementState(frontline, product, newState) {
      return api.post(`/frontline/${frontline.id}/${frontline.hiddenId}/${product.id}/full-replacement`, { active: newState })
    },

    set3DActiveState(frontline, active) {
      return api.post(`/frontline/${frontline.id}/${frontline.hiddenId}/active`, { frontline, active })
    },

    setDisplayExistingBuildings(displayExistingBuildingsUI) {
      return api.post(`/frontline/global/display_existing_buildings_ui`, { displayExistingBuildingsUI })
    },

    saveVersionState({ name, version }) {
      return api.post(`/frontline/save_version/${name}`, { version })
    },

    addAddressFieldGroup({ frontline }) {
      return api.post(`/frontline/add_address_field_group/${frontline.id}/${frontline.hiddenId}`)
    },

    removeAddressFieldGroup({ frontline, group }) {
      return api.post(`/frontline/remove_address_field_group/${frontline.id}/${frontline.hiddenId}`, { group })
    },

    saveAddressState({ frontline, name, value, group }) {
      return api.post(`/frontline/save_address/${frontline.id}/${frontline.hiddenId}/${name}`, { value, group })
    },

    publishVersionState({}) {},

    updateRestrictionsForProduct(frontline, productId, restrictions, releaseId) {
      return api.post(`/frontline/${frontline.id}/${frontline.hiddenId}/${productId}/restrictions`, { frontline, restrictions, releaseId, })
    },

    destroy(frontline) {
      return api.delete(`/frontline/${frontline.id}/${frontline.hiddenId}`)
    },

    getCountries() {
      return api.get(`/frontline/available-countries`)
    },

    setWelcomePageUIState(frontline, options) {
      const { id, hiddenId } = frontline
      const { section, visible, url } = options
      return api.post(`/frontline/${id}/${hiddenId}/ui/welcomePage`, { section, visible, url })
    },

    saveServiceURL(frontline, options) {
      const { id, hiddenId } = frontline
      const { service, url } = options
      return api.post(`/frontline/${id}/${hiddenId}/ui/services`, { service, url })
    },

    setPredesigns(frontline, options) {
      const { hiddenId } = frontline
      const { group, predesigns, productId, releaseId } = options
      return api.post(`/frontline/${hiddenId}/set_predesigns/${productId}/${releaseId}`, { group, predesigns })
    },

    deleteFrontline(frontline) {
      const { id, hiddenId } = frontline
      return api.delete(`/frontline/${id}/${hiddenId}`)
    }
  }
}


export default createFrontlineService;
