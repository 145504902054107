export const PRODUCT = "product"
export const TIMESTAMP = "Timestamp"
export const COUNTRY = "country"

/**
 * Default graph options
 */
export const defaultGraphOptions = {
  animation: {
    duration: 1000,
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
      }
    }
  },
  scales: {
    xAxes: [{
      stacked: true,
      gridLines: { display: false },
    }],
    yAxes: [{
      stacked: true,
      ticks: {
        callback: function (value) { return value; },
      },
    }],
  },
  legend: { display: false }
}

export const defaultDatasetStyles = {
  backgroundColor: "rgba(55, 160, 225, 0.7)",
  hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
  hoverBorderWidth: 2,
  hoverBorderColor: 'lightgrey'
}