import React, { useState, useMemo, useContext, useEffect } from 'react'
import { message } from 'antd'
import useService from '../../../../services/useService'

import './GenDoc.scss'
import UnitChart from '../../../../components/UnitChart/UnitChart'
import ExportDataButton from '../../../../components/ExportDataButton/ExportDataButton'

const excelHeaders = [
  { key: 'productName', label: 'Product ID' },
  { key: 'destinationCountry', label: 'Destination country' },
  { key: 'status', label: 'Response status' },
  { key: 'Timestamp', label: 'Timestamp' },
  { key: 'unsupportedComponents', label: 'Unsupported components' },
  { key: 'blobUri', label: 'XML donwload link' },
]

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function GenDoc(props) {
  const {
    className = '',
    availableProducts
  } = props

  const logService = useService('log')

  const [loading, setLoading] = useState(true)
  const [tableEntries, setTableEntries] = useState()
  const [excelExportData, setExcelExportData] = useState()

  useEffect(() => {
    logService.getGenDocEntries().then(result => {
      const units = []

      result.forEach(project => {

        if (project.description) {
          try {
            const desc = JSON.parse(project.description)

            if (Array.isArray(desc)) {

              desc.forEach(unit => {
                units.push({
                  ...project,
                  ...unit,
                  productName: unit.productId
                })
              })
            }
          } catch (err) {
            // invalid description (old data)
          }
        }

      })


      setTableEntries(units)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })
  }, [logService])

  return (
    <div data-testid="GenDoc" className={`GenDoc ${className}`}>
      <h2>GenDoc units</h2>
      <UnitChart
        className="chart-container"
        loading={loading}
        tableEntries={tableEntries}
        availableProducts={availableProducts}
        setExcelExportData={setExcelExportData}
      />
      <ExportDataButton
        filename="GenDoc_log_export"
        sheetName="Data"
        data={excelExportData}
        headers={excelHeaders}
      />
    </div>
  )
}

export default GenDoc