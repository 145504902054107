import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route} from "react-router-dom";
import "./index.scss";
import App from "./components/App";
import { Provider } from './store';
import registerServiceWorker from "./registerServiceWorker";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

ReactDOM.render(
  <Router>
    <ErrorPage>
      <Provider>
        <App />
      </Provider>
    </ErrorPage>
  </Router>
  , document.getElementById("root")
);

// registerServiceWorker();
