import React, { useState, useEffect, useContext } from 'react'
import { Checkbox, Input, message, Card, Tag } from 'antd'
import dateFns from 'date-fns'
import { AuthContext } from '../../store/auth';
import './LanguagesPage.scss'
import { FrontlinesContext } from '../../store/frontlines';
import AsyncButton from '../../components/AsyncButton'
import { createErrorMessage } from '../../utils/error-utils'
import ExportDataButton from '../../components/ExportDataButton/ExportDataButton';
import languageCSV from '../../csv/data/languages'

const LanguagesPage = ({ history }) => {
  const [ searchTerm, setSearchTerm ] = useState('')
  const [ checkAllChecked, setCheckAllChecked ] = useState(false)
  const [ isIndeterminate, setIsIndeterminate ] = useState(false)

  const { globalFrontline, publishTranslation, saveAvailableLanguages } = useContext(FrontlinesContext)
  const { loggedInUser} = useContext(AuthContext)

  const [ languages, setLanguages ] = useState(globalFrontline.languages.filter(x => !x.hidden))

  useEffect(() => {
    if (languages.every(l => l.disabled)) {
      setCheckAllChecked(false)
      setIsIndeterminate(false)
    } else if (languages.every(l => !l.disabled)) {
      setCheckAllChecked(true)
      setIsIndeterminate(false)
    } else {
      setCheckAllChecked(false)
      setIsIndeterminate(true)
    }
  }, [])

  const handleSearch = e => {
    if (!globalFrontline) return
    setSearchTerm(e.target.value)
  }

  const handleCheckAllChange = e => {
    const newLanguages = languages.map(language => {
      return {
        ...language, disabled: !e.target.checked
      }
    })

    setLanguages(newLanguages)
    setIsIndeterminate(false)
    setCheckAllChecked(e.target.checked)
  }

  const handleChange = (e, code) => {
    const languageToSet = languages.find(x => x.code === code)
    languageToSet.disabled = !e.target.checked

    setLanguages([...languages])
  }

  const handleSave = async () => {
    try {
      const languagesToSave = languages
        .filter(x => !x.disabled)
        .map(x => x.code)
      const globalFL = await saveAvailableLanguages(languagesToSave)
      message.success("Saving succesful!")
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving',
        attemptedItem: 'settings'
      })

      message.error(errorMessage, 5)
    }
  }

  const handlePublish = async (code) => {
    try {
      const result = await publishTranslation(code, 'global')
      // Re-set the languages so the published status updates
      setLanguages(result.frontline.languages.filter(x => !x.hidden))
      message.success("Publishing succesful!")
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Publishing',
        attemptedItem: 'settings'
      })

      message.error(errorMessage, 5)
    }
  }
  
  if (!loggedInUser || !globalFrontline) return null
  return (
    <div className="LanguagesPage page">
      <h1 className="page-title">Languages</h1>
      <div className="actions-container">
        <Input.Search
          className="search-field"
          placeholder="Filter languages" 
          onChange={handleSearch}
          ></Input.Search>
          <AsyncButton
            onConfirm={handleSave}
            type="primary">Save</AsyncButton>
        <AsyncButton
          className="publish-global-button"
          onConfirm={() => handlePublish('en')}
          tooltipText="Publishes the default English translation to the Car Designer.">
          Publish the global translation
        </AsyncButton>
        <ExportDataButton
          data={languageCSV.getData(languages)}
          headers={languageCSV.getHeaders()}
          filename={languageCSV.getName()}
        />
      </div>
        <Card className="checkbox-container">
        { !searchTerm && 
          <Checkbox 
            onChange={handleCheckAllChange}
            checked={checkAllChecked}
            indeterminate={isIndeterminate}>
            Check all</Checkbox>
        }
          { languages.filter(l => {
            return l.language.toLowerCase().includes(searchTerm.toLowerCase())
          }).map(language => {
            return (
              <div className="list-item" key={language.code}>
                <Checkbox 
                  className="language-checkbox"
                  onChange={(e) => handleChange(e, language.code)}
                  checked={!language.disabled}>
                  {language.language}
                </Checkbox>
                <div className="publish-container">
                  <AsyncButton
                    onConfirm={() => handlePublish(language.code)}
                    confirmText={`${language.lastPublished ? "Update" : "Publish"} the translation for ${language.language}?`}
                  >{language.lastPublished ? "Update" : "Publish"}</AsyncButton>
                  <span className="publish-text">
                    Latest: {
                      language.lastPublished ?
                        dateFns.format(new Date(language.lastPublished), 'MMMM Do, YYYY, HH:mm')
                        : "Never published"
                    }
                  </span>
                </div>
                  <div className="tag-container">
                  {
                    !language.disabled && language.showInMobile && 
                      <Tag 
                        color="success"
                      >Mobile</Tag>
                  }
                  </div>
              </div>
            )
          })}
      </Card>
    </div>
  )
}

export default LanguagesPage