import React, { useState, useReducer, useEffect } from 'react';
import axios from 'axios'
import config from '../../config'
import { createError } from '../../utils/error-utils'
import { useLocation } from 'react-router-dom';

export const UIContext = React.createContext();



export const UIProvider = ({ children }) => {
  const [ selectedProductGroup, setSelectedProductGroup ] = useState()
  const [ prevScrollOnProductPage, setPrevScrollOnProductPage ] = useState()
  
  const location = useLocation()

  useEffect(() => {
    // Reset memory when leaving frontline management pages
    if (!location.pathname.includes('/frontline/')) {
      setPrevScrollOnProductPage(0)
      window.scrollTo(0, 0)
    }

    if (location.state && location.state.from) {
      if (location.pathname.includes('/global')) {
        if (!location.state.from.includes('/global/')) {
          // Reset the scroll if going from normal frontline to global frontline through nav bar
          setPrevScrollOnProductPage(0)
          window.scrollTo(0, 0)
        }
      }
    }
  }, [location])

  return (
    <UIContext.Provider value={{
      selectedProductGroup, setSelectedProductGroup,
      prevScrollOnProductPage, setPrevScrollOnProductPage
    }}>
      {children}
    </UIContext.Provider>
  )
}
export default UIProvider;

export const UIConsumer = UIContext.Consumer;