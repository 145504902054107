import React, { useState, useEffect } from 'react'
import { Card, Tabs } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import { addCategory, useComponentSelectionError } from '../tab-component-utils'

const { TabPane } = Tabs

const Doors = ({ initRestrictions = {}, setRestrictions, disableSaving, product, managingGlobal, title }) => {
  const [
    solutionsRestrictions,
    setSolutionsRestrictions
  ] = useState(initRestrictions.solutions)

  const [
    doorFinishesRestrictions,
    setDoorFinishesRestrictions
  ] = useState(initRestrictions.doorFinishes)

  const [
    frontWallFinishesRestrictions,
    setFrontWallFinishesRestrictions
  ] = useState(initRestrictions.frontWallFinishes)

  const [
    framesRestrictions,
    setFramesRestrictions
  ] = useState(initRestrictions.frames)

  const [
    doorAndFrameFinishesRestrictions,
    setDoorAndFrameFinishesRestrictions
  ] = useState(initRestrictions.doorAndFrameFinishes)

  const [
    landingDoorFinishRestrictions,
    setLandingDoorFinishRestrictions
  ] = useState(initRestrictions.landingDoorFinishes)

  const { handleError } = useComponentSelectionError(disableSaving)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setRestrictions({
      solutions: solutionsRestrictions,
      doorFinishes: doorFinishesRestrictions,
      frontWallFinishes: frontWallFinishesRestrictions,
      frames: framesRestrictions,
      doorAndFrameFinishes: doorAndFrameFinishesRestrictions,
      landingDoorFinishes: landingDoorFinishRestrictions,
    })
  }, [
    solutionsRestrictions,
    doorFinishesRestrictions,
    frontWallFinishesRestrictions,
    framesRestrictions,
    doorAndFrameFinishesRestrictions,
    landingDoorFinishRestrictions
  ]
  )

  const setError = message => {
    handleError("doors", message)
    setErrorMessage(message)
  }

  const handleSolutionsChange = checkedValues => {
    setSolutionsRestrictions(checkedValues)
  }

  const handleDoorFinishesChange = checkedValues => {
    setDoorFinishesRestrictions(checkedValues)
  }

  const handleFrontWallFinishesChange = checkedValues => {
    setFrontWallFinishesRestrictions(checkedValues)
  }

  const handleFramesChange = checkedValues => {
    setFramesRestrictions(checkedValues)
  }

  const handleDoorAndFrameFinishesChange = checkedValues => {
    setDoorAndFrameFinishesRestrictions(checkedValues)
  }

  const handleLandingDoorFinishChange = checkedValues => {
    setLandingDoorFinishRestrictions(checkedValues)
  }

  const { frontWallFinishes, doorFinishes, doorAndFrameFinishes, landingDoorFinishes } = product.componentsData.doors

  return (
    <Card className="Doors component-card">
      <h2 className="component-card__title">{title}</h2>
      {/* {errorMessage ? <p className="error-message">{errorMessage}</p> : null} */}
      <Tabs className="component-card__main-tab" animated={false}>
        <TabPane className="tab-pane" tab="Inside" key="inside">
          <Tabs className="component-card__secondary-tab" animated={false}>
            <TabPane className="tab-pane" tab="Front wall finishes" key="frontWallFinishes">
              <CheckboxGroup
                name="frontWallFinishes"
                // title="Front wall finishes"
                mainTitle
                items={addCategory(frontWallFinishes)}
                categorizeBy="category"
                handleChange={handleFrontWallFinishesChange}
                defaultValue={frontWallFinishesRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one front wall finish."
                requireSelection
                managingGlobal={managingGlobal}
              />
            </TabPane>
            <TabPane className="tab-pane" tab="Door solutions" key="doorSolutions">
              <CheckboxGroup
                name="doorSolutions"
                // title="Door solutions"
                mainTitle
                items={product.componentsData.doors.solutions}
                handleChange={handleSolutionsChange}
                defaultValue={solutionsRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one available door solution."
                requireSelection
                managingGlobal={managingGlobal}
              />
            </TabPane>
            <TabPane className="tab-pane" tab="Door finishes" key="doorFinishes">
              <CheckboxGroup
                name="doorFinishes"
                // title="Door finishes"
                mainTitle
                items={addCategory(doorFinishes)}
                categorizeBy="category"
                handleChange={handleDoorFinishesChange}
                defaultValue={doorFinishesRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one door finish."
                requireSelection
                managingGlobal={managingGlobal}
              />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane className="tab-pane" tab="Landing" key="landing">
          <Tabs className="component-card__secondary-tab" animated={false}>
            <TabPane className="tab-pane" tab="Door frames" key="frames">
              <CheckboxGroup
                name="frames"
                // title="Door frames"
                mainTitle
                items={product.componentsData.doors.frames}
                handleChange={handleFramesChange}
                defaultValue={framesRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one available door frame."
                requireSelection
                managingGlobal={managingGlobal}
              />
            </TabPane>
            <TabPane className="tab-pane" tab="Landing door finishes" key="landingDoorFinishes">
              <CheckboxGroup
                name="landingDoorFinishes"
                // title="Landing door finishes"
                mainTitle
                items={addCategory(landingDoorFinishes)}
                categorizeBy="category"
                handleChange={handleLandingDoorFinishChange}
                defaultValue={landingDoorFinishRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one finish."
                requireSelection
                managingGlobal={managingGlobal}
              />
            </TabPane>
            <TabPane className="tab-pane" tab="Frame finishes" key="doorAndFrameFinishes">
              <CheckboxGroup
                name="doorAndFrameFinishes"
                // title="Frame finishes"
                mainTitle
                items={addCategory(doorAndFrameFinishes)}
                categorizeBy="category"
                handleChange={handleDoorAndFrameFinishesChange}
                defaultValue={doorAndFrameFinishesRestrictions}
                setError={handleError}
                errorMsg="You have to select at least one finish."
                requireSelection
                managingGlobal={managingGlobal}

              />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>






    </Card>
  )
}

export default Doors