import React, { useState, useContext, useEffect } from 'react'
import "./Users.scss"

import UsersDisplay from '../../components/UsersDisplay';
import { AuthContext } from '../../store/auth';
import { UsersContext } from '../../store/users';
import { FrontlinesContext } from '../../store/frontlines';

const Users = ({history}) => {
  const { loggedInUser } = useContext(AuthContext)
  const { loadUsers, deleteUser, createUser, users, saveRole, saveFrontlinesForUser } = useContext(UsersContext)
  const { frontlines, loadFrontlines } = useContext(FrontlinesContext)

  const [ usersLoading, setUsersLoading ] = useState(true)

  useEffect(() => {
    loadUsers().then(() => setUsersLoading(false))

    if (!frontlines || frontlines.length === 0) {
      loadFrontlines()
    }
  }, [])

  if (!loggedInUser) return null

  return (
    <div className="Users page">
      <h1 className="page-title">Users</h1>
      <UsersDisplay 
        deleteUser={deleteUser}
        frontlines={frontlines}
        loading={usersLoading}
        saveFrontlinesForUser={saveFrontlinesForUser}
        saveUser={createUser}
        saveUserRole={saveRole}
        users={users}
      />
    </div>
  )
}

export default Users