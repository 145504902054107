import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Card, Tabs } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import { useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'

const { TabPane } = Tabs

const Floor = ({ initRestrictions = {}, setRestrictions, disableSaving, product, managingGlobal, title }) => {
  const [floorRestrictions, setFloorRestrictions] = useState(initRestrictions)
  const { errors, handleError } = useComponentSelectionError(disableSaving)
  const [ errorMessage, setErrorMessage ] = useState('') 
  const { getText } = useContext(TranslationsContext)

  const filteredFloors = product.componentsData.floors
    .filter(floor => floor.finishes.length > 0)


  function hasMaterialError() {
    const found = filteredFloors.find(floor => errors[floor.id])
    return !!found
  }

  useEffect(() => {
    setRestrictions(floorRestrictions)
  }, [floorRestrictions])

  const setError = message => {
    handleError("floor", message)
    setErrorMessage(message)
  }

  const handleChange = (checkedValues, name) => {
    const restrictions = { ...floorRestrictions }

    if (!restrictions[name]) restrictions[name] = {}
    
    restrictions[name].finishes = checkedValues

    setFloorRestrictions(restrictions)
  }

  const handleCheckAllChange = (name, checked) => {
    const restrictions = { ...floorRestrictions }

    if (!restrictions[name]) restrictions[name] = {}

    restrictions[name].disabled = checked

    setFloorRestrictions(restrictions)

    const allSelected = Object.values(restrictions)
      .filter(v => v.disabled).length === filteredFloors.length 
      
      if (allSelected) {
        setError("You need to select at least one available material for the floor.")
      } else {
        setError('')
      }
  }

  return (
    <Card className="Floor component-card">
      <h2 className="component-card__title">{title}</h2>
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      <Tabs className="component-card__main-tab" animated={false}>
      {filteredFloors.map(floor => {
        const materialRestricted = floorRestrictions[floor.id] === 'all'
        let className = 'tab-pane-text'

        if (materialRestricted) {
          className += ' tab-pane-text-off'
        }

        return (
          <TabPane 
            className="tab-pane" 
            tab={<span className={className}>{getText(floor.label)}</span>} 
            disabled={hasMaterialError()}
            key={floor.id} >
            <CheckboxGroup
              key={floor.id}
              title={floor.label}
              name={floor.id}
              handleChange={handleChange}
              defaultValue={floorRestrictions[floor.id]}
              handleCheckAllChange={handleCheckAllChange}
              items={floor.finishes}
              requireSelection
              setError={handleError}
              errorMsg="You need to select at least one finish for an activated floor."
              checkAll
              subtitle="Floor finishes"
              managingGlobal={managingGlobal}
            />
          </TabPane>
        )
      })}
      </Tabs>
    </Card>
  )
}

export default Floor