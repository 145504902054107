import { Card } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import React, { useContext, useState } from 'react'
import { useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'
import URLInput from '../../../../components/URLInput/URLInput'
import { FrontlinesContext } from '../../../../store/frontlines'
import { useFrontline } from '../../../../store/frontlines/useFrontline'

export default function Services(props) {
  const {
    initRestrictions = {},
    disableSaving,
    managingGlobal,
    setRestrictions,
    title,
    product,
  } = props
  const { getText } = useContext(TranslationsContext)

  const frontline = useFrontline()
  const frontlineCtx = useContext(FrontlinesContext)
  const { handleError } = useComponentSelectionError(disableSaving)

  // Set restrictions for this element.
  const handleChange = (checkedValues, name) => {
    setRestrictions({ disabledServices: checkedValues })
  }

  const urlMap = frontline.carDesignerUI?.services || {}

  
  // Air purifier is in accessories
  const servicesToDisplay = (product.componentsData.kcsmServices || []).filter(x => x.id !== 'KCSM_AIR_PURIFIER') // TODO constant...
  
  if (servicesToDisplay.length === 0) return <Card className="ModelAndLayout component-card">No digital services available.</Card>
  
  return (
    <Card className="ModelAndLayout component-card">
      <h2 className="component-card__title">{title}</h2>
      <CheckboxGroup
        title="Available services"
        items={servicesToDisplay}
        name="services"
        setError={handleError}
        handleChange={handleChange}
        defaultValue={initRestrictions?.disabledServices || []}
        managingGlobal={managingGlobal}
      />
      <h3 className="readmore-title">Read More links</h3>
      <div className="readmore-input-container">
        {[
          {
            label: 'Digital services tab',
            id: 'SERVICES'
          },
          ...servicesToDisplay].map((service) => {
          return (
            <URLInput
              className="ReadMoreInput"
              label={getText(service.label)}
              defaultValue={urlMap[service.id]?.url || ''}
              onSave={async (url) => {
                await frontlineCtx.saveServiceURL(frontline, { url, service: service.id })
              }}
            />
          )
        })}
      </div>
    </Card>
  )
}
