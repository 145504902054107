import React, { useState, useEffect } from 'react';
import axios from 'axios'
import config from '../../config'

export const TranslationsContext = React.createContext();

/**
 * Provider for the logged in user and related actions.
 */
export const TranslationsProvider = ({ children }) => {
  const [ translations, setTranslations ] = useState()

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/public/translation/en`)
        
        setTranslations(response.data.translation)
      } catch (error) {
        throw Error(`${error} when loading translations.`)
      }
    })()
  }, [])

  const getText = (strId) => {
    if (!translations) {
      throw Error("Translations have not been loaded. Call loadTranslations first.")
    }
    const item = translations.find(item => item.key === strId);
    if (!item) {
      return strId
    }
    return item.value
  }

  return (
    <TranslationsContext.Provider value={{
      getText
    }}>
      {translations && children}
    </TranslationsContext.Provider>
  )
}
export default TranslationsProvider;

export const TranslationsConsumer = TranslationsContext.Consumer;
