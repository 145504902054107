import React, { useState, useContext, useEffect } from 'react'
import { Switch, message, Breadcrumb, List, Card, Tag, Tooltip, Spin } from 'antd'
import dateFns from 'date-fns'
import "./ManageFrontlinePage.scss"

import LanguageSelection from '../../components/LanguageSelection'
import UserSelection from '../../components/UserSelection'
import WelcomePageManager from '../../components/WelcomePageManager'

import NotFoundPage from '../NotFoundPage'
import { UsersContext } from '../../store/users';
import { FrontlinesContext } from '../../store/frontlines';
import { AuthContext } from '../../store/auth';
import { createErrorMessage } from '../../utils/error-utils'
import { Link } from 'react-router-dom'
import { TranslationsContext } from '../../store/translations'
import { useMemo } from 'react'
import AddressFieldManager from '../../components/AddressFieldManager'
import ProductReleases from '../../components/ProductReleases'
import { groupProducts } from '../../utils/product-utils'
import frontlineUtils from '../../utils/frontline-utils'

const ManageFrontlinePage = ({ match }) => {
  const country = match.params.country

  const isMarine = country.toLowerCase() === 'marine'

  const [frontlineLoading, setFrontlineLoading] = useState(true) 
  const [ domainData, setDomainData ] = useState()

  const [notFound, setNotFound] = useState(false) // Was frontline for a country found or not.
  
  // TODO Maybe there is a better way to do this......
  const {
    frontline,
    globalFrontline,
    loadFrontline,
    save3DDesignerActiveState,
    revokePublishingRights,
    givePublishingRights,
    publishTranslation,
    addLanguagesForFrontline,
    removeLanguageFromFrontline,
    saveDefaultLanguageForFrontline,
    loadDomainData,
    saveWelcomePageUIState,
    setLanguageMobileVisibility,
    allProducts
  } = useContext(FrontlinesContext)

  const { getText } = useContext(TranslationsContext)

  const { loggedInUser } = useContext(AuthContext)

  const frontlineProducts = useMemo(() => {
    if (!frontline || !frontline.products) return []
    return frontline.products.map(product => {
      return ({
        ...product,
        name: getText(`product-${product.id}`)
      })
    })
    .sort((a, b) => {
      if (a.name < b.name) return 1
      if (a.name > b.name) return -1
      return 0
    })
  }, [frontline])

  // Initial page load
  useEffect(() => {
    if (!loggedInUser) return

    loadDomainData().then(result => {
      setDomainData(result)
    })

    loadFrontline({ country }).then(result => {
      if (result) {
        setFrontlineLoading(false)
      } else { // No frontline exists for the country.
        setNotFound(true)
      }
    }).catch((err) => {
      setNotFound(true)
    })
  }, [loggedInUser])


  if (!loggedInUser) return null

  const handle3DToggle = async (checked, fl) => {
    try {

      await save3DDesignerActiveState(fl, checked)
      const successMessage = checked ? `3D Car Designer enabled for ${fl.country.name}.` : `3D Car Designer disabled for ${fl.country.name}. Using 2D Car Designer instead.`
      message.success(successMessage)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      console.error(">err", err)

      message.error(errorMessage, 5)
    }
  }

  if (notFound) return <NotFoundPage countryName={country}></NotFoundPage>
  if (frontlineLoading) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  return (
    <div className="ManageFrontlinePage page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      <Breadcrumb.Item>{frontline.country.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">{frontline.country.name}
        { loggedInUser.role === "admin" && !isMarine &&
          <Switch
            onChange={checked => handle3DToggle(checked, frontline)}
            checked={!!frontline.active3D}
            checkedChildren={<span>3D</span>}
            unCheckedChildren={<span>2D</span>}
        />
        }
      </h1>
      
    <div className="block block-products">
      <h2 className="block__title">Products</h2>
      <div>
        <Link to={`/frontline/${frontline.hiddenId}/products`} >Manage products</Link>
      </div>
      <ProductSummaryList allProducts={allProducts} products={frontlineProducts} />
    </div>
    <div className="block block-product-releases">
      <h2 className="block__title">Product releases</h2>
        <ProductReleases />
    </div>
    <div className="block block-user-selection">
    <h2 className="block__title">Managers</h2>
    <UserSelection 
      frontline={frontline} 
    />
    </div>
    <div className="block block-language-selection">
      <h2 className="block__title">Primary languages</h2>
      <LanguageSelection 
        frontline={frontline} 
        givePublishingRights={givePublishingRights}
        revokePublishingRights={revokePublishingRights}
        languages={globalFrontline.languages.filter(x => !x.disabled && !x.hidden)}
        saveDefaultLanguageForFrontline={saveDefaultLanguageForFrontline}
        publishTranslation={publishTranslation}
        addLanguagesForFrontline={addLanguagesForFrontline}
        removeLanguageFromFrontline={removeLanguageFromFrontline}
        globalFrontline={globalFrontline}
        setMobileVisibility={setLanguageMobileVisibility}
        admin={loggedInUser.role === 'admin'}
      />
    </div>
    <div className="block block-welcome-page-manager">
    <h2 className="block__title">Welcome page sections' visibility</h2>
      <WelcomePageManager
        frontline={frontline}
        saveWelcomePageUIState={saveWelcomePageUIState}
        domainData={domainData}
      />
      </div>

      <div className="block">
        <h2 className="block__title">Address fields</h2>
        <AddressFieldManager
          frontline={frontline}
          marine={isMarine}
        />
      </div>
    </div>
  )
}

const ProductSummaryList = props => {
  const { products, allProducts } = props

  // const groups = useMemo(() => {
  //   return groupProducts(products, allProducts)
  // }, [products, allProducts])

  return (
    <List className="ProductSummaryList"
        grid={{
          gutter: 16,
          xxl: 3,
          xl: 3,
          md: 2,
          sm: 1,
          xs: 1
        }}
        locale={{
          emptyText: 'No products added for this frontline'
        }}
        dataSource={products.filter(x => !x.productDisabled)}
        renderItem={product => {
          return (
            <List.Item>
              <SummaryItem product={product} />
            </List.Item>
          )
        }}
      />
  )
}

function SummaryItem(props) {
  const { product = {} } = props

  const isReleased = useMemo(() => {
    return frontlineUtils.isProductReleased(product)
  }, [product])

  const isModernization = useMemo(() => {
    if (product.id === 'add-on-deco') return false
    return product.modernization
  }, [product])

  return (
      <Card className="product-summary-card" >
        <span className="product-name">{product.name}</span>
        <div className="tags">
          { isReleased && 
            <Tooltip 
            // title={dateFns.format(new Date(product.lastProductReleased), 'MMMM   Do, YYYY, HH:mm')} 
            >
              <Tag color="success">Released</Tag>
            </Tooltip> 
          }
          {
            isModernization &&
            <Tag color="blue">Full replacement</Tag>
          }
          {
            product.isKoneOnly &&
            <Tag color="magenta">KONE only</Tag>
          }
        </div>
      </Card>

  )
}

export default ManageFrontlinePage