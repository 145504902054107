import React, { useState, useEffect, useContext, useRef} from 'react'
import { Table, Button, Input, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import dateFns from 'date-fns'
import './FrontlinesDisplay.scss'

/**
 * 
 * @param {Object} props 
 * @param {Object} props.history 
 * @param {Object[]} props.frontlines
 * @param {boolean} props.loading
 */
const FrontlinesDisplay = (props) => {
  const { history, frontlines, loading } = props
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef()

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  // Sets up the column search functionality. Searching by dataIndex.
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      // Select the searchInput when the search panel is opened.
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select()
          } 
        }, 0)
      }
    }
  })

  const columns = [
    {
      title: "Frontline",
      dataIndex: ['country', 'name'],
      key: "frontline",
      width: "40%",
      sorter: (a, b) => {
        if (a.country.name < b.country.name) return -1
        if (a.country.name > b.country.name) return 1
        return 0
      },
      ...getColumnSearchProps('country') // for search
    },
    {
      title: "Last Updated",
      dataIndex: "_ts",
      key: "_ts",
      width: "30%",
      sorter: (a, b) => b._ts - a._ts,
      render: (_ts, record, index) => {
        return dateFns.format(new Date(_ts * 1000), 'MMMM Do, YYYY')
      }
    }, {
      title: "3D Status",
      dataIndex: "active3D",
      key: "active3D",
      width: "30%",
      sorter: (a, b) => {
        if (a.active3D && !b.active3D) {
          return -1
        } else if (!a.active3D && b.active3D) {
          return 1
        }

        return 0
      },
      render: (status, record, i) => {
        if (record.hiddenId === 'marine') return null
        return status ? <Tag color="green">3D</Tag>
          : <Tag color="red">2D</Tag>
      }
    }
  ]

  return (
      <Table
        loading={loading}
        className="FrontlinesDisplay"
        scroll={{ y: '56vh' }}
        dataSource={frontlines.sort((fl1, fl2) => {
          if (fl1.country.name > fl2.country.name) return 1
          if (fl1.country.name < fl2.country.name) return -1
          return 0
        })}
        columns={columns}
        onRow={record => {
          return {
            onClick: e => history.push(`/frontline/${record.hiddenId}`)
          }
        }}
        rowKey={frontline => frontline.id}
        pagination={false}
      ></Table>
  )
}

export default FrontlinesDisplay