export function isProductReleased(product = {}) {
  if (product.productDisabled) return false

  if (!product.productReleases) return false

  return !!product.productReleases.find(x => x.lastProductReleased)
}

export default {
  isProductReleased,
}