import React, { useContext, useRef } from 'react';
import axios from 'axios'
import { message } from 'antd'
import config from '../../config'
import { AuthContext } from '../auth/AuthProvider';
import { createError } from '../../utils/error-utils'

export const APIContext = React.createContext();



/**
 * Provider for API calls.
 * Separate context for these so the API calls used would always have the token auth included.
 */
export const APIProvider = ({ children }) => {
  const { getAuthHeaders } = useContext(AuthContext)
  const displayingError = useRef()

  const get = async (path, params = {}) => {
    const headers = await getAuthHeaders()

    try {
      const result = await axios.get(`${config.baseUrl}${path}`, 
      { headers })
      return result.data
    } catch (err) {
      console.error(err)
      throw createError(err)
    }
  }

  const post = async (path, data) => {
    const headers = await getAuthHeaders()
    try {
      const result = await axios.post(`${config.baseUrl}${path}`, data, 
      { headers })
      return result.data
    } catch (err) {
      console.error(err)
      throw createError(err)
    }
  }

  const del = async (path, params = {}) => {
    const headers = await getAuthHeaders()

    try {
      const result = await axios.delete(`${config.baseUrl}${path}`, 
      { headers })
      return result.data
    } catch (err) {
      console.error(err)
      throw createError(err)
    }
  }

  const checkAuth = async () => {
    const headers = await getAuthHeaders()
    const result = await axios.get((`${config.authUrl}/check`), 
    { headers })
    return result.data
  }

  return (
    <APIContext.Provider value={{
      get, post, delete: del, checkAuth,
    }}>
      {children}
    </APIContext.Provider>
  )
}
export default APIProvider;

export const APIConsumer = APIContext.Consumer;