import React from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import './ButtonWithPopconfirm.scss'

/**
 * Button that does not show popconfirm on click when it has been disabled 
 * (unlike the default ant-design behavior).
 * @param {Object} props
 * @param {Function} props.onConfirm - Action to perform
 * @param {boolean=} props.confirmation - Whether to ask for confirmation
 * @param {string=} props.okText - Text of the confirmation OK button
 * @param {string=} props.confirmText - Confirmation text
 * @param {*=} props.children
 * @param {boolean=} props.disabled
 * @param {string=} props.disabledTooltipText - tooltip to show on hover when button is disabled
 * @param {string=} props.popconfirmPlacement
 * @param {string=} props.tooltipPlacement
 * @param {string=} props.tooltipText
 * @param {string=} props.className
 */
const ButtonWithPopconfirm = (props) => {
  const { 
    onConfirm, // Action to perform.
    confirmation=true, // Whether to ask for confirmation.
    okText="Yes",
    confirmText="Are you sure?", 
    children,
    disabled,
    disabledTooltipText,
    popconfirmPlacement="top",
    tooltipPlacement="bottom",
    tooltipText,
    className='',
    ...rest } = props

  const getButtonWithTooltip = ({onClick = () => { }, title, mouseEnterDelay = 0.5}) =>  (
    <Tooltip
      className="ButtonWithPopconfirm-tooltip"
      placement={tooltipPlacement}
      mouseEnterDelay={mouseEnterDelay}
      overlayStyle={{ zIndex: 1000 }}

      title={title || tooltipText}>
      <Button
        disabled={disabled}
        onClick={onClick}
        className={`ButtonWithPopconfirm ${className}`}
        {...rest}
      >{children}</Button>
    </Tooltip>
    )

  if (disabled) {
    return getButtonWithTooltip({title: disabledTooltipText, mouseEnterDelay: 0 })
  }

  // Execute the action directly if no confirmation.
  if (!confirmation) {
    return getButtonWithTooltip({onClick: onConfirm })
  }

  return (
    <Popconfirm
      onConfirm={onConfirm}
      title={confirmText}
      okText={okText}
      placement={popconfirmPlacement}
      overlayStyle={{maxWidth: "25rem"}}
    >
      {getButtonWithTooltip({})}
    </Popconfirm>
  )
}

export default ButtonWithPopconfirm