import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import axios from 'axios'
import { message } from 'antd'
import * as msal from "@azure/msal-browser";
import { ConfigContext } from '../config';
import { baseUrl } from '../../config'
import FailurePage from '../../pages/FailurePage'


export const AuthContext = React.createContext();


export const AuthProvider = ({ children }) => {
  const config = useContext(ConfigContext)
  const [error, setError] = useState()
  const [loggedInUser, setLoggedInUser] = useState()

  const adminRights = useMemo(() => {
    if (!loggedInUser) return
    return loggedInUser.role === "admin"
  }, [loggedInUser])

  const msalApp = useMemo(() => {
    const msalObj = new msal.PublicClientApplication({
      auth: {
        clientId: config.clientID,
        authority: 'https://login.microsoftonline.com/kone.onmicrosoft.com',
        validateAuthority: true,
        redirectUri: `${window.location.protocol}//${window.location.host}`,
      },
      cache: {
        cacheLocation: 'sessionStorage'
      }
    })

    msalObj.handleRedirectPromise().then(async tokenResponse => {
      let accountObj = null
      if (tokenResponse) {
        accountObj = tokenResponse.account
      } else {
        const currentAccounts = msalObj.getAllAccounts()
        if (!currentAccounts?.length) {
          // No user signed in
          signIn()
          return
        } else {
          // Can there be more than one? If so, which one to choose?
          accountObj = currentAccounts[0]
        }
      }

      if (accountObj) {
        msalObj.setActiveAccount(accountObj)

        const headers = await getAuthHeaders()
    
        try {
          const result = await axios.get(`${baseUrl}/user/self`, { headers })
          const newUser = result.data

          setLoggedInUser(newUser)
        } catch (err) {
          setError(err)
        }
      } else {
        signIn()
      }
    })

    return msalObj
  }, [])

  const GRAPH_REQUESTS = {
    LOGIN: {
      scopes: ['user.read']
    },
    API: {
      scopes: ['api://893879db-45d8-434c-a566-3fafc3bb4f29/API']
    }
  }

  async function getAuthHeaders() {
    try {

      const token = await acquireToken(GRAPH_REQUESTS.API)
      
      if (!token) return {}
      
      return {
        Authorization: `Bearer ${token.accessToken}`, // the token is a variable which holds the token
      }
    } catch (err) {
      console.error(err)
      message.error('Failed to acquire auth token')
      throw err
    }
  }

  async function acquireToken(request) {
    try {
      const token = await msalApp.acquireTokenSilent(request)
      if (!token) {
        throw new Error('No token')
      }

      return token
    } catch (err) {
      console.error('Failed to get token silently', err)
      return msalApp.acquireTokenRedirect(request)
    }
  }

  async function signIn() {
    return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN)
  }

  async function signOut() {
    msalApp.logoutRedirect()
  }

  return (
    <AuthContext.Provider value={{
      getAuthHeaders, signIn, signOut, loggedInUser, adminRights
    }}>
      {error ? <FailurePage logout={signOut}></FailurePage> :
        loggedInUser && children
      }
    </AuthContext.Provider>
  )
}
export default AuthProvider;

export const AuthConsumer = AuthContext.Consumer;