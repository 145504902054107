import React, { useState, useContext, useMemo } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { TranslationsContext } from '../../store/translations';
import './CheckboxGroup.scss'
import { categorizeItemsBy } from '../../utils/general-utils';


// Group of checkboxes, for which the selected and non-selected state styles are swapped!
// So if the checkbox is not selected, it looks like it is selected and vice versa.
const CheckboxGroup = ({ title, name,
  handleChange, handleCheckAllChange,
  items, requireSelection, requiredSelections = 1,
  setError = () => { },
  errorMsg = "You need to select at least one option.",
  defaultValue,
  innerProp = 'finishes',
  mainTitle,
  managingGlobal,
  className = '',
  extra,
  debug,
  disableSort,
  categorizeBy,
  categoryTranslationPrefix = 'finish-category-',
  checkBoxHeightRem = 2.5,
  checkBoxGapRem = 0.15,
  checkBoxesInRow = 4,
  sap = true,
  checkAll, subtitle, ...rest }) => {
  const { getText } = useContext(TranslationsContext)
  const [errorMessage, _setErrorMessage] = useState('')

  function setErrorMessage(msg) {
    _setErrorMessage(msg)
    setError(name, msg)
  }

  const allChecked = !defaultValue || Array.isArray(defaultValue) ? false : defaultValue.disabled

  const [checkedList, setCheckedList] = useState(() => {
    if (allChecked) return items.map(item => item.id)

    const checkedItems = !defaultValue || Array.isArray(defaultValue) ? defaultValue : defaultValue[innerProp]

    return checkedItems || []
  })

  const [checkAllChecked, setCheckAllChecked] = useState(allChecked)

  const filteredItems = useMemo(() => {
    return items.filter(item => {
      return managingGlobal || !item.disabled
    })
  }, [managingGlobal, items])

  const categorizedItems = useMemo(() => {
    return categorizeItemsBy(filteredItems, categorizeBy)
      .sort((a, b) => {
        const [a_category] = a
        const [b_category] = b

        if (!a_category) return 1
        if (!b_category) return -1

        const a_name = getText(categoryTranslationPrefix + a_category)
        const b_name = getText(categoryTranslationPrefix + b_category)

        if (a_name < b_name) return -1
        if (a_name > b_name) return 1
        return 0
        { }
      })
  }, [categorizeBy, filteredItems])

  const handleSelection = checkedItems => {
    // The item that was just now checked.
    // Difference of the last and current checked items.
    const nowChanged = _.xor(checkedList, checkedItems)[0]
    // If the item was checked (true) or unchecked (false)
    const wasChecked = checkedItems.length > checkedList.length

    setCheckedList(checkedItems.sort())
    // If selection is required but none is selected, set an error.
    // NOTE: Checkbox state is inverted (selected is non-selected and vice versa)!
    if (requireSelection && (items.filter(x => managingGlobal || !x.disabled).length - checkedItems.length) < requiredSelections) {
      setErrorMessage(errorMsg)
    } else {
      setErrorMessage('')
    }
    handleChange(checkedItems.sort(), name, nowChanged, wasChecked)
  }

  const checkAllChange = (e) => {
    const checked = e.target.checked
    setCheckAllChecked(checked)

    if (!checked) {
      // When ticking the box, check that there is at least one inner item selected, else display an error
      if (requireSelection && (items.filter(x => managingGlobal || !x.disabled).length - checkedList.length) < requiredSelections) {
        setErrorMessage(errorMsg)
      } else {
        setErrorMessage('')
      }
    } else {
      setErrorMessage('')
    }

    handleCheckAllChange(name, checked)
  }

  if (!managingGlobal && checkAll && items.filter(i => !i.disabled).length === 0) return null

  if (!managingGlobal && (!items || items.filter(x => !x.disabled).length === 0)) {
    return null
  }

  

  return (
    <div className={`CheckboxGroup ${className}`}>
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      { checkAll ?
        <div className="check-all-panel">
          <ReverseCheckbox
            onChange={checkAllChange}
            checked={checkAllChecked}
          >
            {getText(title)}
          </ReverseCheckbox>
        </div>
        : title ?
        <h3 className={`CheckboxGroup__title ${mainTitle ? 'main-title' : ''}`}>
          {getText(title)}
          {/* {requireSelection ? <span className="required"> *</span> : null} */}
        </h3> : null
      }
      <Checkbox.Group
        name={name}
        onChange={handleSelection}
        // defaultValue={defaultValue}
        value={checkedList}
        disabled={checkAllChecked}
        className={`${checkAll ? 'sub-group' : ''} ${checkAllChecked ? ' hidden' : ''}`
        }
        {...rest}
      >
        {
          <div
            className={'category-container'}
          >
            {
              categorizedItems.map(group => {
                const [category, items] = group

                return (
                  <div className="category" key={category}>
                    { category && <h3 className="category-name">{getText(categoryTranslationPrefix + category)}</h3>}
                    <div className="options-container"
                      style={{ height: calculateCheckboxContainerHeight(items.length) }}>
                      {items
                        .sort((a, b) => {
                          if (disableSort) return 0
                          if (a.sapId && b.sapId && sap) {
                            if (a.sapId > b.sapId) return 1
                            if (a.sapId < b.sapId) return -1
                            return 0
                          } else {
                            const nameA = getText(a.stringid || a.name || a.label)
                            const nameB = getText(b.stringid || b.name || b.label)
                            if (nameA > nameB) return 1
                            if (nameA < nameB) return -1
                            return 0
                          }
                        })
                        .map(item => (
                          <ReverseCheckbox value={item.id} key={item.id}
                            disabled={item.selectionDisabled}
                            style={getCheckboxStyle({ checkBoxGapRem, checkBoxesInRow, checkBoxHeightRem })}
                            className={item.selectionDisabled ? `disabled-selected` : ''}
                          >
                            {
                              item.sapId && sap &&
                              <span>
                                <span className="sapId">{item.sapId}</span> -
                            </span>
                            }
                            { getText(item.stringid || item.name || item.label)}
                          </ReverseCheckbox>
                        ))}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </Checkbox.Group>
    </div>
  )
}

export function ReverseCheckbox(props) {
  const { className = '', children, ...rest } = props

  return (
    <Checkbox className={`ReverseCheckbox ${className}`} {...rest}>
      { children }
    </Checkbox>
  )  
}

export function getCheckboxStyle(args = {}) {
  const { 
    checkBoxesInRow = 4, checkBoxGapRem = 0.15, checkBoxHeightRem = 2.5
   } = args
  return {
    width: 100 / checkBoxesInRow + '%',
    height: checkBoxHeightRem + 'rem',
    marginBottom: checkBoxGapRem + 'rem',
    // marginRight: checkBoxGapRem + 'rem',
  }
}

export function calculateCheckboxContainerHeight(amountOfItems, args = {}) {
  const {
    checkBoxesInRow = 4, checkBoxGapRem = 0.15, checkBoxHeightRem = 2.5
  } = args
  
  const spaceRequired = checkBoxHeightRem + checkBoxGapRem

  const times = Math.ceil(amountOfItems / checkBoxesInRow)

  return spaceRequired * times + 'rem'
}


export default CheckboxGroup