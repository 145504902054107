import React, { useState, useMemo, useContext, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { message, Card, Radio, Select, DatePicker, ConfigProvider, Switch, Table, Modal, Row, Col, Button } from 'antd'
import moment from 'moment';

import 'moment/locale/en-gb'
import locale from 'antd/lib/locale/en_GB'

import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner'
import useService from '../../../../services/useService'
import countryMapping from '../../../../constants/ktocCountryMapping.json'

import './CadRequests.scss'
import ExportDataButton from '../../../../components/ExportDataButton/ExportDataButton';
import elevIcon from './elevator.svg'
import escIcon from './escalator.svg'
import Column from 'antd/lib/table/Column';

const { RangePicker } = DatePicker;
const { Option } = Select

const BIM_FLCAD_SAMESERVER= [
  'G',
]
const excelHeaders = [
  { key: 'country', label: 'Country' },
  { key: 'platform', label: 'Platform' },
  { key: 'version', label: 'Version' },
  { key: 'Timestamp', label: 'Timestamp' },
]


function compareCreatedDates( a, b) {

  const aDate = new Date(a.createdAt).getTime()
  const bDate = new Date(b.createdAt).getTime()

  if ( aDate < bDate ){
    return 1;
  }
  if ( aDate > bDate ){
    return -1;
  }
  return 0;
}

function compareStrings( a, b, key) {

  const aVal = a[key]
  const bVal = b[key]

  if ( aVal < bVal ){
    return 1;
  }
  if ( aVal > bVal ){
    return -1;
  }
  return 0;
}

function formatDate(date) {
  return new Date(date).toLocaleString() 

}

/* const TABLE_COLUMNS = [
  { 
    title: 'Config ID',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
  },
  { 
    title: 'Design ID',
    dataIndex: 'plannerDesignId',
    key: 'designId',
  },
  { 
    title: 'Email',
    dataIndex: 'email',
    sortDirections: ['ascend','descend','ascend'],
    sorter: (a, b) => compareStrings(a,b,'email'),
    render: (text, record) => {
      return (
        record.emailToSend
        ? <div key={record.emailToSend+'main'}>
            <div key={record.emailToSend} >{record.emailToSend}</div>
            <div key={record.emailStatus} className={'status-indicator '+record.emailStatus}>{record.emailStatus}</div>
          </div>
        : <span key={'none'} className={'status-indicator noneState'}>Not set</span>
      )
    }
  },
  { 
    title: 'Requested at',
    dataIndex: 'createdAt',
    key: 'created',
    defaultSortOrder: 'ascend',
    sortDirections: ['ascend','descend','ascend'],
    sorter: (a, b) => compareCreatedDates(a,b),
    render: created => {
      return formatDate(created)
    }
  },
  { 
    title: 'Type',
    dataIndex: 'projectType',
    key: 'projectType',
    sortDirections: ['ascend','descend','ascend'],
    sorter: (a, b) => compareStrings(a,b,'projectType'),
    render: projectType => {
      return (
        projectType === 'elevator' 
        ? <span key={projectType} ><img src={elevIcon}/></span>
        : <span key={projectType} ><img src={escIcon}/></span>
      )
    }
  },
  { 
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    sortDirections: ['ascend','descend','ascend'],
    sorter: (a, b) => compareStrings(a,b,'product'),
  },
  { 
    title: 'FLCAD Status',
    dataIndex: 'flcadStatus',
    render: (text, record) => {
      return (
        record.progressStatusFLCAD 
        ? record.progressStatusFLCAD === 'Complete'
          ?<div key={record.progressStatusFLCAD}>
              <div key={record.progressStatusFLCAD} className={'status-indicator '+record.progressStatusFLCAD}>{record.progressStatusFLCAD}</div>
              <div key={record.progressStatusFLCAD+'url'} ><a href={record.blobFlcadUri} target="_new">Open result</a></div>
              <div key={record.flcadDate} >{formatDate(record.flcadDate)}</div>
            </div>
          :<span key={record.progressStatusFLCAD} className={'status-indicator '+record.progressStatusFLCAD}>{record.progressStatusFLCAD}</span>
        : <span key={'none'} className={'status-indicator noneState'}>Not requested</span>
      )
    }
  },
  { 
    title: 'BIM Status',
    dataIndex: 'bimStatus',
    render: (text, record) => {
      return (
        record.progressStatusBIM 
        ? record.progressStatusBIM === 'Complete'
          ?<div key={record.progressStatusBIM}>
              <div key={record.progressStatusBIM} className={'status-indicator '+record.progressStatusBIM}>{record.progressStatusBIM}</div>
              <div key={record.progressStatusBIM+'url'} ><a href={record.blobBimUri} target="_new">Open result</a></div>
              <div key={record.bimDate} >{formatDate(record.bimDate)}</div>
            </div>
          :<span key={record.progressStatusBIM} className={'status-indicator '+record.progressStatusBIM}>{record.progressStatusBIM}</span>
        : <span key={'none'} className={'status-indicator noneState'}>Not requested</span>
      )
    }
  },
  { 
    title: 'Actions',
    dataIndex: 'actions',
    render: (text, record) => {
      return (
       <div key={'details'} >Details</div>
      )
    }
  },

] */

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function CadRequests(props) {
  const {
    className = ''
  } = props

  const [tableEntries, setTableEntries] = useState()
  const [continuationToken, setContinuationToken] = useState(null)
  const [itemsOfThePage, setItemsOfThePage] = useState(20)
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [timeSelect, setTimeSelect] = useState('null,3')
  const [timeRange, setTimeRange] = useState(null)

  const logService = useService('log')

  useEffect(() => {
    if (!logService) return

    logService.getCadEntries({lastDays:null,lastHours:3,fromDay:null,toDay:null,continuationToken:null}).then(([result,newContinuationToken]) => {
      /* Example entry:
          {
            "PartitionKey": "CAD",
            "RowKey": "DEV-51270_7226794261447764_1660111559012",
            "Timestamp": "2022-08-10T06:06:54.693Z",
            "blobBimUri",
            "blobFlcadUri": "https://qakcd3dsa.blob.core.windows.net/images/PlannerResults%2F2022%2F8%2F10%2FF000042578.zip?sv=2020-10-02&st=2022-04-22T10%3A24%3A51Z&se=2042-04-23T10%3A24%3A00Z&sr=c&sp=racwl&sig=u3CLjy5G0wtDNH%2B55OjHiVi5oKSyTquRjaZC0Du139E%3D",
            "blobUri": "https://qakcd3dsa.blob.core.windows.net/images/PlannerXMLs%2F2022%2F8%2F10%2FDEV-51270_7226794261447764_1660111559012_1660111559137.xml",
            "configSummary": "none",
            "constructionSpec": "none",
            "contactData",
            "contactStatus",
            "createdAt": "2022-08-10T06:05:59.856Z",
            "destinationCountry": "IN",
            "emailStatus",
            "emailToSend",            
            "errorMessage",
            "failingReasonBIM",
            "failingReasonFLCAD",
            "method": "POST",
            "plannerDesignId": "7226794261447764",
            "plannerHostname": "kone-dev.conceptconfigurator.com",
            "plannerLinkId": "",
            "plannerLocaleId": "en:US:--",
            "plannerUserId": "",
            "planningGuide": "none",
            "processingServerBIM",
            "processingServerFLCAD",
            "product": "AMonoSpace",
            "productModel": "AO",
            "productName": "AMonoSpace@",
            "progressStatusFLCAD": "Complete",
            "progressStatusBIM",
            "projectType": "elevator",
            "serialNumber": "DEV-51270",
            "status": 202,
            "techSpecSummary": "https://qakcd3dsa.blob.core.windows.net/images/cadBrochures%2F2022%2F8%2F10%2FDEV-51270_1660111602175.pdf",
            "uri": "/api/cad/?",
            "userAgent": "Apache-HttpClient/4.5.5 (Java/1.8.0_211)",
            "username": "configure-one"
            flcadDate,
            bimDate,
            downloadServer,
          },
        */
       const formatted = result.filter(item => item.status === 202) //.filter(item => !item.userAgent.includes('PostmanRuntime'))
       formatted.sort(compareCreatedDates)
      setTableEntries(formatted)
      setContinuationToken(newContinuationToken)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })
  }, [logService])

  const handleTimeBackClick = (value) => {
    if (!logService) return
    setLoading(true)
    const times = value.split(',')
    setTimeSelect(value)

    if(value === 'null,null') {
      setLoading(false)
      return
    }

    logService.getCadEntries({lastDays:times[0], lastHours:times[1],fromDay:null,toDay:null, continuationToken:null}).then(([result,newContinuationToken]) => {
      const formatted = result.filter(item => item.status === 202).filter(item => !item.userAgent.includes('PostmanRuntime'))
      formatted.sort(compareCreatedDates)
      console.log({result})
      // setTableEntries(formatted)
      setTableEntries(formatted)
      setContinuationToken(newContinuationToken)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })    
  }

  const handleTimeRangeClick = (value) => {
    if (!logService) return
    setLoading(true)

    logService.getCadEntries({lastDays:null, lastHours:null,fromDay:timeRange.from,toDay:timeRange.to, continuationToken:null}).then(([result,newContinuationToken]) => {
      const formatted = result.filter(item => item.status === 202).filter(item => !item.userAgent.includes('PostmanRuntime'))
      formatted.sort(compareCreatedDates)
      console.log({result})
      // setTableEntries(formatted)
      setTableEntries(formatted)
      setContinuationToken(newContinuationToken)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })    
  }

  const handleDetailsClick = (id) => {
    const requestById = tableEntries.find(item => item.RowKey === id)
    Modal.info({
      title: `Details about the request ${requestById.serialNumber}`,
      content: <RequestDetails request={requestById} />,
      okText: 'Ok',
      onOk() {
      },
      width:'80%',
      className:"modalArea"
    })
  }

  const loadMoreItems = (e) => {
    setLoadingMore(true)
    const times = timeSelect.split(',')
    const from = (timeRange && timeSelect === 'null,null') ?timeRange.from :null
    const to = (timeRange && timeSelect === 'null,null') ?timeRange.to :null
    logService.getCadEntries({lastDays:times[0], lastHours:times[1],fromDay:from,toDay:to, continuationToken}).then(([result,newContinuationToken]) => {
      console.log({result})
      const formatted = result.filter(item => item.status === 202).filter(item => !item.userAgent.includes('PostmanRuntime'))
      formatted.sort(compareCreatedDates)
      // setTableEntries(formatted)
      setTableEntries([...tableEntries,...formatted])
      setContinuationToken(newContinuationToken)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoadingMore(false)
    })    
  }
  
  const onDateChange = (dates) => {

    if (!dates) {
      setTimeRange(null)
    } else {
      var d0, d1
      d0 = new Date(dates[0]._d).toISOString()
      d1 = new Date(dates[1]._d).toISOString()
      setTimeRange({
        from: d0,
        to: d1,
        rawDates: dates
      })
    }

  }
  return (
    <>
      <div data-testid="CadRequests" className={`CadRequests ${className}`}>
        <Card 
          title={"RICK Requests"}
          loading={loading}
          extra= {
            <>
              <span>Show requests from </span>
              <Select defaultValue={timeSelect} onChange={handleTimeBackClick} style={{width:'150px'}}>
                <Option value={'null,3'} >last 3 hours</Option>
                <Option value={'1,null'} >last 24 hours</Option>
                <Option value={'7,null'} >last 7 days</Option>
                <Option value={'31,null'} >last 31 days</Option>
                <Option value={'180,null'} >last 180 days</Option>
                <Option value={'null,null'} >selected timerange</Option>
              </Select>
              { timeSelect === 'null,null' &&
              <ConfigProvider locale={locale}>
                <RangePicker
                  picker="date"
                  style={{marginLeft:'10px'}}
                  defaultValue={(timeRange && Array.isArray(timeRange.rawDates)) ? timeRange.rawDates.map((date) => moment(date._d)) : null}
                  onChange={onDateChange}
                />
                { timeRange !== null &&
                  <Button onClick={e => handleTimeRangeClick(e)} label="Get requests" style={{marginLeft:'10px'}}>Get requests</Button>
                }
              </ConfigProvider>
              }

            </>
          }
          className="requests-container">
  {/*         <Table
            className={`RequestStatusTable ${className}`}
            dataSource={tableEntries}
            pagination={false}
            columns={TABLE_COLUMNS}
          />
  */}
          <LoadingSpinner loading={loading}>
            <Table
              className={`RequestStatusTable ${className}`}
              dataSource={tableEntries}
              // pagination={true}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 20,
                onShowSizeChange: (current, value)=>setItemsOfThePage(value),
              }}
              >
                <Column title="Config ID" dataIndex={'serialNumber'} key="serialNumber"/>
                <Column title="Design ID" dataIndex={'plannerDesignId'} key="designId"/>
                <Column title="Page visited" dataIndex={'pageVisited'} key="downloadPageOpened"
                  render={
                    (text,record) => {
                      return (
                        record.downloadPageOpened
                        ? <div key={'dPageOpened'} className={'status-indicator Ready'}>Yes</div>
                        : <div key={'dPageOpened'} className={'status-indicator Failed'}>No or Unknown</div>
                      )
                    }
                  }
                />
                <Column title="Email" dataIndex={'email'} key="email" sortDirections={['ascend','descend','ascend']} sorter={(a, b) => compareStrings(a,b,'email')}
                  render={
                    (text, record) => {
                      return (
                        record.emailToSend
                        ? <div key={record.emailToSend+'main'}>
                            <div key={record.emailToSend} >{record.emailToSend}</div>
                            <div key={record.emailStatus} className={'status-indicator '+record.emailStatus}>{record.emailStatus}</div>
                          </div>
                        : <span key={'none'} className={'status-indicator noneState'}>Not set</span>
                      )
                    }
                  }
                />
                <Column title="Requested at" dataIndex={'createdAt'} key="created" defaultSortOrder='ascend' sortDirections={['ascend','descend','ascend']} sorter={(a, b) => compareCreatedDates(a,b)}
                  render={
                    created => {
                      return formatDate(created)
                    }
                  }
                />
                <Column title="Type" dataIndex={'projectType'} key="projectType" sortDirections={['ascend','descend','ascend']} sorter={(a, b) => compareStrings(a,b,'projectType')}
                  render={
                    projectType => {
                      return (
                        projectType === 'elevator' 
                        ? <span key={projectType} ><img src={elevIcon}/></span>
                        : <span key={projectType} ><img src={escIcon}/></span>
                      )
                    }
                  }
                />
                <Column title="Product" dataIndex={'product'} key="product" sortDirections={['ascend','descend','ascend']} sorter={(a, b) => compareStrings(a,b,'product')} />
                <Column title="FLCAD Status" dataIndex={'flcadStatus'} key="flcadStatus" 
                  render={
                    (text, record) => {
                      return (
                        record.progressStatusFLCAD
                        ? record.progressStatusFLCAD === 'Complete'
                          ?<div key={record.progressStatusFLCAD}>
                              <div key={record.progressStatusFLCAD} className={'status-indicator '+record.progressStatusFLCAD}>{record.progressStatusFLCAD}</div>
                              <div key={record.progressStatusFLCAD+'url'} ><a href={record.blobFlcadUri} target="_new">Open result</a></div>
                              <div key={record.flcadDate} >{formatDate(record.flcadDate)}</div>
                            </div>
                          :<span key={record.progressStatusFLCAD} className={'status-indicator '+record.progressStatusFLCAD}>{record.progressStatusFLCAD}</span>
                        : ( (record.progressStatusBIM && BIM_FLCAD_SAMESERVER.find( item => item === record.productModel) )
                          ? <div key={record.progressStatusBIM}>
                              <div key={record.progressStatusBIM} className={'status-indicator '+record.progressStatusBIM}>{record.progressStatusBIM}</div>
                              <div key={record.progressStatusBIM+'url'} ><a href={record.blobFlcadUri} target="_new">Open result</a></div>
                              <div key={record.bimDate} >{formatDate(record.bimDate)}</div>
                            </div>
                          : <span key={'none'} className={'status-indicator noneState'}>Not requested</span>
                        ) 
                      )
                    }
                  }
                />
                <Column title="BIM Status" dataIndex={'bimStatus'} key="bimStatus" 
                  render={
                    (text, record) => {
                      return (
                        record.progressStatusBIM 
                        ? record.progressStatusBIM === 'Complete'
                          ?<div key={record.progressStatusBIM}>
                              <div key={record.progressStatusBIM} className={'status-indicator '+record.progressStatusBIM}>{record.progressStatusBIM}</div>
                              <div key={record.progressStatusBIM+'url'} ><a href={record.blobBimUri} target="_new">Open result</a></div>
                              <div key={record.bimDate} >{formatDate(record.bimDate)}</div>
                            </div>
                          :<span key={record.progressStatusBIM} className={'status-indicator '+record.progressStatusBIM}>{record.progressStatusBIM}</span>
                        : <span key={'none'} className={'status-indicator noneState'}>Not requested</span>
                      )
                    }
                  }
                />
                <Column title="Actions" dataIndex={'actions'} key="actions" 
                  render= {
                    (text, record) => {
                      return (
                      <div key={'details'} className="actionLink" onClick={e => handleDetailsClick(record.RowKey)}>Show details</div>
                      )
                    }
                  }
                />
            </Table>
            
            <LoadingSpinner loading={loadingMore}>
              {continuationToken !== null &&
                <Button onClick={(e)=>{loadMoreItems(e)}} type="primary" block >Load more items</Button>
              }
            </LoadingSpinner>

          </LoadingSpinner>
        </Card>

  {/*         <ExportDataButton
            filename="KTOC_log_export"
            sheetName="Data"
            data={filteredData}
            headers={excelHeaders}
          />
  */}   
      </div>
    </>

  )
}

function RequestDetails(props) {
  const {
    request,
  } = props
 
  return (  
    <div className="modalView">
      <Row width="100%" gutter={10} style={{paddingBottom:'10px'}}>
        <Col span={8}>
          <Card title="General" style={{width:'100%'}}>
            <InformationItem label="Configuration Id" value={request.serialNumber} />
            <InformationItem label="Design id" value={request.plannerDesignId} />
            <InformationItem label="Request sent" value={formatDate(request.createdAt)} />
          </Card>  
        </Col>
        <Col span={8}>
          <Card title="Planner related information" style={{width:'100%'}}>
            <InformationItem label="Planner hostname" value={request.plannerHostname} />
            <InformationItem label="User id" value={request.plannerUserId} />
            <InformationItem label="Link id" value={request.plannerLinkId} />
            <InformationItem label="Local language id" value={request.plannerLocaleId} />
          </Card>  
        </Col>
        <Col span={8}>
          <Card title="Request related information" style={{width:'100%'}}>
            <InformationItem label="Product type" value={request.projectType} />
            <InformationItem label="Product name" value={request.product} />
            <InformationItem label="Product model code" value={request.productModel} />            
            <InformationItem label="Destination country code" value={request.destinationCountry} />            
            <InformationItem label="Request XML" value={request.blobUri} link={true} linkLabel="Download XML" />            
            <InformationItem label="Download page visited" value={request.downloadPageOpened ?'Yes' :'No or Unknown'} />
            {(request.downloadUrl) &&
              <InformationItem label="Link to the download page" value={request.downloadUrl} link={true} linkLabel="Open page" />
            }

          </Card>  
        </Col>
      </Row>
      <Row width="100%" gutter={10} style={{paddingBottom:'10px'}}>
        <Col span={8}>
          <Card title="FLCAD related information" style={{width:'100%'}}>
            {request.progressStatusFLCAD
              ? (
                <>
                  <InformationItem label="Processing status" value={request.progressStatusFLCAD} useValueAsStyle={true} />
                  {request.progressStatusFLCAD === 'Failed' &&
                    <InformationItem label="Failing caused by" value={request.failingReasonFLCAD} />
                  }
                  <InformationItem label="Processing FLCAD server" value={request.processingServerFLCAD} />
                  {request.blobFlcadUri &&
                    <InformationItem label="Result files" value={request.blobFlcadUri} link={true} linkLabel="Download zip file" />
                  }
                  {request.flcadDownloaded &&
                    <InformationItem label="Result file downloaded" value={request.flcadDownloaded+(request.flcadDownloaded === 1 ?' time' :' times')} />
                  }
                </>
              )
              : ( (request.progressStatusBIM && BIM_FLCAD_SAMESERVER.find( item => item === request.productModel))
                ? (
                  <>
                    <InformationItem label="Processing status" value={request.progressStatusBIM} useValueAsStyle={true} />
                    <InformationItem label="Processing FLCAD server" value={request.processingServerBIM} />
                    {request.blobFlcadUri &&
                      <InformationItem label="Result files" value={request.blobFlcadUri} link={true} linkLabel="Download zip file" />
                    }
                    {request.flcadDownloaded &&
                      <InformationItem label="Result file downloaded" value={request.flcadDownloaded+(request.flcadDownloaded === 1 ?' time' :' times')} />
                    }
                  </>
                  ) 

                : <></>
              )
            }
          </Card>  
        </Col>
        <Col span={8}>
          <Card title="BIM related information" style={{width:'100%'}}>
            <InformationItem label="Processing status" value={request.progressStatusBIM} useValueAsStyle={true} />
            {request.progressStatusBIM === 'Failed' &&
              <InformationItem label="Failing caused by" value={request.failingReasonBIM} />
            }
            <InformationItem label="Processing BIM server" value={request.processingServerBIM} />
            {request.blobBimUri &&
              <InformationItem label="Result files" value={request.blobBimUri} link={true} linkLabel="Download zip file" />
            }
            {request.bimDownloaded &&
              <InformationItem label="Result file downloaded" value={request.bimDownloaded+( request.bimDownloaded===1 ?' time' :' times')} />
            }
          </Card>  
        </Col>
        <Col span={8}>
          <Card title="Related brochures" style={{width:'100%'}}>
            {request.configSummary !== 'none' &&
              <InformationItem label="Configuration summary" value={request.configSummary} link={true} linkLabel="Download summary" />
            }
            {request.constructionSpec !== 'none' &&
              <InformationItem label="Construction specification" value={request.constructionSpec} link={true} linkLabel="Download specification" />
            }
            {request.planningGuide !== 'none' &&
              <InformationItem label="Planning guide" value={request.planningGuide} link={true} linkLabel="Download guide" />
            }
            {request.techSpecSummary !== 'none' &&
              <InformationItem label="Technical specification" value={request.techSpecSummary} link={true} linkLabel="Download specification" />
            }
          </Card>  
        </Col>
      </Row>
      <Row width="100%" gutter={10} style={{paddingBottom:'10px'}}>        
        <Col span={8}>
          <Card title="Contact information" style={{width:'100%'}}>
            <InformationItem label="Email to notify" value={request.emailToSend} />
            <InformationItem label="Email sending status" value={request.emailStatus} useValueAsStyle={true} />
            {request.emailFailedReason &&
              <InformationItem label="Failing caused by" value={request.emailFailedReason} />
            }
            {request.contactData &&
              <InformationItem label="Contact form content" value={request.contactData} link={true} />
            }
            {request.contactData &&
              <InformationItem label="Form sending status" value={request.contactStatus} useValueAsStyle={true} />
            }
          </Card>  
        </Col>
      </Row>
    </div>
  )

}

function InformationItem(props) {
  const {
    label,
    value,
    link=false,
    linkLabel='Get file',
    useValueAsStyle=false
  } = props

  if(!value) return null

  return (
    <Row width="100%">
      <Col className="infoLabel">
        {label}
      </Col>
      <Col className={'infoValue'+ (useValueAsStyle ?' '+value :'')}>
        { link
          ? <a href={value} target="_new">{linkLabel}</a>
          :value
        }
      </Col>
    </Row>
  )
}

export default CadRequests