import React, { useState } from 'react'
import { Card } from 'antd'
import CheckboxGroup from '../../../components/CheckboxGroup'
import { useComponentSelectionError } from './tab-component-utils'

const Landing = ({ initRestrictions = {}, product, setRestrictions, disableSaving, managingGlobal }) => {
  const [landingColorRestrictions, setLandingColorRestrictions] = useState(initRestrictions.landingColor)

  const { handleError } = useComponentSelectionError(disableSaving)
  // Set restrictions for this element.
  const handleChange = (checkedValues, name) => {
    const restrictions = { ...landingColorRestrictions }
    restrictions[name] = checkedValues
    setLandingColorRestrictions(restrictions)
    setRestrictions(restrictions)
  }

  return (
    <Card className="Landing component-card">
      <CheckboxGroup
        title="Landing color"
        mainTitle
        className="Landing"
        items={product.componentsData.landing.landingColor.map((x => ({
          ...x,
          name: x.id.toLowerCase().split('_').join(' ')
        })))}
        sap={false}
        requireSelection
        setError={handleError}
        name="landingColor"
        handleChange={handleChange}
        defaultValue={landingColorRestrictions}
        managingGlobal={managingGlobal}
      />
    </Card>
  )
}

export default Landing