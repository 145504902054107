const createUserService = (api) => {
  return {
     getAll() {
      return api.get(`/user`)
    },

    getOwnedFrontlines() {
      return api.get(`/user/frontlines`)
    },

    /**
     * 
     * @param {Object} user 
     * @param {string} user.email
     * @param {string=} user.role - manager or admin
     * @param {string[]=} user.frontlinesToAdd 
     */
    create(user) {
      return api.post(`/user`, user)
    },

    addFrontline(user, frontline) {
      return api.post(`/user/${user.email}/add_frontline/${frontline.hiddenId}`)
    },

    removeFrontline(user, frontline) {
      return api.post(`/user/${user.id}/${user.email}/remove_frontline/${frontline.hiddenId}`)
    },

    setFrontlines(user, frontlineCountries) {
      return api.post(`/user/${user.id}/${user.email}/set_frontlines`, { frontlineCountries })
    },

    setRole(user, role) {
      return api.post(`/user/${user.id}/${user.email}/set_role`, { role })
    },

    delete(user) {
      return api.delete(`/user/${user.id}/${user.email}`)
    },

    setFeedbackSubscribtion(user, frontlineId, newState) {
      return api.post(`/user/${user.id}/${user.email}/feedback/${frontlineId}`, { subscribed: newState })
    },
  }
}

export default createUserService;
