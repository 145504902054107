import React, { useState, useMemo } from 'react'
import { Input, Button } from 'antd'
import { EditTwoTone } from '@ant-design/icons'

import './EditableInput.scss'

/**
 * 
 * @param {Object} props 
 * @param {string} props.label
 * @param {Function} props.onOKClick
 * @param {Function=} props.validator
 * @param {string=} props.okText
 * @param {string=} props.cancelText
 * @param {string=} props.emptyText
 * @param {string=} props.text
 * @param {string=} props.className
 * @param {boolean=} props.valueRequired
 * @param {boolean=} props.okDisabled
 * @param {boolean=} props.textArea
 */
function EditableInput(props) {
  const { 
    label, 
    onOKClick,
    validator,
    valueRequired, 
    okText = 'OK', 
    cancelText = 'Cancel',
    emptyText = '',
    okDisabled,
    text = '',
    className = '',
    textArea,
  } = props
  
  const [ editing, setEditing ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ value, setValue ] = useState(text)
  const [ valid, setValid ] = useState(validator ? validator(text) : true)

  const disabled = useMemo(() => {
    if (!valid || okDisabled) return true

    if (valueRequired) {
      if (!value || value.trim().length === 0) return true
    }
    return value.trim() === text.trim()
  }, [valid, okDisabled, text, value])

  async function handleClick(e) {
    if (disabled) return
    try {
      if (onOKClick) {
        setLoading(true)
        await onOKClick(value)
      }

      setEditing(false)
    } catch (err) {
        
    } finally {
      setLoading(false)
    }
    
  }

  function onCancelClick() {
    setValue(text)
    setEditing(false)
  }

  const empty = !editing && !text
  const valueToUse = editing || value ? value : emptyText

  const inputProps = {
    value: valueToUse,
    disabled: !editing,
    onChange: e => {
      const val = e.target.value
      const isValid = validator ? validator(val) : true
      setValid(isValid)
      setValue(e.target.value)
    }
  }

  return (
    <div className={`EditableInput ${className} ${valid ? '' : 'EditableInput--invalid'}`}>
      <label className="EditableInput__label">
        <span className="label-text">{label}:</span>
        <div className="EditableInput__container">
          <div className="EditableInput__editor"> 
          { textArea ? 
            <Input.TextArea {...inputProps} className={`EditableInput__input input-text-area ${empty ? 'EditableInput--empty' : ''}`} autoSize /> :
            <Input {...inputProps} onPressEnter={handleClick} className={`EditableInput__input ${empty ? 'EditableInput--empty' : ''}`} />
          }
        { editing ?
            <div className="buttons-container">
              <Button onClick={onCancelClick}>{cancelText}</Button>
              <Button loading={loading} disabled={disabled} onClick={handleClick} type="primary">{okText}</Button>
            </div> :
            <EditTwoTone
              className="EditableInput__icon" 
              onClick={() => setEditing(true)}
            /> 
            }
          </div>
        </div>
      </label>
    </div>
  )
}

export default EditableInput