import React from 'react'
import { message } from 'antd'

import './VersionManager.scss'
import { createErrorMessage } from '../../utils/error-utils'
import EditableInput from '../EditableInput/EditableInput'

function VersionManager(props) {
  const { globalFrontline, saveVersionState } = props 

  return (
    <div className="VersionManager">
      <VersionInput 
        versions={globalFrontline.appVersions} 
        label="Mobile App"
        name="mobile"
        saveVersionState={saveVersionState}
      />
      <VersionInput 
        versions={globalFrontline.appVersions} 
        label="Car Designer 3D"
        name="carDesigner"
        saveVersionState={saveVersionState}
      />
      <VersionInput 
        versions={globalFrontline.appVersions} 
        label="Admin Tool"
        name="adminTool"
        saveVersionState={saveVersionState}
      />
    </div>
  )
}

function VersionInput(props) {
  const { name, label, versions, saveVersionState } = props

  const version = (versions && versions[name] && versions[name].savedVersion) || ''

  async function handleSave(value) {
    try {
      await saveVersionState({name, version: value })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: `Saving the version for ${label}`,
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
      throw err
    }
  }
  
  return (
    <EditableInput
      className="VersionInput"
      label={label}
      onOKClick={handleSave}
      okText="Save"
      valueRequired={true}
      text={version}
      emptyText="No version set"
    />
  )
}

export default VersionManager