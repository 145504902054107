import React, { useState, useContext, useEffect, useRef } from 'react'
import { Button, Dropdown, Menu, message, Modal } from 'antd'
import dateFns from 'date-fns'

import { FrontlinesContext } from '../../store/frontlines'
import { TranslationsContext } from '../../store/translations'
import { ConfigContext } from '../../store/config'
import { createErrorMessage } from '../../utils/error-utils'
import ProductList from './ProductList'

const SELECTED_RELEASE_GROUPS = 'SELECTED_RELEASE_GROUPS'

function ProductGroup(props) {
  const { group, managingGlobal, admin, frontline } = props

  const [ groupName, products ] = group
  const containerRef = useRef()

  const { 
    globalFrontline, 
    saveFullReplacementStatus,
  } = useContext(FrontlinesContext)

  const [ stickyHeader, setStickyHeader ] = useState(false)

  const [ selectedRelease, setSelectedRelease ] = useState(() => {
    const found = globalFrontline.releases[groupName].find(x => x.enabled)

    if (!found) {
      return
    }

    const defaultSelection = found.id

    try {
      const item = localStorage.getItem(SELECTED_RELEASE_GROUPS)
      
      if (!item) return defaultSelection
      
      const savedSelections = JSON.parse(item)
      
      const releaseId = savedSelections[groupName]

      const release = globalFrontline.releases[groupName].find(x => x.id === releaseId)

      if (!release || !(managingGlobal || release.enabled)) return defaultSelection
      
      return releaseId || defaultSelection
    } catch (err) {
      return defaultSelection
    }
  })

  const { previewURL } = useContext(ConfigContext)

  const { getText } = useContext(TranslationsContext)

  function onScroll() {
    const rect = containerRef.current.getBoundingClientRect()
    const y = window.scrollY
    const stick = y > 280 && rect.bottom > 310
    setStickyHeader(stick)
  }

  useEffect(() => {
    if (!containerRef.current) return

    if (products.length <= 2) return

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [containerRef, products])

  async function toggleFullReplacementStatus(product) {
    const newState = !product.fullReplacementStatus

    try {
      await saveFullReplacementStatus(frontline, product, newState)
      message.success(`Status changed succesfully`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing the state',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  function handleReleaseSelection(id) {
    const item = localStorage.getItem(SELECTED_RELEASE_GROUPS) || '{}'

    const itemToSave = JSON.parse(item)

    itemToSave[groupName] = id
    
    localStorage.setItem(SELECTED_RELEASE_GROUPS, JSON.stringify(itemToSave))

    setSelectedRelease(id)
  }

  function menu(releases, showAll) {

    if (!releases) {
      // console.log()
      return null
    }
  
    return (
      <Menu>
        { releases.filter(release => showAll || release.enabled).map(release => {
          return (
            <Menu.Item onClick={() => handleReleaseSelection(release.id)} key={release.id}>{release.id}</Menu.Item>
          )
        })}
      </Menu>
    )
  }

  return (
    <div key={groupName} ref={containerRef} className={`list-container block`}>
      <h2 className={`${stickyHeader ? 'sticky' : ''}`}>{groupName}
        <Dropdown overlay={menu(globalFrontline.releases[groupName], managingGlobal)} trigger={['hover']}>
          <Button type="primary" size="large" className="dropdown-button" style={{marginLeft: '1rem'}}>{ selectedRelease }</Button>
        </Dropdown>
      </h2>
      <ProductList 
        products={products.filter(x => x.productReleases && x.productReleases.find(y => y.id === selectedRelease))} 
        frontline={frontline}
        releaseId={selectedRelease}
        getText={getText}
        globalFrontline={globalFrontline}
        admin={admin}
        managingGlobal={managingGlobal}
        previewURL={previewURL}
        toggleFullReplacementStatus={toggleFullReplacementStatus}
      />
    </div>
  )
}

export default ProductGroup