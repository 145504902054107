import { useContext, useMemo } from 'react'
import { APIContext } from '../store/api'
import createFrontlineService from './frontlines-api'
import createPackageService from './packages-api'
import createProductService from './products-api'
import createTranslationService from './translations-api'
import createUserService from './users-api'
import createThemeService from './themes-api'
import createLogService from './log-api'

const services = {
  frontline: createFrontlineService,
  package: createPackageService,
  product: createProductService,
  translation: createTranslationService,
  user: createUserService,
  theme: createThemeService,
  log: createLogService,
}

const useService = (serviceName) => {
  const api = useContext(APIContext)

  if (!services[serviceName]) {
    throw Error(`A service with a name ${serviceName} does not exist. Available services: ${JSON.stringify(services)}`)
  }

  return useMemo(() => services[serviceName](api), [])
}

export default useService