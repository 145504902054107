import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { List, Card, Breadcrumb, Empty, message, Spin } from 'antd'
import { FrontlinesContext } from '../../store/frontlines'
import NotFoundPage from '../NotFoundPage'
import AsyncButton from '../../components/AsyncButton'
import ProductSelection from '../../components/ProductSelection'
import useService from '../../services/useService'
import './ProductsPage.scss'
import { createErrorMessage } from '../../utils/error-utils'
import { TranslationsContext } from '../../store/translations'
import { ConfigContext } from '../../store/config'
import { AuthContext } from '../../store/auth'
import { groupProducts } from '../../utils/product-utils'
import { UIContext } from '../../store/ui'

const ProductsPage = (props) => {
  const [ loading, setLoading ] = useState(true)
  const [ notFound, setNotFound ] = useState()
  const { adminRights } = useContext(AuthContext)

  const { getText } = useContext(TranslationsContext)
  const uiCtx = useContext(UIContext)
  const { 
    frontline,
    loadFrontline, 
    addProduct, 
    allProducts,
  } = useContext(FrontlinesContext)

  const { country } = props.match.params

  const isMarine = country.toLowerCase() === 'marine'

  const groups = useMemo(() => {
    return groupProducts(allProducts
        .filter(x => {
          if (isMarine) {
            return x.offeringLocation === 'Marine'
          } else {
            return x.offeringLocation !== 'Marine'
          }
        }))
  }, [allProducts])

  async function handleAddProduct(productId, groupName) {
    try {
      await addProduct(frontline, productId)
      uiCtx.setSelectedProductGroup(groupName)
      message.success(`Product added`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedItem: 'frontline',
        attemptedOperation: 'Adding a product to a frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  useEffect(() => {
    loadFrontline({ country }).then(() => {
      setLoading(false)
    }, err => {
      console.error(err)
      setNotFound(true)
    })

  }, [])

  if (notFound) {
    return <NotFoundPage countryName={country}></NotFoundPage>
  }

  if (loading || !allProducts) {
    return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>
  }

  return (
    <div className="ProductsPage page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
        <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Products
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">{frontline.country.name} - Products</h1>
      <div className="block">
        <ProductSelection 
          frontline={frontline}
          admin={adminRights}
          allProducts={allProducts}
        />
      </div>
      <div className="block">
        <h2 className="block__title">Add products</h2>
        <div className="lists-container">
          {
            groups ? groups.map(group => {
              const [name, products] = group
              return (
                <ProductList key={name} getText={getText} name={name} onAdd={(id) => handleAddProduct(id, name)} products={products.filter(x => {
                  const existingProducts = frontline.products.map(x => x.id)
                  return !existingProducts.includes(x.id)
                })} />
              )
            }) : <Spin />
          }
          
        </div>
      </div>
    </div>
  )
}

const ProductList = (props) => {
  const { name, products, onAdd, getText } = props

  return (
    <Card title={name} className="ProductList">
      <List
        size="large"
        dataSource={products}
        locale={{
          emptyText: <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No products left"
           />
        }}
        renderItem={item => <ProductListItem getText={getText} onAdd={onAdd} product={item} />}
      />
    </Card>
  )
}

const ProductListItem = (props) => {
  const { product, onAdd, getText } = props

  return (
    <List.Item
      className="ProductListItem"
      extra={<AsyncButton onConfirm={() => onAdd(product.id)} confirmation={false} type="primary">Add</AsyncButton>}
    >
      {getText(product.name)}
    </List.Item>
  )
}

export default ProductsPage