/**
 * Gets a filtered list of items that match a specific search string.
 * @param {string} wordToMatch 
 * @param {string[] | Object[]} items array to search matches from
 * @param {string[]=} properties the properties of items to check if items are objects instead of strings
 */
export function findMatches(wordToMatch, items, properties) {
  // g means global (whole word), i means insensitive to case
  const regex = new RegExp(wordToMatch, 'gi')

  return items.filter(item => {
    if (!properties) {
      return item.match(regex) 
    }

    for (const prop of properties) {
      const found = item[prop].match(regex)

      if (found) return true
    }
  })
}

/**
 * Categorizes an array of objects by a property.
 * @param {Object[]} items items to categorize
 * @param {string} categorizeBy - property of the item from which the categories are created
 * @param {string=} defaultCategory - If an item has no category, it will be categorized under default category if one has been specified. If not spesified, the item will be filtered out.
 * @returns {Array[]} Array of categories where first element is the category and the second element is the items belonging to that category
 */
export function categorizeItemsBy(items = [], categorizeBy, defaultCategory) {
  if (!categorizeBy) {
    return [[null, items]]
  }
  
  const result = {}

  items
    .forEach(item => {
    const category = item[categorizeBy] || defaultCategory

    // Filter out items that do not have any category
    if (!category) return

    if (!result[category]) {
      result[category] = []
    }

    result[category].push(item)
  })

  return Object.entries(result)
}