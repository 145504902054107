import { COUNTRY, defaultDatasetStyles, TIMESTAMP } from "./api-dashboard-constants"

// Utility Date instanse for performance optimized date handling
const date = new Date()
const hourFormatter = new Intl.DateTimeFormat('fi-FI', { hour: 'numeric' })
const dayFormatter = new Intl.DateTimeFormat('fi-FI', { day: 'numeric', month: 'numeric', year: 'numeric' })
const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' })

export const DAY = 24 * 60 * 60 * 1000
export const WEEK = 7 * DAY
export const MONTH = 4 * WEEK
export const YEAR = 365 * DAY

/**
 * Create unified data format
 * 
 * destinationCountry --> country
 */
export function formatData(rawData, options) {
  const { countryMapping } = options
  return rawData.map(({ country, destinationCountry, ...rest }) => {
    const rawCountry = country || destinationCountry || "invalid"
    return {
      ...rest,
      [COUNTRY]: countryMapping && countryMapping[rawCountry] || rawCountry
    }
  })
}

export function filterData(data, filters, customFilter = undefined) {

  const { range, country } = filters

  let start = 0
  let end = 0

  if (range) {
    start = range[0].getTime()
    end = range[1].getTime()
  }

  const filter = item => {
    if (range && item.hasOwnProperty(TIMESTAMP)) {
      const time = Date.parse(item[TIMESTAMP])
      if (time < start || time > end - 1) {
        return false
      }
    }
    if (country && item.hasOwnProperty(COUNTRY) && item[COUNTRY] !== country) {
      return false
    }
    if (customFilter && !customFilter(item)) {
      return false
    }

    return true
  }

  return data.filter(filter)
}

export function stringToRGB(str = '') {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.7)`;
}

export function getLabels(data, groupKey, range = null) {

  const predefinedItems = {}

  if (range && ["hour", "day", "month"].includes(groupKey)) {
    
    const start = new Date(range[0].getTime())
    const endTime = range[1].getTime()

    while (start.getTime() < endTime) {
      switch (groupKey) {
        case "hour":
          predefinedItems[getHourLabel(start)] = true
          start.setHours(start.getHours() + 1)
          break;

        case "day":
          predefinedItems[dayFormatter.format(start)] = true
          start.setDate(start.getDate() + 1)
          break;

        // month
        default:
          predefinedItems[monthFormatter.format(start)] = true
          start.setMonth(start.getMonth() + 1)
          break;
      }
    }
  }

  const uniqueItems = data.reduce((prev, curr) => {
    const key = getGroupName(curr, groupKey)
    prev[key] = true
    return prev
  }, predefinedItems)

  return Object.keys(uniqueItems).map(key => key)
}

export function getHourLabel(date) {
  const hour = hourFormatter.format(date)
  return `${hour}:00 - ${Number(hour) + 1}:00`
}

export function getTime(item, interval = "month") {
  const time = Date.parse(item[TIMESTAMP])
  date.setTime(time)

  switch (interval) {
    case "hour":
      return getHourLabel(date)

    case "day":
      return dayFormatter.format(date)

    case "month":
      return monthFormatter.format(date)

    default:
      break;
  }

  return "invalid"
}

export function getGroupName(item, key) {
  switch (key) {
    case "country":
      return item[COUNTRY].toUpperCase()

    case "hour":
      return getTime(item, "hour")

    case "day":
      return getTime(item, "day")

    case "month":
      return getTime(item, "month")

    default:
      return item[COUNTRY].toUpperCase()
  }
}

export function getDataset(rawData, groups, groupKey, label, filter) {

  const uniqueItems = groups.reduce((prev, curr) => {
    prev[curr] = 0
    return prev
  }, {})

  const countByGroup = rawData.reduce((prev, curr) => {
    const key = getGroupName(curr, groupKey)
    if (prev.hasOwnProperty(key)) {
      if (!filter || filter(curr)) {
        prev[key]++;
      }
    }
    return prev
  }, uniqueItems)

  const data = Object.keys(countByGroup).map(key => countByGroup[key])

  const customStyles = filter ? {
    backgroundColor: stringToRGB(label),
    hoverBackgroundColor: stringToRGB(label),
  } : {}

  return {
    ...defaultDatasetStyles,
    ...customStyles,
    label,
    data
  }
}

export function getDatasets(rawData, groups, groupKey, datasetKey) {

  if (!datasetKey) {
    return [
      getDataset(rawData, groups, groupKey, "Total", null)
    ]
  }

  const uniqueItems = rawData.reduce((prev, curr) => {
    if (curr.hasOwnProperty(datasetKey)) {
      prev[curr[datasetKey]] = true
    }
    return prev
  }, {})

  const datasetNames = Object.keys(uniqueItems).map(key => key)

  return datasetNames.map(name => getDataset(rawData, groups, groupKey, name, (item) => {
    return item.hasOwnProperty(datasetKey) && item[datasetKey] === name
  }))

}

export function createGraphData(data, groupKey, datasetKey = null, range = null) {

  const labels = getLabels(data, groupKey, range)
  const datasets = getDatasets(data, labels, groupKey, datasetKey)

  return {
    labels,
    datasets,
  }
}

export function getGroupKey(filters) {

  const { viewType, pickerType, time, range } = filters

  let groupKey = "country"

  if (viewType === "time" && !time && !range) {
    groupKey = "month"
  } else if (viewType === "time") {
    switch (pickerType) {
      case "date":
      case "day":
        groupKey = "hour"
        break;
      case "week":
      case "month":
      case "range":
        groupKey = "day"
        break;
      case "year":
        groupKey = "month"
        break;
    }
  }

  return groupKey
}

export function getRange(date, period) {

  const start = new Date(0)
  const end = new Date(0)

  switch (period) {
    case "date":
    case "day":
      start.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
      end.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      break;
    case "week":
      start.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
      end.setTime(start.getTime() + WEEK)
      break;
    case "month":
      start.setFullYear(date.getFullYear(), date.getMonth())
      end.setFullYear(date.getFullYear(), date.getMonth() + 1)
      break;
    case "year":
      start.setFullYear(date.getFullYear())
      end.setFullYear(date.getFullYear() + 1)
      break;
  }

  return [start, end]
}

export function getRangeByPeriod(period) {
  switch (period) {
    case "date":
    case "day":
      return [new Date(Date.now() - DAY), new Date()]
    case "week":
      return [new Date(Date.now() - WEEK), new Date()]
    case "month":
      return [new Date(Date.now() - MONTH), new Date()]
    case "year":
      return [new Date(Date.now() - YEAR), new Date()]
  }
  return [new Date(Date.now() - MONTH), new Date()]
}

export function getUniqueValues(data, key) {

  if (!data) {
    return []
  }

  const uniqueItems = data.reduce((prev, curr) => {
    if (curr.hasOwnProperty(key)) {
      prev[curr[key]] = true
    }
    return prev
  }, {})

  return Object.keys(uniqueItems).map(key => key)
}

export function getRangeFromData(data) {
  if (!data || !data.length) {
    return null
  }

  let min, max

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.hasOwnProperty(TIMESTAMP)) {
      const time = Date.parse(item[TIMESTAMP])
      min = !min ? time : Math.min(min, time)
      max = !max ? time : Math.max(min, time)
    }
  }

  if (!min || !max || min === max) {
    return null
  }

  return [new Date(min), new Date(max)]
}