import React from 'react';
import { UsersProvider } from './users';
import { FrontlinesProvider } from './frontlines';
import { TranslationsProvider } from './translations';
import { ConfigProvider } from './config';
import { AuthProvider } from './auth';
import { APIProvider } from './api';
import { UIProvider } from './ui';

/**
 * Data provider (React Context API)
 * @function Provider Main dataprovider
 * @param {Object} props
 */
export const Provider = ({ children }) => (
  <React.Fragment>
  <ConfigProvider>
    <AuthProvider>
      <APIProvider>
            <FrontlinesProvider>
              <UsersProvider>
                <TranslationsProvider>
                  <UIProvider>
                    {children}
                  </UIProvider>
                </TranslationsProvider>
              </UsersProvider>
            </FrontlinesProvider>
      </APIProvider>
    </AuthProvider>
  </ConfigProvider>
  </React.Fragment>
)