import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Card, Tabs } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import { useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'
import { categorizeItemsBy } from '../../../../utils/general-utils'

const { TabPane } = Tabs

const Ceiling = ({ initRestrictions = {}, setRestrictions, disableSaving, product, managingGlobal, saveDisabled, title }) => {
  const [ceilingRestrictions, setCeilingRestrictions] = useState(initRestrictions)

  const { handleError } = useComponentSelectionError(disableSaving)
  const [ errorMessage, setErrorMessage ] = useState('')

  const { getText } = useContext(TranslationsContext)

  const categorizedCeilings = useMemo(() => {
    const filtered = product.componentsData.ceilings.filter(x => managingGlobal || !x.disabled)
    return categorizeItemsBy(filtered, 'componentCategory')
  }, [product.componentsData.ceilings, managingGlobal])

  useEffect(() => {
    setRestrictions(ceilingRestrictions)
  }, [ceilingRestrictions])

  const handleChange = (checkedValues, name) => {
    const restrictions = { ...ceilingRestrictions }

    if (!restrictions[name]) restrictions[name] = {}
    
    restrictions[name].finishes = checkedValues
    setCeilingRestrictions(restrictions)
  }

  const setError = message => {
    handleError("ceiling", message)
    setErrorMessage(message)
  }

  const handleCheckAllChange = (name, checked) => {
    const restrictions = { ...ceilingRestrictions }

    if (!restrictions[name]) {
      restrictions[name] = {}
    }
    
    restrictions[name].disabled = checked
    
    setCeilingRestrictions(restrictions)

    const allSelected = Object.values(restrictions).filter(v => v.disabled).length ===
      product.componentsData.ceilings.length

    if (allSelected) {
      setError("You need to select at least one available ceiling configuration.")
    } else {
      setError('')
    }
  }


  return (
    <Card className="Ceiling component-card">
      <h2 className="component-card__title">{title}</h2>
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      <Tabs className="component-card__main-tab" animated={false}>
        { categorizedCeilings.map(([ category, ceilings]) => {
          return (
            <TabPane className="tab-pane" tab={getText(`component-category-${category}`)} key={category}>
              {
                ceilings.map(ceiling => {
                  return (
                    <CheckboxGroup
                      key={ceiling.id}
                      title={ceiling.name}
                      name={ceiling.id}
                      handleChange={handleChange}
                      defaultValue={initRestrictions[ceiling.id]}
                      handleCheckAllChange={handleCheckAllChange}
                      items={ceiling.finishes}
                      requireSelection
                      setError={handleError}
                      errorMsg="You need to select at least one finish for an activated ceiling."
                      checkAll
                      subtitle="Panels & Mirrors & Lights"
                      managingGlobal={managingGlobal}
                    />
                  )
                })
              }
            </TabPane>
          )
        })}
      </Tabs>
    </Card>
  )
}

export default Ceiling