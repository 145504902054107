import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../store/auth'
import { APIContext } from '../store/api'

const APIDocs = (props) => {

  const { loggedInUser } = useContext(AuthContext)
  const api = useContext(APIContext)
  const [ html, setHtml ] = useState(null)

  useEffect(() => {
    if (!loggedInUser) return
    
  }, [loggedInUser])


  return null

}

export default APIDocs