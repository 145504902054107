import React, { useEffect, useState } from 'react'
import { Col, message, Row } from 'antd'
import { Bar } from 'react-chartjs-2'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import useService from '../../../../services/useService'
import APIDashboardFilters from '../../APIDashboardFilters'
import { createGraphData, filterData, formatData, getGroupKey, getRangeFromData, getUniqueValues } from '../../api-dashboard-utils'
import { COUNTRY, defaultGraphOptions, PRODUCT, TIMESTAMP } from '../../api-dashboard-constants'
import countryMapping from '../../../../constants/ktocCountryMapping.json'
import ExportDataButton from '../../../../components/ExportDataButton'
import './Salesforce.scss'

export const excelHeaders = [
  { key: "leadSource", label: 'Lead Source' },
  { key: COUNTRY, label: 'Country' },
  { key: TIMESTAMP, label: 'Timestamp' },
]

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 * @param {string=} props.title 
 * @param {function=} props.filter 
 */
function Salesforce(props) {
  const {
    className = '',
    title = '',
    filter,
  } = props

  const logService = useService('log')
  const [loading, setLoading] = useState(true)
  const [rawData, setRawData] = useState([])
  const [filters, setFilters] = useState({})

  useEffect(() => {

    if (!logService) return

    const fetchData = async () => {
      const cdLeads = await logService.getCdLeads()
      const plannerLeads = await logService.getPlannerLeads()
      const rickLeads = await logService.getRickLeads()

      cdLeads.forEach(i => { i.leadSource = "CD" })
      plannerLeads.forEach(i => { i.leadSource = "Planner" })
      rickLeads.forEach(i => { i.leadSource = "RICK" })

      // console.log({ cdLeads, plannerLeads, rickLeads })

      return cdLeads.concat(plannerLeads, rickLeads)
    }

    fetchData().then(result => {
      setRawData(result)
    }).catch(err => {
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })

  }, [logService])

  const datasetKey = filters.stacked
    ? "leadSource"
    : null

  const formattedData = formatData(rawData, { countryMapping })
  const filteredData = filterData(formattedData, filters, filter)
  const countries = getUniqueValues(formattedData, COUNTRY)
  const range = filters.range || getRangeFromData(filteredData)
  const graphData = createGraphData(filteredData, getGroupKey(filters), datasetKey, range)

  return (
    <div data-testid="Salesforce" className={`Salesforce ${className}`}>
      <h2>{title}</h2>
      <LoadingSpinner loading={loading}>
        <Row gutter={16}>
          <Col span={19}>
            <Bar data={graphData} options={defaultGraphOptions} />
          </Col>
          <Col span={5}>
            <APIDashboardFilters onChange={setFilters} countries={countries} />
          </Col>
        </Row>
        <ExportDataButton
          filename="Salesforce_log_export"
          sheetName="Data"
          data={filteredData}
          headers={excelHeaders}
        />
      </LoadingSpinner>
    </div>
  )
}

export default Salesforce