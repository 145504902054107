import React, { useEffect, useContext } from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import Users from '../pages/Users'
import LanguagesPage from '../pages/LanguagesPage'
import FailurePage from '../pages/FailurePage'
import ProductsPage from '../pages/ProductsPage'
import DashboardPage from '../pages/DashboardPage'
import ReleasesPage from '../pages/ReleasesPage'
import ManageFrontlinePage from '../pages/ManageFrontlinePage'
import GlobalFrontlinePage from '../pages/GlobalFrontlinePage'
import ManageOfferingPage from '../pages/ManageOfferingPage'
import ManageProductPage from '../pages/ManageProductPage'
import ManagePredesignsPage from '../pages/ManagePredesignsPage'
import ManageLocalThemesPage from '../pages/ManageLocalThemesPage'
import ManageLocalDesignsPage from '../pages/ManageLocalDesignsPage'
import UpdateAllPage from '../pages/UpdateAllPage'
import { AuthContext } from '../store/auth'
import APIDocs from '../pages/APIDocs'
import APIDashboardPage from '../pages/APIDashboardPage'
import ImageGenDashboardPage from '../pages/ImageGenDashboardPage/ImageGenDashboardPage'
import RickDashboardPage from '../pages/RickDashboardPage/RickDashboardPage'
import ErrorPage from '../pages/ErrorPage/ErrorPage'

const AdminOnlyRoute = ({ component: Component, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      role === "admin"
        ? <Component {...props} />
        : <Redirect to='/' />
    )}} />
)

const RickUsersRoute = ({ component: Component, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      (role === "admin" || role === "rick")
        ? <Component {...props} />
        : <Redirect to='/' />
    )}} />
)

const FrontPageSelectionRoute = ({ component: Component, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      (role === "rick")
        ? <Redirect to='/rick' />
        : <Component {...props} />
    )}} />
)

const OwnedFrontlineRoute = ({ component: Component, loggedInUser, ...rest }) => (
  <Route {...rest} render={(props) => {
    const { country } = props.match.params
    const hasAccess = loggedInUser.role === "admin" || loggedInUser.frontlineCountries.map(x => x.toLowerCase()).includes(country.toLowerCase())
    return (
      hasAccess
        ? <Component {...props} />
        : <Redirect to='/' />
    )
  }} />
)


const Routes = () => {
  const { loggedInUser } = useContext(AuthContext)
  return (
    <Switch>
      <FrontPageSelectionRoute role={loggedInUser.role} path="/" component={DashboardPage} exact />
      {/* <Route path="/" component={DashboardPage} exact /> */}
      <AdminOnlyRoute role={loggedInUser.role} path="/users" component={Users} exact />
      <AdminOnlyRoute role={loggedInUser.role} path="/languages" component={LanguagesPage} exact />
      <AdminOnlyRoute role={loggedInUser.role} path="/releases" component={ReleasesPage} exact />
      <RickUsersRoute role={loggedInUser.role} path="/rick" component={RickDashboardPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/products" component={ProductsPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/product/:productId/:releaseId/predesigns" component={ManagePredesignsPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/product/:productId/:releaseId/local-themes" component={ManageLocalThemesPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/product/:productId/:releaseId/local-theme/:themeId/designs" component={ManageLocalDesignsPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/product/:productId/:releaseId/offering" component={ManageOfferingPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country/product/:productId/:releaseId/manage" component={ManageProductPage} exact />
      <AdminOnlyRoute role={loggedInUser.role} path="/frontline/global" component={GlobalFrontlinePage} exact />
      <AdminOnlyRoute role={loggedInUser.role} path="/frontline/global/update_all" component={UpdateAllPage} exact />
      <Route path={["/logs", "/logs/dashboard"]} component={APIDashboardPage} exact />
      <Route path="/logs/image-gen" component={ImageGenDashboardPage} exact />
      <OwnedFrontlineRoute loggedInUser={loggedInUser} path="/frontline/:country" component={ManageFrontlinePage} exact />
      <Route path="/login/failure" component={FailurePage} exact />
      <Redirect to="/" />
    </Switch>
  )
}

export default Routes