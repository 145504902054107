import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Button, Card, Divider, Dropdown, Menu, message, Spin, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { FrontlinesContext } from '../../store/frontlines'

import './ProductReleases.scss'
import CustomSwitch from '../CustomSwitch'
import { TranslationsContext } from '../../store/translations'
import { createErrorMessage } from '../../utils/error-utils'

function ProductReleases(props) {
  const { managingGlobal } = props

  const { 
    frontline: fl, 
    globalFrontline,
    allProducts, 
  } = useContext(FrontlinesContext)

  const frontline = managingGlobal ? globalFrontline : fl

  const releaseGroups = useMemo(() => {
    if (!allProducts) return
    return Object.entries(globalFrontline.releases).reduce((prev, curr) => {
      const [ groupName, releases ] = curr

      const productsForGroup = allProducts.filter(x => x.offeringLocation === groupName).map(x => x.id)

      const flProduct = frontline.products && frontline.products
        .find(x => !x.productDisabled && productsForGroup.includes(x.id))

      // The frontline does not have any products that belong into this group
      // --> No need to display the group
      if (!flProduct) {
        return prev
      }

      if (!prev[groupName]) {
        prev[groupName] = []
      }

      releases.forEach(release => {
        if (!release.enabled) return // Filter out disabled

        if (managingGlobal) {
          prev[groupName].push(release)
          return
        }

        const inFrontline = frontline.releases && frontline.releases[groupName] && frontline.releases[groupName].find(x => x.id === release.id)

        if (inFrontline) {
          prev[groupName].push(inFrontline)
        } else {
          prev[groupName].push({ id: release.id })
        }
      })

      return prev
    }, {})
  }, [ allProducts, globalFrontline, frontline ])

  if (!releaseGroups) {
    return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>
  }

  const groupEntries = Object.entries(releaseGroups)

  return (
    <div className="ProductReleases">
      {
        groupEntries && groupEntries.length > 0 ? groupEntries.map(entry => {
          const [ groupName, groupReleases ] = entry
          return (
            <ReleaseGroup frontline={frontline} key={groupName} products={allProducts} name={groupName} releases={groupReleases} />
          )
        }) : <p style={{ marginTop: '2rem', opacity: 0.25, color: 'black' }}>No products enabled for this frontline</p>
      }
    </div>
  )
}

function ReleaseGroup(props) {
  const { name, releases, products, frontline } = props 

  const { 
    saveReleaseVisibility,
    saveDefaultRelease, 
  } = useContext(FrontlinesContext)

  const { getText } = useContext(TranslationsContext)

  const [ defaultSelectionLoading, setDefaultSelectionLoading ] = useState(false)

  const defaultRelease = useMemo(() => {
    const found = releases.find(x => x.default)

    if (found) return found.id
    // No default set

    return releases[0].id // first available

    // return releases.slice(-1)[0].id // latest available
  }, [releases])

  const showError = useMemo(() => {
    return releases.filter(x => x.selectableForKonePeople).length === 0
  }, [releases])

  const cannotDisable = useMemo(() => {
    return releases.filter(x => x.selectableForKonePeople).length <= 1
  }, [releases])

  function menu() {

    return (
      <Menu>
        {
          releases.map(release => {
            let className = 'dropdown-item'
            const style = {}

            if (defaultRelease === release.id) {
              className += ' selected'

              style.fontWeight = 700
            }

            return (
              <Menu.Item onClick={() => handleDefaultSelection(release.id)} className={className} style={style} key={release.id}>{release.id}</Menu.Item>
            )
          })
        }
      </Menu>
    )
  }

  async function handleDefaultSelection(releaseId) {
    if (defaultRelease === releaseId) return

    setDefaultSelectionLoading(true)

    try {
      await saveDefaultRelease({ releaseId, hiddenId: frontline.hiddenId, groupName: name, })
      setDefaultSelectionLoading(false)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      console.error(">err", err)

      message.error(errorMessage, 5)
      setDefaultSelectionLoading(false)
    }
  }

  async function handleVisibilityToggle(releaseId, checked) {
    // saveReleaseVisibility,
    // saveDefaultRelease, 
    try {
      await saveReleaseVisibility({ hiddenId: frontline.hiddenId, groupName: name, releaseId,selectableForKonePeople: checked  })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      console.error(">err", err)

      message.error(errorMessage, 5)
    }
  }

  function Info(props) {
    if (!props.products) return null

    const titleText = props.products
      .filter(x => x.offeringLocation === name)
      .map(x => <p key={x.id}>{getText(`product-${x.id}`)}</p>)

    return (
      <Tooltip placement="bottom" title={titleText}>
        <InfoCircleOutlined className="info-icon" />
      </Tooltip>
    )
  }

  return (
    <Card className="ReleaseGroup" title={name} extra={<Info products={products} />}>
      <h3 className="group-title">Visible releases for logged-in users</h3>
      <div className="releases-container">
        { releases.map(release => {
          // const isDefault = release.id === defaultRelease
          return (
            <ReleaseSwitch key={release.id} cannotDisable={cannotDisable} release={release} onChange={handleVisibilityToggle} />
          )
        })}
      </div>
      <p className="group-error-message">{ showError ? `No releases active. Only default selection (${defaultRelease}) will be visible.` : ''}</p>
      <Divider />
      <div className="default-selection">
        <span>Default release for non-KONE users:</span>
        <Dropdown className="default-dropdown" trigger={['hover']} overlay={menu()}>
          <Button loading={defaultSelectionLoading}>{defaultRelease}</Button>
        </Dropdown>
      </div>
    </Card>
  )
}

function ReleaseSwitch(props) {
  const { release, onChange, cannotDisable } = props

  const [ loading, setLoading ] = useState(false)

  async function handleToggle(...args) {
    setLoading(true)
    await onChange(...args)
    setLoading(false)
  }

  return (
    <div key={release.id} className="ReleaseSwitch">
      <span className="ReleaseSwitch-label">{release.id}</span>
      <CustomSwitch
        loading={loading}
        disabled={cannotDisable && release.selectableForKonePeople}
        disabledTooltipText="Cannot disable every release for a group at once"
        onChange={(checked) => handleToggle(release.id, checked )}
        checked={release.selectableForKonePeople} />
    </div>
  )
}

export default ProductReleases