// @ts-check
import React, { useState } from 'react'
import { message, Input, Button } from 'antd'
import validator from 'validator'
import { EditTwoTone } from '@ant-design/icons'

import './URLInput.scss'
import { createErrorMessage } from '../../utils/error-utils'

function URLInput(props) {
  const { defaultValue, onSave, label, className = '' } = props
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  const [value, setValue] = useState(defaultValue.trim())
  const [isValid, setIsValid] = useState(validateUrl(defaultValue))

  function validateUrl(value) {
    return validator.isURL(value.trim(), {
      protocols: [''],
      require_protocol: false,
    })
  }

  const handleSave = async () => {
    if (!isValid) return
    setLoading(true)

    try {
      await onSave(`https://${value.trim()}`)

      setLoading(false)
      setEditing(false)
      message.success('URL changed succesfully.')
    } catch (err) {
      setLoading(false)

      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the new URL',
        attemptedItem: 'frontline',
      })

      message.error(errorMessage, 5)
    }
  }

  const handleChange = (newValue) => {
    setValue(newValue)

    const valid = validateUrl(newValue)
    setIsValid(valid)
  }

  const handleCancel = () => {
    setValue(defaultValue)
    setEditing(false)
  }

  return (
    <div className={`URLInput ${className} ${editing ? 'URLInput--editing' : 'URLInput--empty'}`}>
      <div className={'URLInput__label'}>{label}</div>
      <Input
        className="URLInput__input"
        addonBefore="https://"
        disabled={!editing}
        onPaste={(e) => {
          e.preventDefault()
          const value = e.clipboardData.getData('text')
          if (value.startsWith('https://')) {
            const newValue = value.replace('https://', '')
            handleChange(newValue)
          } else {
            handleChange(value)
          }
        }}
        onPressEnter={handleSave}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={value || 'No URL set'}
        value={value}
      />
      {editing ? (
        <div className="URLInput__buttons-container">
          <Button className="URLInput__button" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
          <Button
            className="URLInput__button"
            disabled={!isValid}
            onClick={handleSave}
            type="primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
      ) : (
        <EditTwoTone className="URLInput__icon" onClick={() => setEditing(true)} />
      )}
    </div>
  )
}

export default URLInput
