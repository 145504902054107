import React, { useContext, useMemo } from 'react'
import { Modal, message, Switch } from 'antd'
import { FrontlinesContext } from '../../store/frontlines'

import CustomSwitch from '../../components/CustomSwitch'

import './ReleasesPage.scss'
import { createErrorMessage } from '../../utils/error-utils'
import ProductReleases from '../../components/ProductReleases'
import { ConfigContext } from '../../store/config'


function ReleasesPage(props) {

  const { globalFrontline, saveReleaseStatus, saveServeOriginalState } = useContext(FrontlinesContext)

  const config = useContext(ConfigContext)

  const renderGroups = useMemo(() => {
    if (!globalFrontline.releases) return

    const releasesArray = Object.entries(globalFrontline.releases)

    return releasesArray.map((entry, i) => {
      const [ groupName, groupReleases ] = entry

      const cannotDisable = groupReleases.filter(release => release.enabled).length <= 1

      return (
        <div key={groupName} className="release-group">
          <h3 className="release-group-title">{ groupName }</h3>
          <div className="releases-container">
            { groupReleases.map(release => {
              return (
                <div key={release.id} className="switch-container">
                  <span className="switch-label">{release.id}</span>
                  <div className="switch-group">
                    <CustomSwitch
                      disabled={cannotDisable && release.enabled}
                      disabledTooltipText="Cannot disable every release for a group at once"
                      onChange={() => handleToggle(groupName, release )}
                      checked={release.enabled} />
                    {
                      (config.mode === 'QA' || config.mode === 'DEV')  &&
                      <Switch
                        className="json-switch"
                        checked={release.serveOriginal}
                        onChange={() => handleJsonSwitch(groupName, release)}
                        checkedChildren={"JSON"}
                        unCheckedChildren={"DB"}
                      />
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }, [globalFrontline])

  async function changeStatus(groupName, release, newState) {
    try {
      await saveReleaseStatus({ groupName, releaseId: release.id, enabled: newState })
      message.success("Status changed succesfully!")
    } catch (err) {
      console.error(err)
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing status',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }
  
  function handleToggle(groupName, release) {
    const newEnabledState = !release.enabled

    Modal.confirm({
      title: `Are you sure you want to ${newEnabledState ? 'activate' : 'disable'} ${release.id} for ${groupName}?`,
      content: `This action will ${newEnabledState ? 'enable' : 'disable'} the release for frontlines in the admin tool.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return changeStatus(groupName, release, newEnabledState)
      },
    })
  }

  async function handleJsonSwitch(groupName, release) {
    try {
      await saveServeOriginalState({ groupName, releaseId: release.id, serveOriginal: !release.serveOriginal })
      message.success("Status changed succesfully!")
    } catch (err) {
      console.error(err)
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing status',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  } 

  if (!renderGroups) {
    return (
      <div className="page">
        No releases exist. 
      </div>
    )
  }

  return (
    <div className="ReleasesPage page">
    <h2 className="block__title">Product releases</h2>
    <div className="block">
      { renderGroups }
    </div>
    {/* <div className="block">
      <ProductReleases managingGlobal={true} />
    </div> */}
    </div>
  )
}

export default ReleasesPage