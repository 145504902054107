import React, { useState, useMemo, useContext, useEffect } from 'react'
import { Button, message, Card, Collapse, Drawer, Table, Tag } from 'antd'
import { WarningTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import useService from '../../../../services/useService'
import './ImageGen.scss'
import { createErrorMessage } from '../../../../utils/error-utils'
import useInterval from '../../../../utils/useInterval'

const { Panel } = Collapse;

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function ImageGen(props) {
  const {
    className = ''
  } = props

  const logService = useService('log')

  return (
    <div data-testid="ImageGen" className={`ImageGen ${className}`}>
      <div className="content-container">
        <ImageGenStatusChecker queueStatusUpdateIntervalSeconds={30} updateIntervalSeconds={150} />
      </div>
    </div>
  )
}

const TABLE_COLUMNS = [
  { 
    title: 'App name',
    dataIndex: 'AppName',
    key: 'appName',
  },
  { 
    title: 'Instance',
    dataIndex: 'Instance',
    key: 'instance',
  },
  // { 
  //   title: 'Priority',
  //   dataIndex: 'QueuePriority',
  //   key: 'priority',
  // },
  { 
    title: 'Status',
    dataIndex: 'Status',
    key: 'status',
    render: status => {
      return (
        <span className={`status-indicator ${status}`} key={status}>
          {status.toUpperCase()}
        </span>
      )
    }
  },
]

function ImageGenStatusChecker(props) {
  const { updateIntervalSeconds, queueStatusUpdateIntervalSeconds } = props

  const logService = useService('log')

  const [ status, setStatus ] = useState()
  const [ loading, setLoading ] = useState(true) 

  useInterval(() => {
    updateStatus()
  }, updateIntervalSeconds * 1000)

  useEffect(() => {
    updateStatus()
      .finally(() => {
        setLoading(false)
      })
  }, [])

  async function updateStatus() {
    try {
      const result = await logService.getImageGenStatus()
      const formatted = result.map(item => {
        if (!item.instances) return item
        
        return {
          ...item,
          instances: item.instances.map(instance => {
            if (!instance.LaunchedInstances || instance.AppName === 'ImageRequestHandler') return instance
            return {
              ...instance,
              Instance: `${instance.InstanceId}/${instance.LaunchedInstances}` 
            }
          })
        }
        
      })
      setStatus(formatted)

    } catch (err) {
      console.error(err)
      message.error('Failed to update VM status')
    }
  }

  return (
    <Card 
      title={"Virtual machines"}
      loading={loading}
      extra={<QueueStatusChecker updateIntervalSeconds={queueStatusUpdateIntervalSeconds} />}
      className="machines-container">
      { status ? 
        status.map((machine,index) => {
          return <MachineStatusTable machine={machine} key={index+'_'+Date.now()} />
        })
        : 'Failed to get data'
      }
    </Card>
  )
}

function MachineStatusTable(props) {
  const { machine, className = '' } = props

  return (
    <Table
      className={`MachineStatusTable ${className}`}
      dataSource={machine.instances}
      pagination={false}
      columns={TABLE_COLUMNS}
      title={() => <span className="machine-name">{machine.id}</span>}
    />
  )
}

function MachineStatus(props) {
  const { machine, className = '' } = props

  let machineStatus = machine.status
  const [ drawerVisible, setDrawerVisible ] = useState(false)

  if (machine.status === 'ONLINE') {
    const offlineInstance = machine.instances.find(x => x.status === 'OFFLINE')
    if (offlineInstance) {
      machineStatus = 'PROBLEM'
    }
  }

  function onOpen() {
    setDrawerVisible(true)
  }

  function onClose() {
    setDrawerVisible(false)
  }

  return (
    <>
      <Card
        extra={
          <Button 
            onClick={onOpen}
            type="link">
            Details
          </Button>}
        className={`MachineStatus MachineStatus-card ${className}`}
        title={<div className="machine-info">
          <span className={`machine-status-indicator status-indicator ${machineStatus}`}>{machineStatus}</span>
          <span className="machine-name">{machine.machineName}</span>
      </div>}
      >
      </Card>
      <Drawer
      className="MachineStatus MachineStatus-drawer"
      title={machine.machineName}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={drawerVisible}>
        <div
          className="instances-container">

          {
            machine.instances.map(instance => {
              return (
                <div key={instance.id+'_'+Date.now()} className="instance">
                  <span className={`instance-status-indicator status-indicator ${instance.status}`}>{instance.status}</span>
                  <span className="instance-name">Instance {instance.id}</span>
                  {/* {instance.queuePriority && <span className={`instance-priority ${instance.queuePriority}`}>{instance.queuePriority}</span>} */}
                </div>
              )
            })
          }
        </div>
      </Drawer>
    </>
  )
}

function QueueStatusChecker(props) {
  const { updateIntervalSeconds } = props
  const [updating, setUpdating] = useState(true)
  const [messagesInQueue, setMessagesInQueue] = useState()
  const [messagesInHighPrioQueue, setMessagesInHighPrioQueue] = useState()
  const [seconds, setSeconds] = useState(0)
  const logService = useService('log')

  useInterval(() => {
    updateProgress()
  }, 1000)

  useEffect(() => {
    updateCount()
  }, [])

  function updateProgress() {
    if (updating) return

    const newProgress = seconds + 1


    if (newProgress > updateIntervalSeconds) {
      updateCount()
    } else {
      setSeconds(newProgress)
    }
  }

  async function updateCount() {
    setUpdating(true)
    try {
      const promises = [
        logService.getQueueStatus(),
        logService.getQueueStatus({ highPriority: true })
      ]

      const [normalResult, highPrioResult] = await Promise.all(promises)

      setMessagesInQueue(normalResult.approximateMessageCount)
      setMessagesInHighPrioQueue(highPrioResult.approximateMessageCount)
    } catch (err) {
      console.error(err)
      message.error('Failed to update message count')
    }

    setUpdating(false)
    setSeconds(0)
  }

  return (
    <div className="QueueStatus">
      <p onClick={updateCount} className="queue-status-title">Queues (<span className="update-seconds">updating in {updateIntervalSeconds - seconds}s</span>)</p>
      <p className="queue-messages">High priority: <span className="message-count">{messagesInHighPrioQueue}</span> messages</p>
      <p className="queue-messages">Normal priority: <span className="message-count">{messagesInQueue}</span> messages</p>
      {/* <Button onClick={updateCount}>Check now</Button>
      <p className="status-checker-text">Updating in <span className="update-seconds">{updateIntervalSeconds - seconds}</span> seconds</p> */}
      {/* <Progress className="progress-bar" trailColor="white" status={'normal'} percent={seconds / updateIntervalSeconds * 100} format={() => {
        // if (!messagesInQueue) return 'Loading'
        return <span className="status-checker-text">{updateIntervalSeconds - seconds} seconds</span>
      }} /> */}
    </div>
  )
}

export default ImageGen