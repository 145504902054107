import React from "react";
import { message } from 'antd'
import Routes from '../Routes'
import Main from '../Main'
import "./App.scss";
import "../../styles/pages.scss";
import "../../styles/global-styles.scss";

const App = (props) => {
  message.config({
    top: 50,
    duration: 5
  })

  return (
    <div className="App">
      <Main>
        <Routes />
      </Main>
    </div>
  );
}

export default App;
