import React from 'react'
import { Button } from 'antd'
import './FailurePage.scss'


const FailurePage = ({logout}) => {
  return (
    <div className="FailurePage">
      <h2>Login failed</h2>
      <p>KONE login was succesful but there was an error logging you to the Content Manager.</p>
      <p>Most likely you don't have a user created in the Content Manager with your email.</p>
      <Button type="primary" onClick={logout}>Try another account</Button>
    </div>
  )
}

export default FailurePage