import React, { useState, useMemo, useContext } from 'react'
import { Spin } from 'antd'

import './LoadingSpinner.scss'

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function LoadingSpinner(props) {
  const {
    className = '',
    loading,
    size = 'large',
    children,
  } = props

  if (loading) return (
    <div data-testid="LoadingSpinner" className={`LoadingSpinner ${className}`}>
      <Spin size={size} />
    </div>
  )

  return children
}

export default LoadingSpinner