import React, { useState } from 'react'
import { Switch, Tooltip, Button } from 'antd'
import { CheckOutlined, CloseOutlined, WarningTwoTone } from '@ant-design/icons'

import './CustomSwitch.scss'

function SwitchComponent(props) {
  return (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      { ...props }
    />
  )
}

function ButtonsComponent(props) {
  const { disabled, offText = 'Off', onText = 'On', checked, onChange, className = '', warning, disableLoading } = props

  const [ loading, setLoading ] = useState(false)

  async function handleChange(val) {
    if (checked === val) return
    if (!disableLoading) { // mainly for sync functions
      setLoading(true)
    }

    await onChange(val)
    setLoading(false)
  }

  return (
    <div className={`${className} CustomSwitch CustomSwitch--buttons ${true ? 'CustomSwitch--loading' : ''}`}>
      <Button 
        type={!checked ? 'primary' : 'default'} 
        loading={loading && checked}
        disabled={disabled || loading}
        onClick={() => handleChange(false)}>{offText}</Button>
      <Button 
        type={checked ? 'primary' : 'default'} 
        loading={loading && !checked}
        disabled={disabled || loading}
        onClick={() => handleChange(true)}>{onText}</Button>
        {warning && <Tooltip
           overlayStyle={{ zIndex: 1000 }} 
           title={ warning }>
          <WarningTwoTone className="warning-icon" twoToneColor="red" />
        </Tooltip>}
    </div>
  )
}

function CustomSwitch(props) {
  const { 
    disabledTooltipText = null,
    tooltipText = null,
    tooltipPlacement = 'top',
    type = 'normal',
    onChange,
    ...rest
  } = props

  let Component

  const [ loading, setLoading ] = useState(false)

  if (type === 'normal') {
    Component = SwitchComponent
  }

  if (type === 'buttons') {
    Component = ButtonsComponent
  }

  async function handleChange(...args) {
    setLoading(true)
    await onChange(...args)
    setLoading(false)
  }


  return (
    <Tooltip
      placement={tooltipPlacement}
      overlayStyle={{ zIndex: 1000 }} 
      title={ rest.disabled ? disabledTooltipText : tooltipText }>
      <Component
        { ...rest }
        onChange={handleChange}
        loading={loading}
      />
    </Tooltip>
  )
}

export default CustomSwitch