import { getDateForName } from "../utils"

const TYPES = {
  COMPONENT: 'component',
  COMPONENT_FAMILY: 'component family',
  DISPLAY: 'display',
  DISPLAY_COLOR: 'display color',
  FINISH: 'finish',
  POSITION: 'position',
  MATERIAL: 'material',
  PANELING: 'paneling',
}

const SHEETS = {
  INFO: {
    key: 'info',
    name: 'Info',
    headers: [
      { key: 'key', label: 'Key' },
      { key: 'value', label: 'Value' }
    ]
  },
  MODEL_AND_LAYOUT: { 
    key: 'model-and-layout',
    name: 'Model and layout',
    headers: getHeaders(),
  },
  CEILING: { 
    key: 'ceiling',
    name: 'Ceilings',
    headers: getHeaders(),
  },
  WALLS: { 
    key: 'walls',
    name: 'Walls',
    headers: getHeaders(),
  },
  FLOOR: { 
    key: 'floor',
    name: 'Floor materials',
    headers: getHeaders(),
  },
  CAR_DOORS: { 
    key: 'car-doors',
    name: 'Doors and Frames',
    headers: getHeaders(),
  },
  SIGNALIZATION: { 
    key: 'signalization',
    name: 'Signalization',
    headers: getHeaders(),
  },
  SIGNALIZATION_POSITIONS: { 
    key: 'signalization-positions',
    name: 'Signalization positioning',
    headers: getHeaders(),
  },
  MIRRORS: { 
    key: 'mirrors',
    name: 'Mirrors',
    headers: getHeaders(),
  },
  HANDRAILS: { 
    key: 'handrails',
    name: 'Handrails',
    headers: getHeaders(),
  },
  SKIRTINGS: { 
    key: 'skirtings',
    name: 'Skirtings',
    headers: getHeaders(),
  },
  BUFFER_RAILS: { 
    key: 'buffer-rails',
    name: 'Buffer rails',
    headers: getHeaders(),
  },
  INFO_MEDIA_SCEENS: { 
    key: 'info-media-screens',
    name: 'Info and media screens',
    headers: getHeaders(),
  },
  SEATS: { 
    key: 'seats',
    name: 'Seats',
    headers: getHeaders(),
  },
  TENANT_DIRECTORIES: { 
    key: 'tenant-directories',
    name: 'Tenant directories',
    headers: getHeaders(),
  },
  LANDING: { 
    key: 'landing',
    name: 'Landing',
    headers: getHeaders(),
  },
}

function getSheets() {
  return Object.values(SHEETS)
}

function getName(country, productId, releaseId) {
  return `cd_${country}_${productId}_${releaseId}_${getDateForName()}`
}

function getHeaders() {
  return [
    { key: 'name', label: 'Name' }, 
    { key: 'sapId', label: 'Sap ID' }, 
    { key: 'type', label: 'Type' }, 
    { key: 'enabled', label: 'Enabled' },
    { key: 'section', label: 'Section' },
    { key: 'parent', label: 'Parent component' },
    { key: 'display', label: 'Display' },
  ]
}

const emptyRow = {}

function getData(componentsData, restrictions, getText, countryName, productId, releaseId) {
  const result = []

  // const modelAndLaoutSheetData = getModelAndLayoutSheet(componentsData['model-and-layout'])

  const info = getInfoSheetData(countryName, productId, releaseId)

  const ceilings = getCeilingsSheetData(componentsData.ceilings, restrictions.ceiling)

  const walls = getWallsSheetData(componentsData.walls, restrictions.walls)

  const floors = getFloorSheetData(componentsData.floors, restrictions.floor)

  const doors = getDoorSheetData(componentsData.doors, restrictions['car-doors'])

  const signalization = getSignalizationSheetData(componentsData.signalization, restrictions.signalization)
  const signalizationPos = getSignalizationPositioningSheetData(componentsData.signalization, restrictions.signalization)

  result.push( 
    ...info, 
    ...ceilings, 
    ...walls, 
    ...floors, 
    ...doors, 
    ...signalization,
    ...signalizationPos,
    )

  const accessoryGroupRestrictions = restrictions.accessories && restrictions.accessories.accessories || []

  if (accessoryGroupRestrictions.includes('mirrors')) {
    result.push({ sheet: SHEETS.MIRRORS.key, name: 'Mirrors disabled' })
  } else {
    const mirrors = getMirrorsSheetData(componentsData.accessories.mirrors, restrictions.accessories)
    result.push(...mirrors)
  }

  if (accessoryGroupRestrictions.includes('handrails')) {
    result.push({ sheet: SHEETS.HANDRAILS.key, name: 'Handrails disabled' })
  } else {
    const handrails = getHandrailsSheetData(componentsData.accessories.handrails, restrictions.accessories)
    result.push(...handrails)
  }

  if (accessoryGroupRestrictions.includes('skirtings')) {
    result.push({ sheet: SHEETS.SKIRTINGS.key, name: 'Skirtings disabled' })
  } else {
    const skirtings = getSkirtingsSheetData(componentsData.accessories.skirtings, restrictions.accessories)
    result.push(...skirtings)
  }

  if (accessoryGroupRestrictions.includes('bufferRails')) {
    result.push({ sheet: SHEETS.BUFFER_RAILS.key, name: 'Buffer rails disabled' })
  } else {
    const bufferRails = getBufferRailsSheetData(componentsData.accessories.bufferRails, restrictions.accessories)
    result.push(...bufferRails)
  }

  if (accessoryGroupRestrictions.includes('infoMediaScreens')) {
    result.push({ sheet: SHEETS.INFO_MEDIA_SCEENS.key, name: 'Info and media screens disabled' })
  } else {
    const screens = getInfoMediaScreenSheetData(componentsData.accessories.infoMediaScreens, restrictions.accessories)
    result.push(...screens)
  }

  return result.map(item => ({
    ...item,
    name: getText(item.name)
  }))
}

function getSkirtingsSheetData(data, accessoryRestrictions = {}) {
  const restrictions = accessoryRestrictions.skirtings || {}

  const result = []

  const finishes = formatArrayToArray(data.finishes, restrictions.finishes)

  result.push(...finishes)

  return result.map(x => ({...x, sheet: SHEETS.SKIRTINGS.key}))
} 

function getBufferRailsSheetData(data, accessoryRestrictions = {}) {
  const restrictions = accessoryRestrictions.bufferRails || {}
  
  const result = []

  const finishes = formatArrayToArray(data.finishes, restrictions.finishes, TYPES.FINISH)
  const positions = formatArrayToArray(data.positions, restrictions.positions, TYPES.POSITION)
  const layouts = formatArrayToArray(data.layouts, restrictions.layouts, TYPES.POSITION)

  result.push(...finishes)
  result.push(...positions.map(pos => ({
    ...pos,
    name: pos.label,
    sapId: pos.id
  })))
  result.push(...layouts.map(pos => ({
    ...pos,
    name: pos.label,
    sapId: pos.id
  })))

  return result.map(x => ({...x, sheet: SHEETS.BUFFER_RAILS.key}))
}

function getInfoMediaScreenSheetData(data, accessoryRestrictions = {}) {
  const restrictions = accessoryRestrictions.infoAndMediaScreens || {}
  
  const result = []


  // const finishes = formatArrayToArray(data.finishes, restrictions.finishes, TYPES.FINISH)
  // const positions = formatArrayToArray(data.positions, restrictions.positions, TYPES.POSITION)
  // const layouts = formatArrayToArray(data.layouts, restrictions.layouts, TYPES.POSITION)

  // result.push(...finishes)
  // result.push(...positions.map(pos => ({
  //   ...pos,
  //   name: pos.label,
  //   sapId: pos.id
  // })))
  // result.push(...layouts.map(pos => ({
  //   ...pos,
  //   name: pos.label,
  //   sapId: pos.id
  // })))

  return result.map(x => ({...x, sheet: SHEETS.INFO_MEDIA_SCEENS.key}))
} 

function getHandrailsSheetData(data, accessoryRestrictions = {}) {
  const restrictions = accessoryRestrictions.handrails || {}

  const result = []

  const handrails = data.models.filter(x => !x.treatAs)
  const models = formatNestedStructure(handrails, restrictions.models, TYPES.COMPONENT, TYPES.FINISH)

  result.push(...models)

  const positions = formatArrayToArray(data.positions, restrictions.positions, TYPES.POSITION)

  result.push(emptyRow)
  result.push(...positions.map(pos => ({
    ...pos,
    name: pos.label,
    sapId: pos.id
  })))

  return result.map(x => ({ ...x, sheet: SHEETS.HANDRAILS.key }))
}

function getMirrorsSheetData(data, accessoryRestrictions = {}) {
  const restrictions = accessoryRestrictions.mirrors || {}

  const result = []

  const types = formatArrayToArray(data.types, restrictions.types, TYPES.COMPONENT)

  const positions = formatArrayToArray(data.positions, restrictions.positions, TYPES.POSITION)
  
  result.push(...types)
  result.push(...positions.map(pos => ({
    ...pos,
    name: pos.label,
    sapId: pos.id
  })))

  return result.map(x => ({ ...x, sheet: SHEETS.MIRRORS.key }))
}

function getInfoSheetData(frontlineName, productId, releaseId) {
  const result = []

  const date = new Date(Date.now())

  const dateStr = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`

  result.push({ key: 'Frontline', value: frontlineName })
  result.push({ key: 'Product', value: productId })
  result.push({ key: 'Release', value: releaseId })
  result.push({ key: 'Date', value: dateStr })
  
  return result.map(x => ({ ...x, sheet: SHEETS.INFO.key }))
}

function getCeilingsSheetData(data, restrictions = {}) {
  const result = formatNestedStructure(data, restrictions, TYPES.COMPONENT, TYPES.FINISH)

  return result.map(x => ({...x, sheet: SHEETS.CEILING.key }))
}

function getWallsSheetData(data, restrictions = {
  decorativeGlass: {},
}) {
  const result = []

  const materials = formatNestedStructure(data.materials, restrictions.materials, TYPES.MATERIAL, TYPES.FINISH)
  
  const scenicWindowTypes = formatArrayToArray(data.scenicWindowTypes, restrictions.scenicWindowTypes, TYPES.COMPONENT)
  const decorativeGlass = formatArrayToArray(data.decorativeGlass, restrictions.decorativeGlass.components, TYPES.COMPONENT)

  const panelOrientation = formatArrayToArray(data.panelOrientation, restrictions.panelOrientation, TYPES.PANELING)
    .map(item => ({ ...item, name: item.label }))

  result.push(...materials, ...scenicWindowTypes, ...decorativeGlass, ...panelOrientation)

  return result.map(x => ({...x, sheet: SHEETS.WALLS.key }))
}

function getFloorSheetData(data, restrictions = {}) {
  const result = formatNestedStructure(data, restrictions, TYPES.MATERIAL, TYPES.FINISH)

  return result.map(x => ({...x, sheet: SHEETS.FLOOR.key }))
}

function getDoorSheetData(data, restrictions = {}) {
  const result = []

  const solutions = formatArrayToArray(data.solutions, restrictions.solutions, TYPES.COMPONENT, 'solutions')
  const doorFinishes = formatArrayToArray(data.doorFinishes, restrictions.doorFinishes, TYPES.FINISH, 'door finishes')
  const frontWallFinishes = formatArrayToArray(data.frontWallFinishes, restrictions.frontWallFinishes, TYPES.FINISH, 'front wall finishes')
  const doorAndFrameFinishes = formatArrayToArray(data.doorAndFrameFinishes, restrictions.doorAndFrameFinishes, TYPES.FINISH, 'frame finishes')
  const landingDoorFinishes = formatArrayToArray(data.landingDoorFinishes, restrictions.landingDoorFinishes, TYPES.FINISH, 'landing door finishes')
  const frames = formatArrayToArray(data.frames, restrictions.frames, TYPES.COMPONENT, 'frames')
  
  result.push(...solutions)
  result.push(emptyRow)
  result.push(...doorFinishes)
  result.push(emptyRow)
  result.push(...frontWallFinishes)
  result.push(emptyRow)
  result.push(...doorAndFrameFinishes)
  result.push(emptyRow)
  result.push(...landingDoorFinishes)
  result.push(emptyRow)
  result.push(...frames)

  return result.map(x => ({...x, sheet: SHEETS.CAR_DOORS.key}))
}

function getSignalizationSheetData(data, restrictions = {}) {
  const result = []
  data.copModels.forEach(componentFamily => {
    const formatted = formatComponentFamily(componentFamily, restrictions.copModels?.[componentFamily.id])
    result.push(...formatted)
    result.push(emptyRow)
  })

  const restrictedHorCops = []
  if (restrictions.horizontalCops?.types) {
    const ids = restrictions.horizontalCops.types.filter(x => x.disabled).map(x => x.id)    

    restrictedHorCops.push(...ids)
  }

  result.push(...formatArrayToArray(data.horizontalCops, restrictedHorCops, TYPES.COMPONENT, 'Handicap COPs'))

  return result.map(x => ({...x, sheet: SHEETS.SIGNALIZATION.key}))
}

function formatComponentFamily(componentFamily, restrictions = {}) {
  const result = []
  const familyRow = { ...componentFamily, sapId: componentFamily.id, type: TYPES.COMPONENT_FAMILY, section: componentFamily.id } 
  if (restrictions.disabled) {
    result.push(familyRow)
    return result
  } else {
    familyRow.enabled = 'X'
    result.push(familyRow)

    const familyItems = []

    familyItems.push(
      ...formatSignalizationItems(componentFamily.copTypes, restrictions.copTypes),
      ...formatSignalizationItems(componentFamily.hallIndicators, restrictions.hallIndicators),
      ...formatSignalizationItems(componentFamily.callStationTypes, restrictions.callStationTypes),
      ...formatArrayToArray(componentFamily.footButtons, restrictions.footButtons),
      ...formatArrayToArray(componentFamily.destinationOP, restrictions.destinationOP),
      ...formatArrayToArray(componentFamily.elevatorIdentifier, restrictions.elevatorIdentifier),
      ...formatArrayToArray(componentFamily.landingDestIndicator, restrictions.landingDestIndicator),
      ...formatArrayToArray(componentFamily.jambIndicators, restrictions.jambIndicators),
    )

    result.push(...familyItems.map(x => ({...x, section: componentFamily.id})))
  }

  return result
}

function formatSignalizationItems(items = [], restrictions = {}) {
  const result = []

  items.forEach(item => {
    const rowsForItem = []
    const row = { ...item, type: TYPES.COMPONENT }
    
    if (restrictions[item.id]?.disabled) {
      rowsForItem.push(row)
    } else {
      row.enabled = 'X'
      rowsForItem.push(row)
      const finishRows = formatArrayToArray(item.finishes, restrictions[item.id]?.finishes, TYPES.FINISH)
      rowsForItem.push(...finishRows)

      const displayRows = formatDisplays(item.displayTypes, restrictions[item.id]?.displayTypes)
      rowsForItem.push(...displayRows)
    }

   result.push(...rowsForItem.map(x => ({ ...x, parent: item.id }))) 
  })

  return result
}

function formatDisplays(displayTypes = [], restrictions = []) {
  const result = []

  displayTypes.forEach(display => {
    const rowsForDisplay = []
    const displayRow = { ...display, type: TYPES.DISPLAY }
    
    const restriction = restrictions.find(x => x.id === display.id)

    if (restriction?.disabled) {
      rowsForDisplay.push(displayRow)
    } else {
      displayRow.enabled = 'X'
      rowsForDisplay.push(displayRow)

      display.displayColors.forEach(color => {
        const colorRow = { ...color, type: TYPES.DISPLAY_COLOR }

        if (restriction?.displayColors?.includes(color.id)) {
          rowsForDisplay.push(colorRow)
          return
        } else {
          colorRow.enabled = 'X'
          rowsForDisplay.push(colorRow)
        }
      })
    }
    result.push(...rowsForDisplay.map(x => ({ ...x, display: display.id })))
  })

  return result
}

function getSignalizationPositioningSheetData(data, restrictions = {}) {
  const result = []

  const positions = []
  const copPositions = formatArrayToArray(data.copPositions, restrictions.positions, TYPES.POSITION, 'COP positions')
  const hlPositions = formatArrayToArray(data.landingComponentPositions.hl, restrictions.hlPositions, TYPES.POSITION, 'HL positions')
  const lcsPositions = formatArrayToArray(data.landingComponentPositions.lcs, restrictions.lcsPositions, TYPES.POSITION, 'LCS positions')
  const eidPositions = formatArrayToArray(data.landingComponentPositions.eid, restrictions.eidPositions, TYPES.POSITION, 'EID positions')
  const dopPositions = formatArrayToArray(data.landingComponentPositions.dop, restrictions.dopPositions, TYPES.POSITION, 'DOP positions')
  const dinPositions = formatArrayToArray(data.landingComponentPositions.din, restrictions.dinPositions, TYPES.POSITION, 'DIN positions')
  
  positions.push(...copPositions)
  positions.push(...hlPositions)
  positions.push(...lcsPositions)
  positions.push(...eidPositions)
  positions.push(...dopPositions)
  positions.push(...dinPositions)

  result.push(...positions.map(pos => ({
    ...pos,
    name: pos.label,
    sapId: pos.id
  })))

  return result.map(x => ({...x, sheet: SHEETS.SIGNALIZATION_POSITIONS.key}))
}

function formatNestedStructure(data, restrictions = {}, outerType, innerType) {
  const result = []

  data
    .filter(item => {
      return item.finishes && item.finishes.length > 0
    })
    .map(item => {
      const finishes = item.finishes.map(finish => ({...finish, type: innerType }))

      return {
        ...item,
        finishes,
        type: outerType,
      }
    })
    .forEach(item => {
    const { id, finishes } = item

    if (!restrictions[id]) {
      result.push({ ...item, enabled: 'X' })

      finishes.forEach(finish => {
        result.push({...finish, enabled: 'X', section: item.sapId })
      })
      return
    }

    if (restrictions[id].disabled) {
      result.push(item)
      return
    }

    result.push({ ...item, enabled: 'X' })

    finishes.forEach(finish => {
      const finishEnabled = !restrictions[id].finishes?.includes(finish.id)
      const finishItem = { ...finish, section: item.sapId }

      if (finishEnabled) {
        finishItem.enabled = 'X'
      }

      result.push(finishItem)
    })
  })

  return result
}

function formatArrayToArray(data = [], restrictions = [], type, section) {
  return data.map(item => {
    if (restrictions.includes(item.id)) {
      return { ...item, type, section }
    }

    return { ...item, type, enabled: 'X', section }
  })
}


export default { getSheets, getHeaders, getData, getSheets, getName }