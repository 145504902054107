import React, { useMemo, useState, useRef, useEffect, useContext } from 'react'
import { Spin, Tabs, Tooltip, Affix } from 'antd'

import './ProductSelection.scss'
import { groupProducts } from '../../utils/product-utils'
import useService from '../../services/useService'
import ProductGroup from './ProductGroup'
import { UIContext } from '../../store/ui'
import { useHistory } from 'react-router-dom'
import { TranslationsContext } from '../../store/translations'

const { TabPane } = Tabs

const GROUP_ORDER = [
  'Europe',
  'Europe SOC',
  'Modular Modernization',
  'Full Replacement',
  'China',
  'China Export',
  'Russia',
  'Marine',
  'ENA',
  'ENA Modernization',
  'Modular Modernization SOC',
  'Modular Modernization SOC Export',
].reduce((prev, curr, i) => {
  prev[curr] = i
  return prev
}, {})

const TOOLTIP_TOP_UNTIL_I = 3

/**
 * @param {Object} props
 * @param {Object} props.frontline
 * @param {boolean=} props.managingGlobal
 * @param {boolean} props.admin
 * @param {Object[]} props.allProducts
 */
const ProductSelection = (props) => {
  const {
    frontline,
    managingGlobal,
    admin,
    allProducts,
  } = props


  const uiCtx = useContext(UIContext)


  useEffect(() => {
    if (uiCtx.prevScrollOnProductPage) {
      window.scrollTo(0, uiCtx.prevScrollOnProductPage)
    }
  }, [])

  useEffect(() => {
    return () => {
      uiCtx.setPrevScrollOnProductPage(window.scrollY)
    }
  }, [window.scrollY])

  const groups = useMemo(() => {
    if (!frontline || !allProducts) return
    const grouped = groupProducts(frontline.products, allProducts)

    return grouped
      .sort((a, b) => {
        const first = a[0]
        const second = b[0]

        if (GROUP_ORDER[first] > GROUP_ORDER[second]) return 1
        if (GROUP_ORDER[first] < GROUP_ORDER[second]) return -1
        return 0
      })
  }, [frontline, allProducts])

  if (!allProducts) {
    return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>
  }

  const showTabs = groups && groups.length > 1

  function getActiveKey() {
    if (!groups || groups.length === 0) return

    if (uiCtx.selectedProductGroup) {
      const isActive = groups.find(group => group[0] === uiCtx.selectedProductGroup)

      if (isActive) return uiCtx.selectedProductGroup
    }

    return groups[0][0]
  }

  const activeKey = getActiveKey()
  return (
    <div className="ProductSelection">
      <Tabs
        size="default"
        animated={false}
        // tabPosition={'top'}
        renderTabBar={props => <CustomTabBar {...props} />}
        activeKey={activeKey}
        className={showTabs ? 'product-group-tabs' : 'product-group-tabs product-group-tabs--collapsed'}
        onChange={uiCtx.setSelectedProductGroup}>

        {groups && groups.map((group, i) => {
          return (
            <TabPane tab={
              <TabItem 
                tooltipPlacement={managingGlobal && i < TOOLTIP_TOP_UNTIL_I ? 'top' : 'bottom'}
                group={group} 
                products={frontline.products} 
                />
              } key={group[0]}>
              <ProductGroup
                key={i}
                managingGlobal={managingGlobal}
                admin={admin}
                group={group}
                frontline={frontline}
              />
            </TabPane>
          )
        })
        }
      </Tabs>
    </div>
  )
}

function CustomTabBar(props) {
  const { panes, onTabClick, activeKey, className = '' } = props

  function handleClick(id, e) {
    onTabClick(id, e)
  }

  return (
    <div className={`CustomTabBar ${className}`}>
      {
        panes.map(panel => {
          let classes = 'tab-pane'
          if (activeKey === panel.key) {
            classes += ' tab-pane-selected'
          }
          return <div key={panel.key} className={classes} onClick={(e) => handleClick(panel.key, e)}>{panel.props.tab}</div>
        })
      }
    </div>
  )

}

function TabItem(props) {
  const { group, tooltipPlacement = 'bottom' } = props

  const { getText } = useContext(TranslationsContext)

  const titleText = group[1]
    .map(x => <p key={x.id}>{getText(`product-${x.id}`)}</p>)

  return (
    <Tooltip placement={tooltipPlacement} title={titleText}>
      <span>{group[0]}</span>
    </Tooltip>
  )
}


export default ProductSelection