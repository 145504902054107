import React, { useState, useEffect, useRef } from 'react'

import ButtonWithPopconfirm from '../ButtonWithPopconfirm'


/**
 * Button that does not show popconfirm on click when it has been disabled 
 * (unlike the default ant-design behavior).
 * @param {Object} props
 * @param {Function} props.onConfirm - Action to perform
 * @param {boolean=} props.confirmation - Whether to ask for confirmation
 * @param {string=} props.okText - Text of the confirmation OK button
 * @param {string=} props.confirmText - Confirmation text
 * @param {*=} props.children
 * @param {boolean=} props.disabled
 * @param {string=} props.disabledTooltipText - tooltip to show on hover when button is disabled
 * @param {string=} props.popconfirmPlacement
 * @param {string=} props.tooltipPlacement
 * @param {string=} props.tooltipText
 * @param {string=} props.className
 */
const AsyncButton = ({ onConfirm, ...rest }) => {
  const [ loading, setLoading ] = useState(false)
  const mountedRef = useRef(true)
  const handleAction = async () => {
    setLoading(true)
    try {
      await onConfirm()
      if (mountedRef.current) {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <ButtonWithPopconfirm onConfirm={handleAction} loading={loading} {...rest}></ButtonWithPopconfirm>
  )
}

export default AsyncButton