import React, { useState, useContext, useEffect } from 'react'
import { Tabs, notification, Button, message, Breadcrumb, Spin, Modal, Affix } from 'antd'
import { Link } from 'react-router-dom'
import './ManageOfferingPage.scss'
import { FrontlinesContext } from '../../store/frontlines';
import tabs from './Tabs'
import { TranslationsContext } from '../../store/translations';
import AsyncButton from '../../components/AsyncButton'
import { AuthContext } from '../../store/auth';
import { APIContext } from '../../store/api'
import { createErrorMessage } from '../../utils/error-utils'
import NotFoundPage from '../NotFoundPage'
import { notify as notifySaveSuccess } from '../../components/SaveNotification'
import { useMemo } from 'react'
import { useFrontline } from '../../store/frontlines/useFrontline'
import ExportDataButton from '../../components/ExportDataButton/ExportDataButton';
import offeringCSV from '../../csv/data/offering';

const { TabPane } = Tabs

const HIDE_DISABLED_LOCAL_STORAGE_KEY = 'hideDisabledValues'

const ManageOfferingPage = ({ match, history }) => {
  const api = useContext(APIContext)
  const { productId, releaseId, country } = match.params
  const [ loading, setLoading ] = useState(true)
  const [ notFound, setNotFound ] = useState()
  const [ hideDisabled, setHideDisabled ] = useState(localStorage.getItem(HIDE_DISABLED_LOCAL_STORAGE_KEY) === 'true')

  // Is the save button disabled or not?
  const [ saveDisabled, setSaveDisabled ] = useState(false) 

  // Base data for the product that is being managed.
  const [ productData, setProductData ] = useState() 

  // The restrictions for the product.
  const [ restrictions, setRestrictions ] = useState()
  const { 
    globalFrontline,
    loadGlobalOffering,
    loadFrontline,
    releaseProduct,
    updateRestrictionsForProduct 
  } = useContext(FrontlinesContext)
  const { getText } = useContext(TranslationsContext)
  const { loggedInUser } = useContext(AuthContext)

  const frontline = useFrontline()

  const managingGlobal = useMemo(() => {
    if (!frontline) return false
    return frontline.hiddenId.toLowerCase() === 'global'
  }, [frontline])


  const productName = useMemo(() => {
    if (!productData || !productData.name) return
    return getText(productData.name)
  }, [productData])

  useEffect(() => {
    (async () => {
      try {
        const flToManage = managingGlobal ? 
          await loadGlobalOffering({ productId, releaseId }) : 
          await loadFrontline({ country })

        // Initial value for the product being managed.
        const productToManage = flToManage.products
          .find(product => product.id === productId) || {}

        const releaseToManage = productToManage.productReleases.find(x => x.id === releaseId) || {}
          
        setRestrictions(releaseToManage.restrictions || {}) // Init restrictions.

        const apiToUse = managingGlobal ? 'globalData' : 'global'
        const packageApi = `/package/${apiToUse}/${productId}?releaseId=${releaseId}`

        try {
          const productData = await api.get(packageApi)
          setProductData(productData)
          setLoading(false)
        } catch (err) {
          console.error(err)
          message.error(err.message)
          history.push(`manage`)
        }
        
      } catch (err) {
        console.error(err)
        setNotFound(true)
      }
    })()
  }, [country, productId, releaseId])

  if (!loggedInUser) return null

  const handleSaveClick = async (e) => {
    try {
      const fl = managingGlobal ? globalFrontline : frontline

      const result = await updateRestrictionsForProduct(fl, productId, restrictions, releaseId)

      const { failedFrontlines } = result

      notifySaveSuccess({
        productId,
        frontline: fl,
        productName,
        releaseId,
        releaseProduct,
        failedFrontlines,
        releaseAllowed: globalFrontline.allowRelease,
      })
      
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the offering',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  function handeHideDisabledToggle() { 
    const newState = !hideDisabled
    setHideDisabled(newState)
    localStorage.setItem(HIDE_DISABLED_LOCAL_STORAGE_KEY, newState)
  }

  if (notFound) return <NotFoundPage></NotFoundPage>

  if (loading) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  return (
    <div className="ManageOfferingPage page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
        {
          !managingGlobal &&
          <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/products`}>Products</Link>
        </Breadcrumb.Item>
        }
        <Breadcrumb.Item>
          <Link to={`manage`}>{productName} ({releaseId})</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Offering
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">Offering for {productName} ({releaseId})</h1>
      <Tabs defaultActiveKey={tabs[0].key} tabPosition="top" type="card"
        // tabBarExtraContent={<Button onClick={handeHideDisabledToggle} type="link">{hideDisabled ? 'Show disabled selections' : 'Hide disabled selections'}</Button>}
      >
      { tabs.map(tab => {
        const { Component, text, key, globalOnly } = tab
        const initRestrictions = restrictions[key] || {}
        if (!Component) return null

        if (globalOnly && !managingGlobal) return null

        return (
        <TabPane tab={text} key={key} disabled={saveDisabled}>
          <Component 
            title={text}
            initRestrictions={initRestrictions}
            saveDisabled={saveDisabled}
            disableSaving={setSaveDisabled}
            product={productData}
            hideDisabled={hideDisabled}
            managingGlobal={managingGlobal}
            setRestrictions={value => {
              let valueToSet = value
              if (typeof value === 'function') {
                valueToSet = value(restrictions[key])
              } 
              setRestrictions(prev => ({
                ...prev,
                [key]: valueToSet
              }))
            }
            }
          ></Component>
        </TabPane>
        )
      })}
      </Tabs>
      <div className="save-button-container">
        <AsyncButton
          className="save-button"
          onConfirm={handleSaveClick}
          confirmText="Are you sure you want to save the changes?"
          disabled={saveDisabled}
          type="primary"
        >
          Save
        </AsyncButton>
        <ExportDataButton
          data={offeringCSV.getData(productData.componentsData, restrictions, getText, country, productId, releaseId )}
          headers={offeringCSV.getHeaders()}
          sheets={offeringCSV.getSheets()}
          filename={offeringCSV.getName(country, productId, releaseId)}
        />
      </div>
    </div>
  )
}


export default ManageOfferingPage