import React, { useEffect, useState } from 'react'
import { message, Radio } from 'antd'
import { Line } from 'react-chartjs-2'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import useService from '../../../../services/useService'
import { createGraphData, filterData, getRangeByPeriod } from '../../../APIDashboardPage/api-dashboard-utils'
import { defaultGraphOptions } from '../../../APIDashboardPage/api-dashboard-constants'
import './RickGraph.scss'

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 * @param {string=} props.title 
 * @param {function=} props.filter 
 */
function RickGraph(props) {
  const {
    className = '',
  } = props

  const logService = useService('log')
  const [loading, setLoading] = useState(true)
  const [rawData, setRawData] = useState([])
  const [period, setPeriod] = useState("week")

  useEffect(() => {
    if (!logService) return
    logService.getRickEntries().then(result => {
      setRawData(result)
    }).catch(err => {
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })
  }, [logService])

  const range = getRangeByPeriod(period)
  const filteredData = filterData(rawData, { range })
  const graphData = createGraphData(filteredData, period === "day" ? "hour" : "day", null, range)

  return (
    <div data-testid="RickGraph" className={`RickGraph ${className}`}>
      <LoadingSpinner loading={loading}>
        <Line height={50} width={300} data={graphData} options={defaultGraphOptions} />
        <div className='RickGraph-filters'>
          <Radio.Group value={period} onChange={e => setPeriod(e.target.value)} >
            <Radio.Button value={"day"}>Day</Radio.Button>
            <Radio.Button value={"week"}>Week</Radio.Button>
            <Radio.Button value={"month"}>Month</Radio.Button>
          </Radio.Group>
        </div>
      </LoadingSpinner>
    </div>
  )
}

export default RickGraph