import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { Layout } from 'antd'

import MainNav from "../MainNav"
import "./Main.scss"
import { ConfigContext } from "../../store/config"
import { FrontlinesContext } from "../../store/frontlines"
import ErrorPage from "../../pages/ErrorPage/ErrorPage"

const { Header, Content, Footer } = Layout

/**
 * Creates the main layout of the application
 * @function Layout Render function for layout structure
 * @param {Object} props Properties passed to this renderer
 */
const Main = ({ children }) => {
  const config = useContext(ConfigContext)
  const { globalFrontline } = useContext(FrontlinesContext)
  return (
    <div className="Main">
      <Helmet title="Car Designer Content Management" />
      <Layout>
        <Header className="header">
          <MainNav />
        </Header>
        <Content className="content">
          <ErrorPage>
            {children}
          </ErrorPage>
        </Content>
        <Footer>
          <div style={{textAlign: 'center'}}>{
            globalFrontline && 
            globalFrontline.appVersions && 
            globalFrontline.appVersions.adminTool &&
            globalFrontline.appVersions.adminTool.savedVersion}</div>
        </Footer>
      </Layout>
    </div>
  )
}
export default Main