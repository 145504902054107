const createLogService = (api) => {
  return {
    getKTOCEntries() {
      return api.get(`/logs/design`)
    },

    getGenDocEntries() {
      return api.get(`/logs/project`)
    },

    getQueueStatus({ highPriority = false } = {}) {
      return api.get(`/logs/queue-status?highPriority=${highPriority}`)
    },

    getMobileEntries() {
      return api.get(`/logs/mobile`)
    },

    getCadEntries({ lastDays, lastHours, fromDay, toDay, continuationToken }) {
      return api.get(`/logs/cad?lastDays=` + lastDays + '&lastHours=' + lastHours + '&fromDay=' + fromDay + '&toDay=' + toDay+ '&continuationToken='+JSON.stringify(continuationToken))
    },

    getImageGenStatus() {
      return api.get(`/logs/image-gen-status`)
    },

    getRickServersStatus() {
      return api.get(`/logs/rick-services-status`)
    },

    getKTOCProducts() {
      return api.get('/logs/ktoc-products')
    },

    getRickEntries() {
      return api.get(`/logs/cadlog`)
    },

    getCdLeads() {
      return api.get(`/logs/cdleads`)
    },

    getPlannerLeads() {
      return api.get(`/logs/plannerleads`)
    },

    getRickLeads() {
      return api.get(`/logs/cadleads`)
    },
  }
}

export default createLogService