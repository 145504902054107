import React, { useState, useEffect, useContext, useMemo } from 'react'

import './FrontlineCreator.scss'
import { Select, message, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import AsyncButton from '../AsyncButton'
import useService from '../../services/useService'
import { FrontlinesContext } from '../../store/frontlines'

const { Option, OptGroup } = Select

/**
 * 
 * @param {Object} props 
 * @param {Object[]} props.frontlines 
 * @param {Function} props.createFrontline 
 * @param {Function=} props.afterCreated 
 */
const FrontlineCreator = (props) => {
  const { frontlines, createFrontline, afterCreated } = props

  const [ selectedCountry, setSelectedCountry ] = useState()
  const [ countries, setCountries ] = useState()
  const [ creating, setCreating ] = useState(false)
  const frontlineService = useService('frontline')

  const optGroups = useMemo(() => {
    if (!countries) return null

    const groups = countries.reduce((prev, curr) => {
      if (!prev[curr.optionGroup]) {
        prev[curr.optionGroup] = []
      }

      prev[curr.optionGroup].push(curr)
      return prev
    }, {})

    return (<>
      {
        Object.entries(groups).map(entry => {
          const [ groupName, countries ] = entry
    
          return (
            <OptGroup key={groupName} label={groupName}>
              { countries.map((country) => {
                return (
                  <Option value={country.name} key={country.name}>{country.displayName}</Option>
                )
              })}
            </OptGroup>
          )
        })
      }
    </>)
  }, [countries])

  useEffect(() => {
    if (!frontlines) return
    (async () => {
      const existing = frontlines.map(fl => fl.hiddenId)
      const result = await frontlineService.getCountries()
      const notExisting = result.filter(country => {
        return !existing.find(id => id === country.name.toLowerCase())
      })

      setCountries(notExisting)
    })()
  }, [frontlines])

  async function handleSaveClick() {
    try {
      const createdFL = await createFrontline(selectedCountry.name)
      setTimeout(() => {
        message.success('Frontline created succesfully')
        if (afterCreated) {
          afterCreated(createdFL)
        }
        handleCancel()
      }, 0)
    } catch (err) {
      console.error('Error when creating a frontline:', err)
      const errorMessage = `Error when creating a frontline: ${err.message}`
      message.error(errorMessage, 5)
    }
  }

  const handleSelect = (value) => {
    const countryToCreate = countries.find(x => x.name === value)
    setSelectedCountry(countryToCreate)
  }

  const handleCancel = () => {
    setSelectedCountry(null)
    setCreating(false)
  }

  return (
    <div className="FrontlineCreator">
      {!creating ? 
      <Button 
        onClick={() => setCreating(true)} 
        icon={<PlusOutlined />}
      >
        New frontline
      </Button> :
      <div className="creating-new-container">
        <Select
          className="country-select"
          dropdownClassName="country-select__dropdown"
          showSearch
          loading={!countries}
          notFoundContent="All possible frontlines have already been created"
          autoFocus={true}
          placeholder="Select a country"
          onChange={handleSelect}
          filterOption={(input, option) => {
            if (!option.value) {
              return false
            }
            // option.props.children.toLowerCase().includes(input.toLowerCase())
            return option.value.toLowerCase().includes(input.toLowerCase())
          }
            
          }
        >
           { optGroups }
        </Select>
        <div className="buttons-container">
          <Button onClick={handleCancel}>Cancel</Button>
          <AsyncButton
            onConfirm={handleSaveClick}
            type="primary"
            disabledTooltipText="You have to select a frontline country."
            disabled={!selectedCountry}
          >Create</AsyncButton>
        </div>
      </div> }
    </div>
  )
}

export default FrontlineCreator