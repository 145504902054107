import React, { useState } from 'react'
import { Switch, message, Input, Button } from 'antd'
import { EditTwoTone, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import validator from 'validator'
import './WelcomePageManager.scss'
import { createErrorMessage } from '../../utils/error-utils'

/**
 * 
 * @param {Object} props 
 * @param {Object} props.frontline 
 * @param {Object=} props.domainData 
 * @param {Function} props.saveWelcomePageUIState 
 */
const WelcomePageManager = (props) => {
  const {
    frontline,
    saveWelcomePageUIState,
    domainData = {}
  } = props

  return (
    <div className="WelcomePageManager">
      <div className="toggles-container">
        <div className="url-manager">
          <SectionToggle
            saveWelcomePageUIState={saveWelcomePageUIState}
            frontline={frontline}
            name="landingMaterials"
            label="Landing Materials"
          />
          {domainData && (
            <URLSelector
              saveWelcomePageUIState={saveWelcomePageUIState}
              frontline={frontline}
              name="landingMaterials"
              domainData={domainData}
            />
          )}
        </div>
        <div className="url-manager">
          <SectionToggle
            saveWelcomePageUIState={saveWelcomePageUIState}
            frontline={frontline}
            name="elevatorPlanner"
            label="KONE Elevator Planner"
          />
          {domainData && (
            <URLSelector
              saveWelcomePageUIState={saveWelcomePageUIState}
              frontline={frontline}
              name="elevatorPlanner"
              domainData={domainData}
            />
          )}
        </div>
        <div className="url-manager">
          <SectionToggle
            saveWelcomePageUIState={saveWelcomePageUIState}
            frontline={frontline}
            name="mobileApp"
            label="KONE Car Designer Mobile App"
          />
        </div>
      </div>
    </div>
  )
}

const URLSelector = ({ name, frontline, domainData, saveWelcomePageUIState }) => {
  const [ editing, setEditing ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  
  let urlInFrontline

  if (frontline.carDesignerUI && frontline.carDesignerUI.welcomePage && frontline.carDesignerUI.welcomePage.sections && frontline.carDesignerUI.welcomePage.sections[name]) {
    urlInFrontline = frontline.carDesignerUI.welcomePage.sections[name].url
  }

  const defaultValue = urlInFrontline ||  domainData[`${name}URL`] || ''
  const [ url, setUrl ] = useState(defaultValue.trim())
  const [ value, setValue ] = useState(defaultValue.trim())
  const [isValid, setIsValid] = useState(validateUrl(defaultValue))

  function validateUrl(value) {
    return validator.isURL(value.trim(), {
      protocols: [''],
      require_protocol: false,
    })
  }

  const handleSave = async () => {
    if (!isValid) return
    setLoading(true)

    try {
      const newFrontline = await saveWelcomePageUIState(frontline, { url: value.trim(), section: name })
      
      setLoading(false)
      setUrl(value)
      setEditing(false)
      message.success('URL changed succesfully.')
    } catch (err) {
      setLoading(false)

      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the new URL',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value)

    const valid = validateUrl(e.target.value)
    setIsValid(valid)
  }

  const handleCancel = () => {
    setEditing(false)
  }
  
  if (editing) {
    return (
      <div className="URLSelector URLSelector--editing">
        <Input
          className="URLSelector__input"
          addonBefore="https://"
          onPressEnter={handleSave}
          onChange={handleChange}
          placeholder={url}
          defaultValue={url}
        />
        <Button
          className="URLSelector__button"
          onClick={handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          className="URLSelector__button"
          disabled={!isValid}
          onClick={handleSave}
          type="primary"
          loading={loading}
        >
          Apply
        </Button>
      </div>
    )
  }

  return (
    <div className="URLSelector">
      <span className="URLSelector__value">{url || 'No URL set'}</span>
      <EditTwoTone
        className="URLSelector__icon"
        onClick={() => setEditing(true)}
      />
    </div>
  ) 
}

const SectionToggle = ({ label, name, frontline, saveWelcomePageUIState }) => {

  const [ loading, setLoading ] = useState(false)

  const initialToggled = !(frontline.carDesignerUI && frontline.carDesignerUI.welcomePage && frontline.carDesignerUI.welcomePage.sections && frontline.carDesignerUI.welcomePage.sections[name] && frontline.carDesignerUI.welcomePage.sections[name].disabled)

  const [toggled, setToggled] = useState(initialToggled)

  const handleChange = async (newValue) => {
    setLoading(true)

    try {
      await saveWelcomePageUIState(frontline, { section: name, visible: newValue }) 
      setLoading(false)
      setToggled(newValue)
      message.success('The state changed succesfully.')
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the state',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)

      setLoading(false)
    }
  }

  return (
      <label className="SectionToggle" htmlFor={name}>
        <span className="SectionToggle__label">{label}</span>
        <Switch 
          loading={loading} className="SectionToggle_switch"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={handleChange} checked={toggled}></Switch></label>
  )
}

export default WelcomePageManager