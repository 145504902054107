import React, { useState, useMemo, useContext } from 'react'
import { Button, Modal, Card, Tooltip, message, Menu, Dropdown, Radio } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dateFns from 'date-fns'

import CustomSwitch from '../CustomSwitch'
import { createErrorMessage } from '../../utils/error-utils'
import { FrontlinesContext } from '../../store/frontlines'
import AsyncButton from '../AsyncButton'

// TODO get this from the real products array
const modernizationProducts = [
  'add-on-deco',
  'nanospace',
  'monospace-500flex',
  'monospace-upg-gcn',
  'monospace-upg-exp',
  'minispace-upg-gcn',
  'minispace-upg-exp',
]

/**
 *
 * @param {Object} props
 * @param {Object} props.product
 * @param {Object} props.frontline
 * @param {Object} props.globalFrontline
 * @param {boolean} props.admin
 * @param {string} props.previewURL
 * @param {Function} props.toggleFullReplacementStatus
 * @param {boolean} props.managingGlobal
 * @param {boolean=} props.minimized
 */
function ProductCard(props) {
  const {
    product,
    frontline,
    previewURL,
    globalFrontline,
    admin,
    toggleFullReplacementStatus,
    managingGlobal,
    minimized,
    releaseId,
    showMonospaceUpgradeWarning,
    warnOnAdd,
  } = props

  const showFullReplacementToggle =
    frontline.hiddenId !== 'marine' && frontline.hiddenId !== 'india' && !managingGlobal

  const frontlineCtx = useContext(FrontlinesContext)
  const { releaseProduct, saveDisabledState, removeProduct } = frontlineCtx
  
  const [loadingFullReplacement, setLoadingFullReplacement] = useState(false)

  const [isReleasing, setIsReleasing] = useState(false)

  const thisRelease = useMemo(() => {
    if (!product || !product.productReleases) return {}

    return product.productReleases.find((x) => x.id === releaseId) || {}
  }, [product, releaseId])

  const [releasingAllowed, errorMessage] = useMemo(() => {
    if (!thisRelease.offeringLastSaved) return [false, 'Offering has never been saved.']
    if (admin) return [true]

    if (!globalFrontline.allowRelease) {
      return [false, 'Releasing is not allowed at this time.']
    }

    return [true]
  }, [globalFrontline, admin, thisRelease])

  const productEnabled = useMemo(() => {
    if (product.productDisabled) return false
    if (!product.productReleases) return false

    const hasSavedRelease = !!product.productReleases.find((x) => !!x.offeringLastSaved)

    return hasSavedRelease
  }, [product])

  function showConfirm() {
    Modal.confirm({
      title: `Are you sure you want to release ${product.name} (${releaseId}) for ${frontline.country.name}?`,
      content: 'This action will release the product to the KONE Car Designer.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRelease()
      },
    })
  }

  function showRemoveConfirm() {
    Modal.confirm({
      title: `Are you sure you want to remove ${product.name} from ${frontline.country.name}?`,
      content:
        'This action will delete the product and all existing settings for this product. The product can be added back to the frontline again later but any current settings will not be preserved.',
      okText: 'Yes, delete',
      width: 540,
      icon: <WarningTwoTone twoToneColor="red" style={{ marginTop: '10px' }} />,
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemove()
      },
    })
  }

  function handleRelease() {
    setIsReleasing(true)
    releaseProduct(frontline, product.id, releaseId)
      .then((result) => {
        message.success(`Product ${frontline.country.name} / ${product.id} / ${releaseId} released succesfully!`)
        if (result.failedFrontlines) {
          result.failedFrontlines.forEach((item) => {
            message.error(`Failed to update the product for ${item.frontlineName}`, 15)
          })
        }
      })
      .catch((err) => {
        console.error(err)
        const errorMessage = createErrorMessage(err, {
          message: `Releasing product failed for ${frontline.country.name} / ${product.id} / ${releaseId}`,
          attemptedItem: 'frontline',
        })

        message.error(errorMessage, 5)
      }).finally(() => {
        setIsReleasing(false)
      })
  }

  async function handleRemove() {
    try {
      const result = await removeProduct(frontline, product.id)
      message.success('Removed succesfully!')
    } catch (err) {
      console.error(err)
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Removing',
        attemptedItem: 'frontline',
      })

      message.error(errorMessage, 5)
    }
  }

  async function confirmIsKoneOnly(productId, isKoneOnly) {
    if (!warnOnAdd || isKoneOnly) {
      return setIsKoneOnlyState(productId, isKoneOnly)
    }

    Modal.confirm({
      title: `Are you sure?`,
      content: `You are about to set another Monospace Upgrade visible to everyone which will result in showing two products with the same name in Car Designer. It is suggested to allow only one Monospace Upgrade to be released to public.  Are you sure you want to continue?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return setIsKoneOnlyState(productId, isKoneOnly)
      },
    })
  }

  async function setIsKoneOnlyState(productId, isKoneOnly) {
    try {
      await frontlineCtx.setIsKoneOnly(frontline, productId, isKoneOnly)
      message.success(`State change succesful`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing the state',
        attemptedItem: 'frontline',
      })

      message.error(errorMessage, 5)
    }
  }

  async function setProductDisabledState(args, disabled) {
    try {
      await saveDisabledState(frontline, args, disabled)
      message.success(
        `${args.name} succesfully ${disabled ? 'disabled' : 'enabled'} for ${
          frontline.country.name
        }.`
      )
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing the state',
        attemptedItem: 'frontline',
      })

      message.error(errorMessage, 5)
    }
  }

  function toggleEnabledStatus(args) {
    const newDisabledState = !args.productDisabled
    Modal.confirm({
      title: `Are you sure you want to ${newDisabledState ? 'disable' : 'activate'} ${
        args.name
      } for ${frontline.country.name}?`,
      content: `This action will ${newDisabledState ? 'hide' : 'enable'} the product ${
        newDisabledState ? 'from' : 'in'
      } the KONE Car Designer for ${frontline.country.name}.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return setProductDisabledState(args, newDisabledState)
      },
    })
  }

  if (minimized) {
    return (
      <Card
        className="ProductCard ProductCard--minimized"
        title={`${product.name}`}
        extra={
          <div className="card-extra-container">
            <Button type="danger" onClick={showRemoveConfirm}>
              Delete
            </Button>
            <div className="switch-container">
              <span>Enabled:</span>
              <CustomSwitch
                tooltipPlacement="bottom"
                disabledTooltipText="Changing the status of products is not allowed at this time"
                onChange={() =>
                  toggleEnabledStatus({
                    id: product.id,
                    name: product.name,
                    releaseId,
                    productDisabled: product.productDisabled,
                  })
                }
                checked={!product.productDisabled}
              />
            </div>
          </div>
        }
      ></Card>
    )
  }

  return (
    <Card
      className="ProductCard"
      title={`${product.name} (${releaseId})`}
      extra={
        <ManageProductDropdown
          releaseId={releaseId}
          frontlineId={frontline.hiddenId}
          productId={product.id}
          managingGlobal={managingGlobal}
        />
      }
    >
      {thisRelease ? (
        <div className="card-container">
          <div className="card-container__row">
            <span>Offering last saved:</span>
            <b>
              {thisRelease.offeringLastSaved
                ? dateFns.format(new Date(thisRelease.offeringLastSaved), 'MMMM Do, YYYY, HH:mm')
                : ' Never'}{' '}
            </b>
          </div>
          <div className="card-container__row" style={{ marginTop: '0.5rem' }}>
            {previewURL && frontline.country && frontline.country.alpha3 && (
              <a
                className={thisRelease.productDisabled ? 'disabled' : ''}
                href={`${previewURL}/${frontline.country.alpha3.toLowerCase()}/${product.id}/${
                  thisRelease.id
                }/edit/blank`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Preview offering
              </a>
            )}
          </div>
          <div className="card-container__row">
            <span>Latest release:</span>
            <b>
              {product.lastProductReleased
                ? dateFns.format(new Date(product.lastProductReleased), 'MMMM Do, YYYY, HH:mm')
                : thisRelease.lastProductReleased
                ? dateFns.format(new Date(thisRelease.lastProductReleased), 'MMMM Do, YYYY, HH:mm')
                : 'Never'}
            </b>
          </div>
          <div className="card-container__row">
            <span>Released by:</span>
            <span>
              {product.lastProductReleased && product.releasedBy
                ? product.releasedBy
                : thisRelease.lastProductReleased && thisRelease.releasedBy
                ? thisRelease.releasedBy
                : 'No data'}
            </span>
          </div>
          {
            <div className="card-container__row">
              <Tooltip
                placement="bottom"
                overlayStyle={{ zIndex: 1000 }}
                title={releasingAllowed ? null : errorMessage}
              >
                <Button loading={isReleasing} disabled={!releasingAllowed} type="danger" onClick={() => showConfirm()}>
                  Release
                </Button>
              </Tooltip>
              {!managingGlobal && (
                <Button type="danger" className="delete-button" onClick={showRemoveConfirm}>
                  Delete
                </Button>
              )}
              {!managingGlobal && ( // TODO Should this be enabled for global as well?
                <div className="switch-container">
                  <span>Product enabled:</span>
                  <CustomSwitch
                    onChange={() =>
                      toggleEnabledStatus({
                        id: product.id,
                        name: product.name,
                        productDisabled: product.productDisabled,
                        releaseId,
                      })
                    }
                    disabledTooltipText={errorMessage}
                    disabled={!productEnabled}
                    checked={productEnabled && !product.productDisabled}
                  />
                </div>
              )}
            </div>
          }
          <div className="card-container__row">
            {!managingGlobal && (
              <CustomSwitch
                className="visibility-toggle"
                type="buttons"
                offText="Visible to everyone"
                onText="KONE only"
                disableLoading={product.isKoneOnly && warnOnAdd}
                warning={
                  showMonospaceUpgradeWarning
                    ? `There are two different MonoSpace Upgrade products visible to everyone. Consider making at least one of them only visible for KONE employees or disabling the product completely.`
                    : null
                }
                checked={product.isKoneOnly}
                onChange={(checked) => confirmIsKoneOnly(product.id, checked)}
              />
            )}
            {showFullReplacementToggle && (
              <div className="switch-container">
                <span>Show this product in full replacement category</span>
                <CustomSwitch
                  onChange={async () => {
                    setLoadingFullReplacement(true)
                    await toggleFullReplacementStatus({
                      id: product.id,
                      name: product.name,
                      fullReplacementStatus: product.modernization,
                    })
                    setLoadingFullReplacement(false)
                  }}
                  tooltipText="Whether the product should be listed in the full replacement section in the car designer."
                  disabledTooltipText="The state cannot be changed for this product."
                  loading={loadingFullReplacement}
                  disabled={modernizationProducts.includes(product.id)}
                  checked={
                    product.productFamily !== 'modular-modernization' && product.modernization
                  }
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>There is no saved offering.</p>
      )}
    </Card>
  )
}

function ManageProductDropdown(props) {
  const { productId, releaseId, frontlineId, managingGlobal } = props

  const linkBase = `/frontline/${frontlineId}/product/${productId}/${releaseId}`

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to={`${linkBase}/offering`}>Offering</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`${linkBase}/predesigns`}>Predesigns</Link>
      </Menu.Item>
      {!managingGlobal && (
        <Menu.Item key="3">
          <Link to={`${linkBase}/local-themes`}>Local themes</Link>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="link">Manage product</Button>
    </Dropdown>
  )
}

/**
 * @param {Object} props
 * @param {number} props.releasedOn
 * @param {string=} props.releasedBy
 */
const InfoFields = (props) => {
  const { releasedOn, releasedBy } = props

  return (
    <div className="info-fields">
      <div className="info-row">
        <span className="info-label">Released on:</span>
        <span className="info-field">
          {dateFns.format(new Date(releasedOn), 'MMMM   Do, YYYY, HH:mm')}
        </span>
      </div>
      <div className="info-row">
        <span className="info-label">Released by:</span>
        <span className="info-field">{releasedBy ? releasedBy : 'No data'}</span>
      </div>
    </div>
  )
}

export default ProductCard
