import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Table, Input, Button, message, Tag, Popconfirm, Select } from 'antd'
import { SearchOutlined, SyncOutlined } from '@ant-design/icons'
import isEmail from 'validator/lib/isEmail';
import './UsersDisplay.scss'
import { createErrorMessage } from '../../utils/error-utils'
import AsyncButton from '../../components/AsyncButton'
import ExportDataButton from '../ExportDataButton/ExportDataButton';

/**
 * 
 * @param {Object} props 
 * @param {Object[]} props.frontlines 
 * @param {Object[]} props.users 
 * @param {boolean} props.loading 
 * @param {Function} props.saveFrontlinesForUser 
 * @param {Function} props.saveUser 
 * @param {Function} props.deleteUser 
 * @param {Function} props.saveUserRole 
 */
const UsersDisplay = (props) => {
  const { 
    frontlines,
    users,
    loading,
    saveFrontlinesForUser, 
    saveUser, 
    deleteUser,
    saveUserRole,
  } = props

  const [addingUser, setAddingUser] = useState(false)
  const [deletingUser, setDeletingUser] = useState(false)

  const [searchText, setSearchText] = useState('') // Table values search
  
  const frontlineNames = useMemo(() => {
    return frontlines.reduce((prev, curr) => {
      if (!prev[curr.hiddenId]) {
        prev[curr.hiddenId] = curr.country.name
      }

      return prev
    }, {})
  }, [frontlines])

  const searchInputRef = useRef()

  const addUserRow = (e) => {
    setAddingUser(true)
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0])
  };

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  function transformUsersToExcelFormat(data) {
    return data.map(({ frontlineCountries = [], ...rest }) => {
      return {
        ...rest,
        frontlineCountries: frontlineCountries
          .map(x => frontlineNames[x] || x)
          .join(', ')
      }
    })
  }

  function getExcelHeaders() {
    return [
      { key: 'email', label: 'Email' },
      { key: 'role', label: 'Role' },
      { key: 'frontlineCountries', label: 'Frontline countries' },
    ]
  }

  // Sets up the column search functionality. Searching by dataIndex.
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      // Select the searchInput when the search panel is opened.
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select()
          }
        }, 0);
      }
    }
  })

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      sorter: (a, b) => {
        if (a.email < b.email) return -1
        if (a.email > b.email) return 1
        return 0
      },
      ...getColumnSearchProps('email') // for search
    },
    {
      title: 'Frontlines',
      dataIndex: 'frontlineCountries',
      // className: 'col-hidden',
      key: 'frontlines',
      width: '40%',
      render: (frontlineCountries, record) => (
        <FrontlineTableCell
          frontlineNames={frontlineNames}
          frontlinesForUser={frontlineCountries}
          frontlineCountries={frontlines.map(x => {
            return {
              id: x.hiddenId,
              name: x.country.name
            }
          })}
          user={record}
          saveFrontlinesForUser={saveFrontlinesForUser}
        />
      )
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '15%',
      filters: [
        {
          text: 'manager',
          value: 'manager',
        },
        {
          text: 'RICK',
          value: 'rick',
        },
        {
          text: 'admin',
          value: 'admin',
        },
      ],
      onFilter: (value, record) => record.role === value,
      render: (role, record) => (
        <RoleTableCell 
          role={role}
          saveUserRole={saveUserRole}
          user={record}
        />
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => {
        return (
          <DeleteUserTableCell
            handleDelete={deleteUser}
            user={record}
          />
        )
      }
    }
  ]

  return (
    <Table
      className="UsersDisplay"
      dataSource={users.sort((a, b) => {
        if (a.email > b.email) return 1
          if (a.email < b.email) return -1
          return 0
      })}
      loading={loading}
      scroll={{ y: 350 }}
      columns={columns}
      rowKey={user => user.id}
      pagination={false}
      footer={() => (
        <>
          {
            addingUser ?
            <CreateUserRow
              closeView={() => setAddingUser(false)}
              saveUser={saveUser}              
            /> : 
            <Button className="add-user-button" type="primary" onClick={addUserRow}>Add a user</Button>
          }
          <ExportDataButton
            data={transformUsersToExcelFormat(users)}
            headers={getExcelHeaders()}
            filename="cd3d-admin-tool-users"
          />
        </>
      )}
    ></Table>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.role
 * @param {Function} props.saveUserRole
 */
function RoleTableCell(props) {
  const { user, role, saveUserRole } = props
  const [ saving, setSaving ] = useState(false)

  const newRole = role === 'manager' ? 'admin' : (role === 'admin' ? 'rick' : 'manager')
  // const newRole = role === 'manager' ? 'admin' : 'manager'

  async function handleOkClick() {
    if (saving) return
    setSaving(true)
    
    try {
      await saveUserRole(user, newRole)
      setSaving(false)
    } catch (err) {
      setSaving(false)

      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing the role',
        attemptedItem: 'user'
      })

      message.error(errorMessage, 5)

    }
  }

  return (
    <Popconfirm
      title={`Change the role of ${user.email} to ${newRole}?`}
      onConfirm={handleOkClick}
      okText="Yes"
      className="hover-link"
    >
      <Tag 
        className={`role-tag ${saving ? ' saving' : ''}`}  
        color={role === "admin" ? "red" : (role === "manager" ?"geekblue": "purple")}
      >{saving ? <SyncOutlined spin /> : role}</Tag>
    </Popconfirm>
  )
}

/**
 * 
 * @param {Object} props 
 * @param {Object[]} props.frontlineCountries 
 * @param {string} props.frontlineCountries.id 
 * @param {string} props.frontlineCountries.name
 * @param {string[]=} props.frontlinesForUser 
 * @param {Function} props.saveFrontlinesForUser 
 * @param {Object=} props.frontlineNames
 * @param {Object} props.user 
 */
const FrontlineTableCell = (props) => {
  const { 
    frontlineCountries, 
    frontlinesForUser = [], 
    saveFrontlinesForUser, 
    user,
    frontlineNames = {},
  } = props

  const buttonsRef = useRef()

  const [ adding, setAdding ] = useState(false)
  const [ frontlinesToSet, setFrontlinesToSet ] = useState(frontlinesForUser)

  const frontlinesToShow = useMemo(() => {
    if (!frontlinesForUser) return []
    return frontlinesForUser.sort()
      .map(id => frontlineNames[id.toLowerCase()])
      .filter(x => x)
  }, [frontlinesForUser, frontlineNames])


  useEffect(() => {
    if (adding) {
      if (buttonsRef && buttonsRef.current) {
        buttonsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, [adding])

  async function handleSaveClick() {
    try {
      await saveFrontlinesForUser(user, frontlinesToSet)
      setAdding(false)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Setting user frontlines',
        attemptedItem: 'user'
      })

      message.error(errorMessage, 5)
    }
  }

  function handleEditClick() {
    setAdding(true)
  }

  function handleCancelClick() {
    setFrontlinesToSet(frontlinesForUser)
    setAdding(false)
  }

  function onChange(values) {
    setFrontlinesToSet(values)
  }

  if (adding) {
    return (
      <div className="FrontlineTableCell editing">
        <Select
          mode="multiple"
          placeholder="Select frontlines"
          style={{ minWidth: "10rem" }}
          onChange={onChange}
          defaultValue={frontlinesForUser}
        >
          { frontlineCountries.sort((fl1, fl2) => {
            if (fl1.name > fl2.name) return 1
            if (fl1.name < fl2.name) return -1
            return 0
          }).map(fl => (
            <Select.Option key={fl.id} value={fl.id}>{fl.name}</Select.Option>
          ))}
        </Select>
        <div className="editor-buttons" ref={buttonsRef}>
            <AsyncButton 
              onConfirm={handleSaveClick} 
              type="primary" size="small">Save</AsyncButton>
            <Button onClick={handleCancelClick} size="small">Cancel</Button>
        </div>
      </div>
    )
  }

  if (user.role === 'admin') return (
    <div className="FrontlineTableCell">
      <div className="FrontlineTableCell__frontlines">
        All (admin)
      </div>
    </div>
  )

  if (user.role === 'rick') return (
    <div className="FrontlineTableCell">
      <div className="FrontlineTableCell__frontlines">
        None
      </div>
    </div>
  )
  // Display the owned frontlines in alphabetical order, separated by commas.
  return (
    <div className="FrontlineTableCell">
      <div className="FrontlineTableCell__frontlines">
        {frontlinesToShow.length === 0 ? "None" : frontlinesToShow.join(', ')}
      </div>
      <Button className="edit-button" type="link" onClick={handleEditClick}>Edit</Button>
    </div>
  )
}

/**
 * 
 * @param {Object} props 
 * @param {Function} props.closeView
 * @param {Function} props.saveUser
 */
function CreateUserRow(props) {
  const {
    closeView,
    saveUser
  } = props

  const [ newUserEmail, setNewUserEmail ] = useState('')
  const [ newUserRole, setNewUserRole ] = useState('manager')
  const [ newUserSaving, setNewUserSaving ] = useState(false)
  
  const emailInputRef = useRef()
  const roleSelectRef = useRef()

  async function handleSaveClick() {
    if (!isEmail(newUserEmail.trim())) {
      message.error('Invalid email address.')
      return
    }
    
    setNewUserSaving(true)
    
    try {
      await saveUser({
        email: newUserEmail,
        role: newUserRole,
      })

    closeView()
    setNewUserSaving(false)
      
    } catch (err) {
      setNewUserSaving(false)

      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the user',
        attemptedItem: 'user'
      })

      message.error(errorMessage, 5)
    }
  }

  function handleCancelClick() {
    closeView()
  }

  return (
    <div className="CreateUserRow">
      <div className="email-input-container">
        <Input
          className="email-input"
          placeholder="email"
          ref={emailInputRef}
          onPressEnter={() => {
            if (roleSelectRef && roleSelectRef.current) {
              roleSelectRef.current.focus()
            }
          }}
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}></Input>
      </div>
      <div className="role-select-container">
        <Select defaultValue="manager"
          ref={roleSelectRef}
          onChange={(value) => setNewUserRole(value)}>
          <Select.Option value="manager">manager</Select.Option>
          <Select.Option value="rick">RICK</Select.Option>
          <Select.Option value="admin">admin</Select.Option>
        </Select>
      </div>
      <div className="footer-buttons">
        <Button type="link" onClick={handleCancelClick}>Cancel</Button>
        <Button
          className="save-button"
          type="primary"
          onClick={handleSaveClick}
          loading={newUserSaving}
        >Save</Button>
      </div>
    </div>
  )
}

/**
 * @param {Object} props
 * @param {Function} props.handleDelete
 * @param {Object} props.user
 */
function DeleteUserTableCell(props) {
  const { handleDelete, user } = props
  
  const [ deleting, setDeleting ] = useState(false)

  async function handleDeleteClick() {
    try {
      setDeleting(true)
      await handleDelete(user)

      message.success(`Deleted the user ${user.email} succesfully`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Deleting the user',
        attemptedItem: 'user'
      })

      message.error(errorMessage, 5)
    } finally {
      setDeleting(false)
    }
  }


  return (
    <Popconfirm
      title={`Delete ${user.email}?`}
      onConfirm={() => handleDeleteClick()}
      okText="Yes"
    >
      <Button type="link" loading={deleting}>Delete</Button>
    </Popconfirm>
  )
}

export default UsersDisplay