import React, { useMemo } from 'react'
import { useContext } from 'react'
import { TranslationsContext } from '../../store/translations'
import ProductCard from './ProductCard'

const MONO_UPG_PRODUCTS = ['monospace-upg-gcn', 'add-on-deco']  

/**
 * 
 * @param {Object} props 
 * @param {Object[]} props.products 
 * @param {boolean=} props.minimized 
 * @param {boolean} props.managingGlobal 
 * @param {boolean} props.rollingBack 
 * @param {Object} props.frontline 
 * @param {boolean} props.admin 
 * @param {string} props.releaseId 
 * @param {Object} props.globalFrontline 
 * @param {string} props.previewURL 
 * @param {Function} props.toggleEnabledStatus 
 * @param {Function} props.toggleFullReplacementStatus
 */
function ProductList(props) {
  const { products, frontline, globalFrontline, admin, previewURL, toggleEnabledStatus, releaseId, toggleFullReplacementStatus, managingGlobal } = props

  const { getText } = useContext(TranslationsContext)

  const allPubliclyAvailableMonoUpg = frontline.products.filter(flProduct => {
    if (flProduct.productDisabled) return false
    // if (!flProduct.lastProductReleased) return false
    if (flProduct.isKoneOnly) return false
    return MONO_UPG_PRODUCTS.includes(flProduct.id)
  }).map(x => x.id)

  const showMonoUpgWarningFor = MONO_UPG_PRODUCTS
    .reduce((prev, curr) => {
      prev[curr] = {
        warnOnAdd: allPubliclyAvailableMonoUpg.length > 0, // If there already is an available mono upgrade, warn when adding another
        showWarning: allPubliclyAvailableMonoUpg.includes(curr) && allPubliclyAvailableMonoUpg.length > 1 // Display warning for any public mono upgrade when there are more than one available
      }
      return prev
    }, {})
  
  const [ activeProducts, minimizedProducts ] = useMemo(() => {
    if (!products) return
    const activeProducts = []
    const minimizedProducts = []

    products.forEach(product => {
      product.name = getText(`product-${product.id}`)
      if (managingGlobal) {
        activeProducts.push(product)
      } else {
        if (product.productDisabled) {
          minimizedProducts.push(product)
        } else {
          activeProducts.push(product)
        }
      }
    })

    return [activeProducts, minimizedProducts]
  }, [products])

  return (
    <div className="ProductList">
        <div className="ProductList-container">
        { activeProducts.map(product => {
          const showMonospaceUpgradeWarning = showMonoUpgWarningFor[product.id]?.showWarning
          
          const warnOnAdd = showMonoUpgWarningFor[product.id]?.warnOnAdd

          return (
            <ProductCard 
              product={product}
              key={product.id}
              frontline={frontline}
              globalFrontline={globalFrontline}
              admin={admin}
              showMonospaceUpgradeWarning={showMonospaceUpgradeWarning}
              warnOnAdd={warnOnAdd}
              releaseId={releaseId}
              previewURL={previewURL}
              toggleEnabledStatus={toggleEnabledStatus}
              toggleFullReplacementStatus={toggleFullReplacementStatus}
              managingGlobal={managingGlobal}
              minimized={false}
        />
          )
        })}
      </div>
      <div className="ProductList-container minimized-products">
        { minimizedProducts.map(product => {
          return (
            <ProductCard 
              key={product.id}
              product={product}
              frontline={frontline}
              globalFrontline={globalFrontline}
              releaseId={releaseId}
              admin={admin}
              warnOnAdd={showMonoUpgWarningFor[product.id]?.warnOnAdd}
              previewURL={previewURL}
              toggleEnabledStatus={toggleEnabledStatus}
              managingGlobal={managingGlobal}
              minimized={true}
        />
          )
        })}
      </div>
    </div>
  )
}

export default ProductList