import React from 'react'
import RickGraph from './sections/RickGraph';
import RickServers from './sections/RickServers';
import CadRequests from './sections/CadRequests';
import './RickDashboardPage.scss'

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function ImageGenDashboardPage(props) {
  const {
    className = ''
  } = props

  return (
    <div data-testid="RickDashboardPage" className={`RickDashboardPage page ${className}`}>
      <h1 className="page__title">RICK Service</h1>
      <RickGraph />
      <RickServers />
      <CadRequests />
    </div>
  )
}


export default ImageGenDashboardPage