const createProductService = (api) => {
  return {
    release(hiddenId, productId, releaseId) {
      return api.post(`/public/package/${hiddenId}/${productId}`, { releaseId })
    },
  }
}


export default createProductService;
