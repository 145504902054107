import { message, Radio, Select, DatePicker, ConfigProvider, Switch } from 'antd'
import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment';
import { Card } from 'antd'
import { Bar } from 'react-chartjs-2'
import 'moment/locale/en-gb'
import locale from 'antd/lib/locale/en_GB'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import countryMapping from '../../constants/ktocCountryMapping.json'
import productMapping from '../../constants/productMapping.json'
import './UnitChart.scss'

const { RangePicker } = DatePicker;
const { Option } = Select


function stringToRGB(str = '') {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.7)`;
}

/////////////////////////////////////////
// constant options part for the chart
const options = {
  animation: {
    duration: 1000,
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
      }
    }
  },
  scales: {
    xAxes: [{
      stacked: true,
      gridLines: { display: false },
    }],
    yAxes: [{
      stacked: true,
      ticks: {
        callback: function (value) { return value; },
      },
    }],
  },
  legend: { display: false }
}
//
/////////////////////////////////////////

const selectPlaceholders = {
  date: 'Select day',
  week: 'Select week',
  month: 'Select month',
  year: 'Select year',
  range: 'Select time range',
}

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function UnitChart(props) {
  const {
    className = '',
    loading,
    tableEntries: origTableEntries,
    availableProducts = [],
    setExcelExportData,
  } = props

  const [viewType, setViewType] = useState('country') // ['time','country']
  const [pickerType, setPickerType] = useState('date')

  const [stacked, setStacked] = useState(false)

  const [filters, setFilters] = useState({
    timePeriod: null,
    country: null,
    productId: null
  })

  const tableEntries = useMemo(() => {
    if (!origTableEntries) return
    return origTableEntries
      .filter(item => !!item.destinationCountry && !!item.productName)
      .map(item => {
        let country = item.destinationCountry.toUpperCase()
      
        if (country === 'UK') {
          country = 'GB'
        }

        if (country === 'FIN') {
          country = 'FI'
        }
  
        return {
          ...item,
          destinationCountry: country
        }
    })
  }, [origTableEntries])

  const countriesAvailable = useMemo(() => {
    if (!tableEntries) return

    const countries = []
    tableEntries.forEach(item => {
      if (item?.destinationCountry) {
        let itemCountry = item.destinationCountry

        if (countries.indexOf(itemCountry) === -1) {
          countries.push(itemCountry)
        }
      }
    })

    // sort the country code list by the actual name of the country
    const orderedCountryNames = Object.keys(countryMapping).map(item => countryMapping[item]).sort()
    const transposedCountryMapping = Object.entries(countryMapping).map( ([key,value]) => ({key,value}) )
    let orderedCountriesPre = []
    countries.forEach(item => {
      const itemCountry = transposedCountryMapping.find(code => code.key === item)
      if(itemCountry) {
        const index = orderedCountryNames.findIndex(val => val === itemCountry.value)
        orderedCountriesPre[index] = item
      }
    })
    // remove empty slots in the array
    const orderedCountries = orderedCountriesPre.filter(val => val)

    // add country codes not defined in the mapping to the end of the country code list
    countries.forEach(item => {
      if(orderedCountries.indexOf(item) === -1) {
        orderedCountries.push(item)
      }
    })

    return orderedCountries
  })

  function findTimeIndex(labels, time) {
    for (var i = labels.length - 1; i > -1; i--) {
      if (time >= labels[i]) {
        return i
      }
    }
  }

  function getWeekNumber(date) {

    const fullYear = date.getFullYear()
    const januaryFirst = new Date(fullYear, 0, 1)
    const numberOfDays = Math.floor((date - januaryFirst) / (24 * 60 * 60 * 1000))
    const isLeapYear = (fullYear % 4 === 0 && fullYear % 400 !== 0) ? true : false
    const weekCorrection = isLeapYear ? (januaryFirst.getDay() >= 2 ? 1 : 0) : (januaryFirst.getDay() >= 3 ? 1 : 0)
    const weekNumber = (Math.ceil((date.getDay() + 1 + numberOfDays) / 7) - weekCorrection)
    const lastWeek = isLeapYear ? (januaryFirst.getDay() === 2 ? 53 : 52) : (januaryFirst.getDay() === 3 ? 53 : 52)

    return (weekNumber > 0 ? weekNumber : lastWeek)
  }

  const getFirstDayOfTheWeek = (date) => {
    const januaryFirst = new Date(date.getFullYear(), 0, 1)
    const weekNumber = getWeekNumber(date)

  }

  const getLabels = (data) => {
    const labels = []
    if (viewType === 'country') {
      if (!filters.country) {
        data.forEach(item => {
          if (item?.country && item.country !== '' && labels.indexOf(item.country) === -1) {
            labels.push(item.country.toUpperCase())
          }
        })
      } else {
        data.forEach(item => {
          if (item?.product && item.product !== '' && labels.indexOf(item.product) === -1) {
            labels.push(item.product)
          }
        })
      }

    } else {
      if (!filters.timePeriod) {
        data.forEach(item => {
          if (item?.time) {
            const month = new Date(item.time).getMonth()
            const year = new Date(item.time).getFullYear()
            const label = new Date(year, month)
            if (labels.indexOf(Date.parse(label)) === -1) {
              labels.push(Date.parse(label))
            }
          }
        })
      } else {

        if (pickerType === 'date') {
          for (var i = 0; i < 24; i++) {
            labels.push(filters.timePeriod.from + i * (60 * 60 * 1000))
          }

        } else if (pickerType === 'week' || pickerType === 'month' || pickerType === 'range') {
          var labelTime = filters.timePeriod.from
          var index = 0
          while (labelTime < filters.timePeriod.to) {
            labelTime = filters.timePeriod.from + index * (24 * 60 * 60 * 1000)
            labels.push(labelTime)
            index++
          }

        } else {
          const year = new Date(filters.timePeriod.from).getFullYear()

          for (var i = 0; i < 12; i++) {
            const labelTime = new Date(year, i)
            labels.push(labelTime)
          }

        }

      }

    }
    return labels
  }

  const getDatasets = (labels, data) => {

    if (viewType === 'country') {

      // no country is selected
      if (!filters.country) {

        // not stacked
        if (!stacked) {
          const values = []
          data.forEach(item => {
            if (item?.country && item.country !== '') {
              const cIndex = labels.indexOf(item.country)
              if (values[cIndex]) {
                values[cIndex]++
              } else {
                values[cIndex] = 1
              }
            }
          })
          return [{
            label: 'Total',
            data: values,
            backgroundColor: "rgba(55, 160, 225, 0.7)",
            hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'

          }]

        } else {

          // stacked
          const values = []
          const stackLabels = []
          data.forEach(item => {
            if (item?.country && item.country !== '') {
              const cIndex = labels.indexOf(item.country)
              if (values[cIndex]) {
                if (values[cIndex][item.product]) {
                  values[cIndex][item.product]++
                } else {
                  values[cIndex][item.product] = 1
                }
              } else {
                values[cIndex] = {}
                values[cIndex][item.product] = 1
              }

              if (stackLabels.indexOf(item.product) === -1) {
                stackLabels.push(item.product)
              }

            }
          })
          
          return stackLabels.map(item => {
            return {
              label: (productMapping[item] ? productMapping[item] : item),
              data: values.map(val => {
                if (val[item]) {
                  return val[item]
                }
                return 0
              }),
              backgroundColor: stringToRGB(item),
              hoverBackgroundColor: stringToRGB(item),
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'
            }
          })

        }

      }

      // country selected

      if (!stacked) {
        // not stacked
        const values = []
        data.forEach(item => {
          if (item?.product && item.product !== '') {
            const pIndex = labels.indexOf(item.product)
            if (values[pIndex]) {
              values[pIndex]++
            } else {
              values[pIndex] = 1
            }
          }
        })

        return [{
          label: 'Total',
          data: values,
          backgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'

        }]

      } else {
        // stacked
        const values = labels.map(item => {
          return {
            success: 0,
            partial: 0,
            fail: 0,
          }
        })
        data.forEach(item => {
          if (item?.product && item.product !== '') {
            const pIndex = labels.indexOf(item.product)
            if (item.statusStr === 'success') {
              values[pIndex].success++
            } else if (item.statusStr === 'partial') {
              values[pIndex].partial++
            } else {
              values[pIndex].fail++
            }
          }
        })

        return [
          {
            label: 'Success',
            data: values.map(item => item.success),
            backgroundColor: "rgba(14, 164, 14, 0.7)",
            hoverBackgroundColor: "rgba(14, 164, 14, 0.7)",
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'

          },
          {
            label: 'Partial Success',
            data: values.map(item => item.partial),
            backgroundColor: "rgba(245, 164, 66, 0.7)",
            hoverBackgroundColor: "rgba(245, 164, 66, 0.7)",
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'

          },
          {
            label: 'Fail',
            data: values.map(item => item.fail),
            backgroundColor: "rgba(245, 66, 66, 0.7)",
            hoverBackgroundColor: "rgba(245, 66, 66, 0.7)",
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'

          },

        ]
      }


    } else { // by time

      if (!stacked) {

        const values = []

        data.forEach(item => {
          if (item?.time) {
            const tIndex = findTimeIndex(labels, item.time)
            if (values[tIndex]) {
              values[tIndex]++
            } else {
              values[tIndex] = 1
            }
          }
        })

        for (var i = 0; i < labels.length; i++) {
          if (!values[i]) {
            values[i] = 0
          }
        }
        return [{
          label: 'Total',
          data: values,
          backgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'

        }]

      } else {

        if (!filters.country) {
          // stacked
          const values = labels.map(item => { return {} })
          const stackLabels = []
          data.forEach(item => {
            if (item?.time) {
              const cName = item.country ? item.country : 'Undefined'
              const tIndex = findTimeIndex(labels, item.time)
              if (values[tIndex][cName]) {
                values[tIndex][cName]++
              } else {
                values[tIndex][cName] = 1
              }

              if (stackLabels.indexOf(cName) === -1) {
                stackLabels.push(cName)
              }

            }
          })


          return stackLabels.map(item => {
            const backgroundColorStr = countryMapping[item] || item
            return {
              label: countryMapping[item] ? countryMapping[item] : item,
              data: values.map(val => {
                if (val[item]) {
                  return val[item]
                }
                return 0
              }),
              backgroundColor: stringToRGB(backgroundColorStr),
              hoverBackgroundColor: stringToRGB(backgroundColorStr),
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'
            }
          })

        } else if (filters.country && !filters.productId) {

          const values = labels.map(item => { return {} })
          const stackLabels = []
          data.forEach(item => {
            if (item?.time) {
              const cName = item.product ? item.product : 'Undefined'
              const tIndex = findTimeIndex(labels, item.time)
              if (values[tIndex][cName]) {
                values[tIndex][cName]++
              } else {
                values[tIndex][cName] = 1
              }

              if (stackLabels.indexOf(cName) === -1) {
                stackLabels.push(cName)
              }

            }
          })

          return stackLabels.map(item => {

            return {
              label: (productMapping[item] ? productMapping[item] : item),
              data: values.map(val => {
                if (val[item]) {
                  return val[item]
                }
                return 0
              }),
              backgroundColor: stringToRGB(item),
              hoverBackgroundColor: stringToRGB(item),
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'
            }
          })

        } else {

          const values = labels.map(item => {
            return {
              success: 0,
              partial: 0,
              fail: 0,
            }
          })
          data.forEach(item => {
            if (item?.time) {
              const tIndex = findTimeIndex(labels, item.time)
              if (item.statusStr === 'success') {
                values[tIndex].success++
              } else if (item.statusStr === 'partial') {
                values[tIndex].partial++
              } else {
                values[tIndex].fail++
              }
            }
          })

          return [
            {
              label: 'Success',
              data: values.map(item => item.success),
              backgroundColor: "rgba(14, 164, 14, 0.7)",
              hoverBackgroundColor: "rgba(14, 164, 14, 0.7)",
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'

            },
            {
              label: 'Partial Success',
              data: values.map(item => item.partial),
              backgroundColor: "rgba(245, 164, 66, 0.7)",
              hoverBackgroundColor: "rgba(245, 164, 66, 0.7)",
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'

            },
            {
              label: 'Fail',
              data: values.map(item => item.fail),
              backgroundColor: "rgba(245, 66, 66, 0.7)",
              hoverBackgroundColor: "rgba(245, 66, 66, 0.7)",
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'

            },

          ]

        }

      }
    }

  }

  const getChartLabels = (labels) => {
    if (viewType === 'country') {
      if (!filters.country) {
        return labels.map(item => { return (countryMapping[item] ? countryMapping[item] : item) })
      }
      return labels.map(item => { return (productMapping[item] ? productMapping[item] : item) })
    } else {
      if (!filters.timePeriod) {
        return labels.map(item => {
          const itemDate = new Date(item)
          const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(itemDate)
          const year = itemDate.getFullYear()
          return month + ' ' + year
        })
      } else {

        return labels.map(item => {
          const itemDate = new Date(item)
          const monthText = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(itemDate)
          const month = itemDate.getMonth()
          const year = itemDate.getFullYear()
          const day = itemDate.getDate()
          const hour = itemDate.getHours()
          if (pickerType === 'date') {
            return hour + ':00 - ' + (hour + 1) + ':00'
          } else if (pickerType === 'week' || pickerType === 'month' || pickerType === 'range') {
            return day + '.' + (month + 1) + '.' + year
          }
          return monthText + ' ' + year
        })


      }

    }
  }

  const onDateChange = (dates) => {

    if (!dates) {
      setFilters({ ...filters, timePeriod: null })
    } else {
      var d0, d1
      if (pickerType === 'range') {
        d0 = new Date(dates[0]._d)
        d1 = new Date(dates[1]._d)
      } else {
        const valueDate = new Date(dates._d)
        if (pickerType === 'date') {
          d0 = valueDate
          d1 = valueDate
        } else if (pickerType === 'week') {
          const weekStartCorrection = (valueDate.getDay() === 0 ? 6 : valueDate.getDay())
          const weekEndCorrection = (valueDate.getDay() === 0 ? 0 : (7 - valueDate.getDay()))
          d0 = new Date(valueDate.getFullYear(), valueDate.getMonth(), (valueDate.getDate() - weekStartCorrection))
          d1 = new Date(valueDate.getFullYear(), valueDate.getMonth(), (valueDate.getDate() + weekEndCorrection))
        } else if (pickerType === 'month') {
          d0 = new Date(valueDate.getFullYear(), valueDate.getMonth(), 1)
          d1 = new Date(valueDate.getFullYear(), valueDate.getMonth() + 1, 0)
        } else if (pickerType === 'year') {
          d0 = new Date(valueDate.getFullYear(), 0, 1)
          d1 = new Date(valueDate.getFullYear(), 11, 31)
        }
      }
      const startTime = new Date(d0.getFullYear(), d0.getMonth(), d0.getDate())
      const endTime = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), 23, 59, 59, 999)
      setFilters({
        ...filters,
        timePeriod: {
          from: (Date.parse(startTime)),
          to: Date.parse(endTime),
          rawDates: dates
        }
      })
    }

  }

  const filteredData = useMemo(() => {
    if (!tableEntries) return
    return tableEntries.filter(item => {
      // item must fall within the selected time period
      if (filters.timePeriod) {
        if (!(Date.parse(item.Timestamp) >= filters.timePeriod.from && Date.parse(item.Timestamp) <= filters.timePeriod.to)) return false
      }

      if (filters.country) {
        if (item.destinationCountry !== filters.country) return false
      }

      if (filters.productId) {
        if (item.productName !== filters.productId) return false
      }

      return true
    }).map(item => {
      let itemCountry = item.destinationCountry?.toUpperCase()
      if (itemCountry === 'UK') {
        itemCountry = 'GB'
      }

      const succesful = item.status === 200 || item.status === 202

      return {
        ...item,
        time: Date.parse(item.Timestamp),
        product: item.productName,
        country: itemCountry,
        statusStr: (succesful && !item.unsupportedComponents) ? 'success' : (succesful ? 'partial' : 'fail'),
      }
    }).sort(function (a, b) { return a.time - b.time })
  }, [tableEntries, filters])

  const graphData = useMemo(() => {
    if (!filteredData) return

    const labels = getLabels(filteredData)
    const datasets = getDatasets(labels, filteredData)

    return {
      labels: getChartLabels(labels),
      datasets: datasets
    }
  }, [filteredData, viewType, stacked])

  useEffect(() => {
    setExcelExportData(filteredData)
  }, [filteredData])

  const PickerWithType = ({ type }) => {
    if (type === 'date') return <DatePicker onChange={onDateChange} defaultValue={(filters.timePeriod) ? moment(filters.timePeriod.rawDates) : null} placeholder={selectPlaceholders[type]} className="fullWidth" />
    if (type === 'range') return (<RangePicker
      picker="date"
      defaultValue={(filters.timePeriod && Array.isArray(filters.timePeriod.rawDates)) ? filters.timePeriod.rawDates.map((date) => moment(date._d)) : null}
      onChange={onDateChange}
      className="fullWidth"
    />)
    return <DatePicker picker={type} onChange={onDateChange} defaultValue={(filters.timePeriod) ? moment(filters.timePeriod.rawDates) : null} placeholder={selectPlaceholders[type]} className="fullWidth" />

  }

  return (
    <div className={`UnitChart ${className}`}>
      <LoadingSpinner loading={loading}>
        <div className="chartAndFilters">
          <div className="chart">
            <Bar data={graphData} options={options} />
          </div>
          <div className="filters">
            <Card>
              <div className="filter">
                <div className="filterHeader">Approach by</div>
                <div className="filterItem">
                  <Radio.Group onChange={e => setViewType(e.target.value)} value={viewType} optionType="button" buttonStyle="solid" className="fullWidth">
                    <Radio.Button value={'country'} className="halfWidth">Country</Radio.Button>
                    <Radio.Button value={'time'} className="halfWidth">Time</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              <div className="filter">
                <div className="filterHeader">Separate the values</div>
                <div className="filterItem">
                  <Switch checked={stacked} onChange={value => setStacked(value)} />
                </div>
              </div>
              <div className="filter">
                <div className="filterHeader">Select the time period</div>
                <div className="filterItem">
                  <Select value={pickerType} onChange={setPickerType} className="fullWidth">
                    <Option value="date">Day</Option>
                    <Option value="week">Week</Option>
                    <Option value="month">Month</Option>
                    <Option value="year">Year</Option>
                    <Option value="range">Time range</Option>
                  </Select>
                  <ConfigProvider locale={locale}>
                    <PickerWithType type={pickerType} />
                  </ConfigProvider>
                </div>
              </div>
              {viewType === 'country' &&
                <>
                  {countriesAvailable &&
                    <div className="filter">
                      <div className="filterHeader">Select country</div>
                      <div className="filterItem">
                        <Select defaultValue={filters.country} onChange={value => setFilters({ ...filters, country: value })} className="fullWidth" >
                          <Option value={null}>All</Option>
                          {countriesAvailable.map(item => {
                            return (
                              <Option key={item} value={item}>{(countryMapping[item] ? countryMapping[item] : item)}</Option>
                            )
                          })}
                        </Select>
                      </div>
                    </div>
                  }
                </>
              }
              {(viewType === 'time' && countriesAvailable) &&
                <>
                  <div className="filter">
                    <div className="filterHeader">Select country</div>
                    <div className="filterItem">
                      <Select defaultValue={filters.country} onChange={value => setFilters({ ...filters, country: value })} className="fullWidth" >
                        <Option value={null}>All</Option>
                        {countriesAvailable.map(item => {
                          return (
                            <Option key={item} value={item}>{(countryMapping[item] ? countryMapping[item] : item)}</Option>
                          )
                        })}
                      </Select>
                    </div>
                  </div>

                  <div className="filter">
                    <div className="filterHeader">Select product</div>
                    <div className="filterItem">
                      <Select defaultValue={filters.productId} onChange={value => setFilters({ ...filters, productId: value })} className="fullWidth" dropdownStyle={{ minWidth: '250px' }}>
                        <Option value={null}>All</Option>
                        {availableProducts.map(item => {
                          return (
                            <Option key={item} value={item}>{(productMapping[item] ? productMapping[item] : item)}</Option>
                          )
                        })}
                      </Select>
                    </div>
                  </div>


                </>
              }
            </Card>
          </div>
        </div>
      </LoadingSpinner>
    </div>
  )
}

export default UnitChart