import { Button, Form, Input, List, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import { useFrontlinesContext } from '../../store/frontlines/FrontlinesProvider'
import { useUsersContext } from '../../store/users/UsersProvider'
import { createErrorMessage } from '../../utils/error-utils'
import AsyncButton from '../AsyncButton'
import CustomSwitch from '../CustomSwitch'
import './UserSelection.scss'

/**
 *
 * @param {Object} props
 * @param {Object} props.frontline
 */
const UserSelection = (props) => {
  const { frontline  } = props

  const frontlinesCtx = useFrontlinesContext()
  const usersCtx = useUsersContext()

  const [ users, setUsers ] = useState(null)
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    if (!frontline) return

    frontlinesCtx.loadUsersForFrontline(frontline).then(usersResult => {
      setUsers(usersResult)
      setLoading(false)
    })
  }, [frontline])

  async function handleAddUser(user) {
    const result = await usersCtx.addFrontlineForUser(user, frontline)

    setUsers(old => [...old, result])
  }

  async function handleFeedbackSwitchChange(user, checked) {
    try {
      const result = await usersCtx.saveFeedbackSubscribtionState(user, frontline.hiddenId, checked)
      setUsers(old => {
        const userIndex = old.findIndex(u => u.id === user.id)
        const newUsers = [...old]
        newUsers[userIndex] = result
        return newUsers
      })
      message.success(`State changed successfully for ${user.email}`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Changing the state',
        attemptedItem: 'user',
      })

      message.error(errorMessage)
    }
  }

  const handleRemove = async (user) => {
    try {
      await usersCtx.removeFrontlineFromUser(user, frontline)
      message.success(`Removed the ${user.email} from the frontline.`)

      setUsers(old => {
        return old.filter(x => x.email !== user.email)
      })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Removing the user',
        attemptedItem: 'frontline',
      })

      message.error(errorMessage)
    }
  }

  return (
    <div className="UserSelection">
      <List
        locale={{ emptyText: 'No managers added for this frontline.' }}
        // Filter out admins as they can manage every frontline.
        loading={loading}
        dataSource={users || []}
        footer={
          <ManagersListFooter
            users={users}
            frontline={frontline}
            addFrontlineForUser={handleAddUser}
          />
        }
        renderItem={(user) => (
          <List.Item className="user-selection-list-item">
            <div className="user-email">{user.email}</div>
            <div className="buttons-container">
              <FeedbackSwitch user={user} handleChange={handleFeedbackSwitchChange} frontlineId={frontline.hiddenId} className="switch-container" />
              <AsyncButton onConfirm={() => handleRemove(user)}>Remove</AsyncButton>
            </div>
          </List.Item>
        )}
      ></List>
    </div>
  )
}

function FeedbackSwitch(props) {
  const { className = '', user, frontlineId, handleChange } = props

  const isSubscribed = useMemo(() => {
    return !user.unsubsribedFromFeedbackForCountries?.includes(frontlineId.toLowerCase())
  }, [user, frontlineId])

  return (
    <div className={`FeedbackSwitch ${className}`}>
      <span className="switch-label">Feedback emails:</span>
      <CustomSwitch
        onChange={(checked) => handleChange(user, checked)}
        checked={isSubscribed}
      />
    </div>
  )
}

const ManagersListFooter = ({ addFrontlineForUser, users }) => {
  const [adding, setAdding] = useState(false)
  const [emailToAdd, setEmailToAdd] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    const email = emailToAdd.trim().toLowerCase()
    if (!isEmail(email)) {
      message.error('Invalid email.')
      return
    }

    if (users.findIndex((u) => u.email === email) !== -1) {
      message.error('User has already been added.')
      return
    }

    setLoading(true)
    try {
      await addFrontlineForUser({ email })
      setLoading(false)
      setEmailToAdd('')
      // setAdding(false)
    } catch (err) {
      // TODO err message
      console.error(err)
      setLoading(false)
    }
  }

  if (adding) {
    return (
      <Form onFinish={handleSubmit} layout="inline">
        <Form.Item rules={[{ required: true, message: 'Email is required' }]}>
          <Input
            placeholder="Email"
            value={emailToAdd}
            onChange={(e) => setEmailToAdd(e.target.value)}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            onClick={() => {
              setAdding(false)
              setEmailToAdd('')
            }}
          >
            Cancel
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    )
  }

  return <Button onClick={() => setAdding(true)}>Add managers</Button>
}

export default UserSelection
