import React, { useState, useEffect, useContext, useMemo, useReducer } from 'react'
import { Button, message, Card, Spin, Timeline, Tooltip } from 'antd'
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons'
import AsyncButton from '../../components/AsyncButton'
import './UpdateAllPage.scss'
import { Link } from 'react-router-dom'
import { createErrorMessage } from '../../utils/error-utils'
import useInterval from '../../utils/useInterval'
import { FrontlinesContext } from '../../store/frontlines'
import { ConfigContext } from '../../store/config'
import { TranslationsContext } from '../../store/translations'
import { APIContext } from '../../store/api'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'


function useLongPoller(baseApi) {
  const api = useContext(APIContext)
  const [currentStatus, setCurrentStatus] = useState()
  const [initializing, setInitializing] = useState(false)
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    api.get(`${baseApi}/status/update_all`).then(result => {
      setCurrentStatus(result)

      if (result.inProgress) {
        setUpdating(true)
        poll()
      }
    })
  }, [])

  async function poll(retries = 0) {
    try {
      const result = await api.get(`${baseApi}/step/update_all`)
      setCurrentStatus(result)

      if (!result.inProgress) {
        setUpdating(false)
        return
      }

      await poll()
    } catch (err) {
      if (err.status < 500 || retries >= 10) {
        console.error(err)
        setUpdating(false)
      } else {
        // Wait for 5 seconds before retrying
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 5000);
        })
        
        return poll(retries + 1) // retry ten times in case of timeout
      }
    }
  }

  async function startPolling() {
    try {
      const result = await api.post(`${baseApi}/begin/update_all`)
      setUpdating(true)
      setCurrentStatus(result)
      setInitializing(false)
      poll()
    } catch (err) {
      setInitializing(false)
      throw err
    }
  }

  return {
    startPolling,
    state: {
      updating, initializing, currentStatus
    }
  }
}

function UpdateAllPage({ history }) {

  const { globalFrontline, loadFrontline, publishTranslation } = useContext(FrontlinesContext)
  const config = useContext(ConfigContext)
  const { getText } = useContext(TranslationsContext)


  const {
    startPolling: startPreviewUpdate,
    state: previewState,
  } = useLongPoller('/preview/package')

  const {
    startPolling: startProductionUpdate,
    state: productionState,
  } = useLongPoller('/public/package')

  const updating = productionState.updating || previewState.updating

  const showFailedProducts = useMemo(() => {
    if (globalFrontline.failedProducts && globalFrontline.failedProducts.length > 0) return true

    if (globalFrontline.failedPackages && globalFrontline.failedPackages.length > 0) return true

    return false
  }, [globalFrontline])

  const [loading, setLoading] = useState(true)

  async function handleLanguagePublish() {
    try {
      await publishTranslation('en', 'global')
      message.success("Publishing succesful!")
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Publishing',
        attemptedItem: 'settings'
      })

      message.error(errorMessage, 5)
    }
  }

  useEffect(() => {
    loadFrontline({ country: 'global' }).then(() => {
      setLoading(false)
    })
  }, [])

  async function updatePublishedProducts() {
    try {
      await startProductionUpdate()
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Updating',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }


  async function updatePreviewProducts() {
    try {
      await startPreviewUpdate()
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Updating',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  if (loading) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  return (
    <div className="UpdateAllPage page">
      <Link style={{ display: 'block' }} to="/frontline/global">Back</Link>
      <h1 className="page__title">Update all products</h1>
      <div className="block">
        <Card>
          <p>Here you can update all the products to match the latest Car Designer
          base data structure. Updating is only required once after each release of the Admin Tool.</p>
          <p>⚠️ Note that the updating can take several minutes, and during this time some functionality of the Admin Tool is restricted.</p>
          <div className="buttons-container">
            <AsyncButton
              onConfirm={updatePreviewProducts}
              confirmText="Are you sure you want to update all preview products?"
              disabled={updating}
            >Update preview products</AsyncButton>
            <AsyncButton
              disabled={updating}
              disabledTooltipText="Preview packages are out of date. They should be updated first."
              onConfirm={updatePublishedProducts}
              confirmText="Are you sure you want to update all released products?"
            >Update released products</AsyncButton>
            <AsyncButton
              className="publish-global-button"
              disabled={updating}
              onConfirm={() => handleLanguagePublish()}
              tooltipText="Publishes the default English translation to the Car Designer.">
              Update the global translation
            </AsyncButton>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <UpdateProgressContainer state={previewState} title="Preview update status" />
              <UpdateProgressContainer state={productionState} title="Production update status" />
            </div>
          </div>
        </Card>
      </div>
      { showFailedProducts &&
        <div className="block">
          <Card>
            {
              globalFrontline.failedPackages && globalFrontline.failedPackages.length > 0 &&
              (
                <div>
                  <h3>Failed preview products</h3>
                  <FailedProducts getText={getText} products={globalFrontline.failedPackages} />
                </div>
              )
            }
            {
              globalFrontline.failedProducts && globalFrontline.failedProducts.length > 0 &&
              (
                <div>
                  <h3>Failed public products</h3>
                  <FailedProducts getText={getText} products={globalFrontline.failedProducts} />
                </div>
              )
            }
          </Card>
        </div>
      }
    </div>
  )
}

function formatSeconds(time) {
  if (time < 60) return `${time}s`

  const minutes = Math.floor(time / 60)
  const seconds = time % 60

  let result = `${minutes}min`

  if (seconds) {
    result += ` ${seconds}s`
  }
  return result
}

function UpdateProgressContainer(props) {
  const { 
    className = '',
    title,
    state = {}
   } = props

  const { initializing, updating, currentStatus } = state

  const [ totalTime, setTotalTime ] = useState(0)

  const totalTimeToDisplay = currentStatus && currentStatus.totalTimeTaken 
    ? Math.round(currentStatus.totalTimeTaken / 1000) : totalTime 

  useInterval(() => {
    if (updating) {
      setTotalTime(prev => prev + 1)
    }
  }, 1000)

  useEffect(() => {
    if (!updating) {
      setTotalTime(0)
    }
  }, [updating])

  if (!currentStatus || currentStatus.steps.length === 0) return null

  return (
    <div className={`UpdateProgressContainer ${className}`}>
      <LoadingSpinner loading={initializing}>
        <h2>{title} {totalTimeToDisplay > 0 && <span>({formatSeconds(totalTimeToDisplay)})</span>}</h2>
        <UpdateProgress loading={initializing} updating={updating} status={currentStatus} />
        {currentStatus &&
          <div>
            {currentStatus.lastUpdated &&
              <p>
                Last updated: {currentStatus.lastUpdated}
              </p>
            }
          </div>
        }
      </LoadingSpinner>
    </div>
  )
}

function UpdateProgress(props) {
  const { className = '', status, loading, updating } = props
  if (loading) {
    return null
  }

  return (
    <Timeline className={`UpdateProgress ${className}`}>
      { status && status.steps.map((step, i) => {        
        return <ProgressItem
          key={i}
          description={step.description}
          timeTaken={step.timeTaken}
          error={step.error}
          result={step.result}
          status={updating && status.currentStep === i ? 'loading' : step.status
          }
        />
      })}
    </Timeline>
  )
}

function ProgressItem(props) {
  const { className = '', status, result, error, description, timeTaken, ...rest } = props
  const [time, setTime] = useState(0)
  const timeToDisplay = timeTaken ? Math.round(timeTaken / 1000) : time

  useInterval(() => {
    if (status === 'loading') {
      setTime(prev => prev + 1)
    }
  }, 1000)

  useEffect(() => {
    if (status === 'loading') return
    setTime(0)
  }, [status])

  let Dot

  if (status === 'loading') {
    Dot = <LoadingOutlined />
  } else if (status === 'success') {
    if (result && result.failedProducts) {
      const tooltipText = result.failedProducts.map(product =>
        <div 
          key={product.frontlineName + product.productId} className="failed-frontline"> 
          <p className="failed-frontline-info">
            {product.frontlineName} {product.productId} -
          </p>
          <p className="failed-frontline-reason">{product.reason}</p>
        </div>
        )
  
      Dot = <Tooltip placement="bottom" title={tooltipText}>
        <ExclamationCircleTwoTone twoToneColor="#da813c" />
      </Tooltip>
    } else {
      Dot = <CheckCircleTwoTone twoToneColor="#52c41a" />
    }
  } else if (status === 'fail') {
    Dot = <Tooltip title={typeof error === 'string' ? error : null}>
        <CloseCircleTwoTone twoToneColor="red" />
      </Tooltip>
  } else {
    Dot = <CheckCircleTwoTone twoToneColor="#A8A8A8" />
  }

  return (
    <Timeline.Item dot={Dot} className={`ProgressItem ${className}`} {...rest}>
      <span className="ProgressItem-description">{description}</span>
      { typeof result === 'string' &&
        <span>{result}</span>
      }
      { timeToDisplay > 0 &&
        <span className="ProgressItem-timer">{formatSeconds(timeToDisplay)}</span>
      }
    </Timeline.Item>
  )
}

function FailedProducts({ products, getText }) {
  return (
    <div className="FailedProducts">
      {
        products.map(product => {
          return (
            <Card className="failed-product" key={product.productId} title={getText(`product-${product.productId}`)}>
              { product.failedProducts.map(fl => {
                return <div key={fl.frontlineId}>
                  <p>{fl.frontlineName}</p>
                  <p>Reason: {fl.reason}</p>
                </div>
              })}
            </Card>
          )
        }
        )
      }
    </div>
  )
}

export default UpdateAllPage