import React, { useState, useEffect, useMemo, useContext, useReducer } from 'react'
import { Card, Checkbox, Collapse, Tabs } from 'antd'
import CheckboxGroup, { getCheckboxStyle, calculateCheckboxContainerHeight, ReverseCheckbox } from '../../../../components/CheckboxGroup'
import { useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'

const { TabPane } = Tabs

const COP_MODELS = 'copModels'
const AMOUNT_OF_DEVICES = 'amountOfDevices'
const COP_POSITIONS = 'positions'
const HL_POSITIONS = 'hlPositions'
const HI_POSITIONS = 'hiPositions'
const LCS_POSITIONS = 'lcsPositions'
const DOP_POSITIONS = 'dopPositions'
const DIN_POSITIONS = 'dinPositions'
const EID_POSITIONS = 'eidPositions'
const JAMB_POSITIONS = 'jambPositions'
const HORIZONTAL_COPS = 'horizontalCops'

const Signalization = ({ initRestrictions: restrictions = {},
  setRestrictions, disableSaving, product, managingGlobal, title }) => {
  const copModelsData = product.componentsData.signalization.copModels
  const { landingComponentPositions, horizontalCops = [] } = product.componentsData.signalization

  const { handleError, errors } = useComponentSelectionError(disableSaving)
  // const [copTypesErrorMessages, setCopTypesErrorMessages] = useState({})

  function setSignalizationRestrictions(newRestrictions, name) {
    const possibleNames = [
      COP_MODELS,
      AMOUNT_OF_DEVICES,
      COP_POSITIONS,
      HL_POSITIONS,
      HI_POSITIONS,
      LCS_POSITIONS,
      DOP_POSITIONS,
      DIN_POSITIONS,
      EID_POSITIONS,
      JAMB_POSITIONS,
      HORIZONTAL_COPS,
    ]

    if (!possibleNames.includes(name)) {
      throw new Error('Invalid restriction name')
    }

    setRestrictions(prev => {
      return {
        ...prev,
        [name]: newRestrictions
      }
    })
  }

  function setCopModelRestrictions(modelId, newRestrictions) {
    const restrictionsToUse = JSON.parse(JSON.stringify(restrictions))

    if (!restrictionsToUse.copModels) {
      restrictionsToUse.copModels = {}
    }

    restrictionsToUse.copModels[modelId] = newRestrictions

    setSignalizationRestrictions(restrictionsToUse.copModels, COP_MODELS)
  }

  function handleHorizontalCopsToggle(checked) {
    const restrictionsToUse = JSON.parse(JSON.stringify(restrictions))

    if (!restrictionsToUse.horizontalCops) {
      restrictionsToUse.horizontalCops = {}
    }

    restrictionsToUse.horizontalCops.disabled = checked
    setSignalizationRestrictions(restrictionsToUse.horizontalCops, HORIZONTAL_COPS)
  }

  function handleCopModelChange(checkedValues, name, modelId, checked) {
    const restrictionsToUse = JSON.parse(JSON.stringify(restrictions))

    if (modelId === 'KSSH') {
      handleHorizontalCopsToggle(checked)
      return
    }

    const modelRestrictions = restrictionsToUse[modelId] || {}

    modelRestrictions.disabled = checked

    // Empty out any possible errors for COP types when
    // a COP model is checked.
    handleError(modelId, '')
    setCopModelRestrictions(modelId, modelRestrictions)
  }

  function handleHorizontalCopsChange(checkedValues) {
    const restrictionsToUse = JSON.parse(JSON.stringify(restrictions))

    if (!restrictionsToUse.horizontalCops) {
      restrictionsToUse.horizontalCops = {}
    }

    restrictionsToUse.horizontalCops.types = checkedValues.map(id => ({
      id,
      disabled: true
    }))

    setSignalizationRestrictions(restrictionsToUse.horizontalCops, HORIZONTAL_COPS)
  }

  const familiesToDisplay = [...product.componentsData.signalization.copModels]

  let familyRestrictions = restrictions[COP_MODELS] && Object.entries(restrictions[COP_MODELS])
    .filter(entry => entry[1]?.disabled)
    .map(entry => entry[0])
 
  if (!familyRestrictions) {
    familyRestrictions = []
  }

  if (restrictions?.horizontalCops?.disabled) {
    familyRestrictions.push('KSSH')
  }


  let handicapFamily
  if (horizontalCops.length > 0) {
    handicapFamily = {
      id: 'KSSH',
      name: 'Handicap COPs',
      copTypes: horizontalCops
    }

    familiesToDisplay.push(handicapFamily)
  }

  return (
    <Card className="Signalization component-card">
      <h2 className="component-card__title">{title}</h2>
      <Tabs className="component-card__main-tab" animated={false}>
        <TabPane className="tab-pane" tab="Signalization families" key="cop">

          <CheckboxGroup
            items={familiesToDisplay}
            handleChange={handleCopModelChange}
            name="copModels"
            disableSort={true}
            requireSelection
            defaultValue={familyRestrictions}
            errorMsg="You have to select at least one COP family."
            // title="Signalization families"
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>
          <Collapse
            bordered={false}
            expandIconPosition="left"
            className="family-collapse"
          >
            {copModelsData.filter(model => {
              if (restrictions[COP_MODELS]?.[model.id]?.disabled) {
                return false
              }
              return !model.disabled
            }).map(model => {

              return (
                <SignalizationFamilyManager
                  key={model.id}
                  managingGlobal={managingGlobal}
                  setCopModelRestrictions={(newRestrictions) => setCopModelRestrictions(model.id, newRestrictions)}
                  copModelRestrictions={restrictions[COP_MODELS]?.[model.id]}
                  family={model}
                  modelData={copModelsData.find(item => item.id === model.id)}
                  handleError={handleError}
                  errors={errors}
                />
              )
            })}
            {handicapFamily && !restrictions.horizontalCops?.disabled &&
              <Collapse.Panel style={{ marginTop: "2rem" }} header={handicapFamily.name}>
                <CheckboxGroup
                  items={handicapFamily.copTypes}
                  sap={false}
                  handleChange={handleHorizontalCopsChange}
                  name={'horCops'}
                  defaultValue={(restrictions.horizontalCops?.types || []).map(x => x.id)}
                  // setError={handleError}
                  managingGlobal={managingGlobal}
                ></CheckboxGroup>
              </Collapse.Panel>
            }
          </Collapse>
        </TabPane>
        <TabPane className="tab-pane" tab="Positioning" key="positions">
          <CheckboxGroup
            items={product.componentsData.signalization.amountOfDevices}
            name={AMOUNT_OF_DEVICES}
            title="Amount of car operating panels allowed"
            defaultValue={restrictions[AMOUNT_OF_DEVICES]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>
          <CheckboxGroup
            items={product.componentsData.signalization.copPositions}
            name={COP_POSITIONS}
            title="Car operating panel positions"
            defaultValue={restrictions[COP_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            requiredSelections={2 - restrictions[AMOUNT_OF_DEVICES]?.length}
            errorMsg="You have to select enough positions to fit the COP devices."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          {/* HL positions */}
          <CheckboxGroup
            items={landingComponentPositions.hl}
            name={HL_POSITIONS}
            title="Hall indicator positions"
            defaultValue={restrictions[HL_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          {/* LCS positions */}
          <CheckboxGroup
            items={landingComponentPositions.lcs}
            name={LCS_POSITIONS}
            title="Landing call station positions"
            defaultValue={restrictions[LCS_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          {/* DOP positions */}
          <CheckboxGroup
            items={landingComponentPositions.dop}
            name={DOP_POSITIONS}
            title="Destination operating panel positions"
            defaultValue={restrictions[DOP_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          {/* EID positions */}
          <CheckboxGroup
            items={landingComponentPositions.eid}
            name={EID_POSITIONS}
            title="Elevator identifier positions"
            defaultValue={restrictions[EID_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          {/* DIN positions */}
          <CheckboxGroup
            items={landingComponentPositions.din}
            name={DIN_POSITIONS}
            title="Destination indicator positions"
            defaultValue={restrictions[DIN_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>

          <CheckboxGroup
            items={landingComponentPositions.jamb}
            name={JAMB_POSITIONS}
            title="JAMB indicator positions"
            defaultValue={restrictions[JAMB_POSITIONS]}
            handleChange={setSignalizationRestrictions}
            requireSelection
            errorMsg="You have to select at least one position."
            setError={handleError}
            managingGlobal={managingGlobal}
          ></CheckboxGroup>
        </TabPane>
      </Tabs>
    </Card>
  )
}

function SignalizationFamilyManager(props) {
  const { family: model, handleError, errors, copModelRestrictions = {}, setCopModelRestrictions, managingGlobal, modelData = {}, ...rest } = props
  const { getText } = useContext(TranslationsContext)

  function setCopTypesError(modelId, message) {
    handleError(modelId, message)
  }

  function handleCopTypeChange(typeId, checked, modelId) {
    const restrictions = { ...copModelRestrictions }

    if (!restrictions.copTypes) restrictions.copTypes = {}
    if (!restrictions.copTypes[typeId]) restrictions.copTypes[typeId] = {}

    restrictions.copTypes[typeId].disabled = checked
    setCopModelRestrictions(restrictions)

    const allSelected = Object.values(restrictions)
      .filter(v => v.disabled).length === modelData.copTypes?.length

    if (allSelected) {
      setCopTypesError(modelId, "You need to select at least one available COP type.")
    } else {
      setCopTypesError(modelId, '')
    }
  }

  function handleHallIndicatorChange(typeId, checked, modelId) {
    const restrictions = { ...copModelRestrictions }

    if (!restrictions.hallIndicators) restrictions.hallIndicators = {}
    if (!restrictions.hallIndicators[typeId]) restrictions.hallIndicators[typeId] = {}

    restrictions.hallIndicators[typeId].disabled = checked

    setCopModelRestrictions(restrictions)
  }

  function handleRealHallIndicatorChange(typeId, checked, modelId) {
    const restrictions = { ...copModelRestrictions }

    if (!restrictions.realHallIndicators) restrictions.realHallIndicators = {}
    if (!restrictions.realHallIndicators[typeId]) restrictions.realHallIndicators[typeId] = {}

    restrictions.realHallIndicators[typeId].disabled = checked

    setCopModelRestrictions(restrictions)
  }
  
  function handleCallStationChange(typeId, checked, modelId) {
    const restrictions = { ...copModelRestrictions }

    if (!restrictions.callStationTypes) restrictions.callStationTypes = {}
    if (!restrictions.callStationTypes[typeId]) restrictions.callStationTypes[typeId] = {}

    restrictions.callStationTypes[typeId].disabled = checked

    setCopModelRestrictions(restrictions)
  }

  function setRealHallIndicatorRestrictions(typeId, modelId, values) {
    const newRestrictions = { ...copModelRestrictions }

    if (!newRestrictions.realHallIndicators) newRestrictions.realHallIndicators = {}

    newRestrictions.realHallIndicators[typeId] = values
    setCopModelRestrictions(newRestrictions)
  }

  function setHallIndicatorRestrictions(typeId, modelId, values) {
    const newRestrictions = { ...copModelRestrictions }

    if (!newRestrictions.hallIndicators) newRestrictions.hallIndicators = {}

    newRestrictions.hallIndicators[typeId] = values
    setCopModelRestrictions(newRestrictions)
  }

  function setLandingCallStationRestrictions(typeId, modelId, values) {
    const newRestrictions = { ...copModelRestrictions }

    if (!newRestrictions.callStationTypes) newRestrictions.callStationTypes = {}

    newRestrictions.callStationTypes[typeId] = values

    setCopModelRestrictions(newRestrictions)
  }

  function setRestrictions(typeId, modelId, values) {
    const newRestrictions = { ...copModelRestrictions }

    if (!newRestrictions.copTypes) newRestrictions.copTypes = {}

    newRestrictions.copTypes[typeId] = values
    setCopModelRestrictions(newRestrictions)
  }

  function handleFinishChange(checkedValues, typeId) {
    const restrictions = { ...copModelRestrictions }

    if (!restrictions.copTypes) restrictions.copTypes = {}
    if (!restrictions.copTypes[typeId]) restrictions.copTypes[typeId] = {}

    restrictions.copTypes[typeId].finishes = checkedValues
    setCopModelRestrictions(restrictions)
  }

  function handleLandingItemsChange(checkedValues, name) {
    const restrictions = { ...copModelRestrictions }
    restrictions[name] = checkedValues
    setCopModelRestrictions(restrictions)
  }

  function createLandingItemManager(header, name) {
    if (!model[name]?.length) return null

    return (
      <Collapse.Panel key={name} header={header}>
        <CheckboxGroup
          items={model[name]}
          sap={false}
          handleChange={handleLandingItemsChange}
          name={name}
          defaultValue={copModelRestrictions[name]}
          setError={handleError}
          managingGlobal={managingGlobal}
        ></CheckboxGroup>
      </Collapse.Panel>
    )
  }

  return (
    <Collapse.Panel
      {...rest}
      style={{ marginTop: "2rem" }}
      header={`Components for ${getText(model.name)}`}
    >
      <Collapse
        className="sub-group"
        bordered={false}
      >
        <Collapse.Panel
          header="Car operating panel types"
          key="copTypes"
        >
          {errors[model.id] ? <p className="error-message">{errors[model.id]}</p> : null}
          {model.copTypes.map(type => {
            return (
              <CopItemWithDisplays
                restrictions={copModelRestrictions.copTypes?.[type.id]}
                setRestrictions={(values) => setRestrictions(type.id, model.id, values)}
                onCopToggle={(checked) => {
                  handleCopTypeChange(type.id, checked, model.id)
                }}
                key={type.id}
                type={type} />
            )
          })}
        </Collapse.Panel>
        {model.hallIndicators?.length && <Collapse.Panel header="Hall lantern types" key="hls">
          {model.hallIndicators.map(type => {
            return (
              <CopItemWithDisplays
                restrictions={copModelRestrictions.hallIndicators?.[type.id]}
                setRestrictions={(values) => setHallIndicatorRestrictions(type.id, model.id, values)}
                onCopToggle={(checked) => {
                  handleHallIndicatorChange(type.id, checked, model.id)
                }}
                key={type.id}
                type={type} />
            )
          })}
        </Collapse.Panel>}
        {model.realHallIndicators?.length && <Collapse.Panel header="Hall indicator types" key="realhls">
          {model.realHallIndicators.map(type => {
            return (
              <CopItemWithDisplays
                restrictions={copModelRestrictions.realHallIndicators?.[type.id]}
                setRestrictions={(values) => setRealHallIndicatorRestrictions(type.id, model.id, values)}
                onCopToggle={(checked) => {
                  handleRealHallIndicatorChange(type.id, checked, model.id)
                }}
                key={type.id}
                type={type} />
            )
          })}
        </Collapse.Panel>}
        {model.callStationTypes?.length && <Collapse.Panel header="Landing call station types" key="lcs">
          {model.callStationTypes.map(type => {
            return (
              <CopItemWithDisplays
                restrictions={copModelRestrictions.callStationTypes?.[type.id]}
                setRestrictions={(values) => setLandingCallStationRestrictions(type.id, model.id, values)}
                onCopToggle={(checked) => {
                  handleCallStationChange(type.id, checked, model.id)
                }}
                key={type.id}
                type={type} />
            )
          })}
        </Collapse.Panel>}
        {createLandingItemManager('Destination operating panel types', 'destinationOP')}
        {createLandingItemManager('Elevator identifier types', 'elevatorIdentifier')}
        {createLandingItemManager('Destination indicator at landing', 'landingDestIndicator')}
        {createLandingItemManager('JAMB indicators', 'jambIndicators')}
        {createLandingItemManager('Foot pedal', 'footButtons')}
      </Collapse>
    </Collapse.Panel>
  )
}

const checkboxStyle = getCheckboxStyle({ checkBoxesInRow: 4 })

function CopItemWithDisplays(props) {
  const { className = '', type, onCopToggle, restrictions = {}, setRestrictions } = props

  const { getText } = useContext(TranslationsContext)
  const enabledDisplays = (type.displayTypes || []).filter(display => {
    const displayRestrictions = restrictions.displayTypes?.find(x => x.id === display.id)

    if (displayRestrictions?.disabled) {
      return false
    }

    return true
  })
  const displaysWithColors = enabledDisplays.filter(x => x.displayColors.length > 1) || []

  function handleFinishChange(id, checked) {
    const newRestrictions = { ...restrictions }

    if (!newRestrictions.finishes) {
      newRestrictions.finishes = []
    }

    if (checked && !newRestrictions.finishes.includes(id)) {
      newRestrictions.finishes.push(id)
    } else {
      newRestrictions.finishes = newRestrictions.finishes.filter(x => x !== id)
    }

    setRestrictions(newRestrictions)
  }

  function handleDisplayChange(id, checked) {
    const newRestrictions = { ...restrictions }

    if (!newRestrictions.displayTypes) {
      newRestrictions.displayTypes = []
    }

    const found = newRestrictions.displayTypes.find(x => x.id === id)

    if (found) {
      found.disabled = checked
    } else {
      newRestrictions.displayTypes.push({
        id,
        disabled: checked
      })
    }

    setRestrictions(newRestrictions)
  }

  function handleColorChange(displayId, colorId, checked) {
    const newRestrictions = { ...restrictions }

    if (!newRestrictions.displayTypes) {
      newRestrictions.displayTypes = []
    }

    const found = newRestrictions.displayTypes.find(x => x.id === displayId)

    if (found) {
      if (!found.displayColors) {
        found.displayColors = []
      }

      if (checked && !found.displayColors.includes(colorId)) {
        found.displayColors.push(colorId)
      } else {
        found.displayColors = found.displayColors.filter(x => x !== colorId)
      }
    } else {
      if (checked) {
        newRestrictions.displayTypes.push({
          id: displayId,
          displayColors: [colorId]
        })
      }
    }

    setRestrictions(newRestrictions)
  }

  return (
    <div className={`CopItemWithDisplays ${className}`} key={type.id}>
      <ReverseCheckbox checked={restrictions.disabled} onChange={(e) => onCopToggle(e.target.checked)} className="cop">{getText(type.name)}</ReverseCheckbox>
      { !restrictions.disabled && <div className="inner-items">
        <CopTypeManagerInnerContainer
          items={type.finishes}
          minimum={0}
          checked={restrictions.finishes}
          onChange={handleFinishChange}
          displaySapId={true}
          header="Finishes"
          className="finishes"
        />
        <CopTypeManagerInnerContainer
          items={type.displayTypes}
          checked={restrictions.displayTypes?.filter(x => x.disabled)
            .map(x => x.id)
          }
          onChange={handleDisplayChange}
          header="Displays"
          className="displays"
        />
        {displaysWithColors.map(display => {
          const checked = restrictions
            .displayTypes?.find(x => x.id === display.id)?.displayColors
          return (
            <CopTypeManagerInnerContainer
              key={display.id}
              onChange={(color, checked) => handleColorChange(display.id, color, checked)}
              items={display.displayColors}
              checked={checked}
              header={`${getText(display.name)} colors`}
              className="colors"
            />
          )
        })
        }
      </div>}
    </div>
  )
}

function CopTypeManagerInnerContainer(props) {
  const { className = '', minimum = 1, items = [], header, displaySapId, onChange, checked = [] } = props
  const { getText } = useContext(TranslationsContext)

  if (items.length <= minimum) return null

  return (
    <div className={`inner-item-container ${className}`}>
      <h3>{header}</h3>
      <div className="items" style={{ height: calculateCheckboxContainerHeight(items.length) }}>
        {
          items
            .sort((a, b) => {
              if (displaySapId) {
                if (a.sapId > b.sapId) return 1
                if (a.sapId < b.sapId) return -1
              }

              const aName = getText(a.name)
              const bName = getText(b.name)

              if (aName > bName) return 1
              if (aName < bName) return -1
              return 0
            })
            .map(item => {
              const isChecked = checked.find(x => x === item.id)
              return (
                <ReverseCheckbox value={item.id} onChange={(e) => onChange(item.id, e.target.checked)} checked={isChecked} key={item.id} style={checkboxStyle}>
                  {
                    displaySapId &&
                    <><span style={{ fontWeight: 700 }}>{item.sapId}</span> -</>
                  }
                  {getText(item.name)}
                </ReverseCheckbox>
              )
            })
        }
      </div>
    </div>
  )
}

export default Signalization