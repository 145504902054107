import React from 'react'
import { Result, Button } from 'antd'
import { withRouter } from 'react-router-dom'

/**
 * 
 * @param {Object} props 
 * @param {string} props.countryName 
 * @param {Object} props.history 
 */
const NotFoundPage = (props) => {

  function handleClick() {
    props.history.push('/')
  }

  return (
    <div className="NotFoundPage page">
      <Result
        status="404"
        title="404"
        subTitle={`No frontline data for ${props.countryName} found.`}
        extra={<Button onClick={handleClick} type="primary">Back Home</Button>}
      ></Result>
    </div>
  )
} 

export default withRouter(NotFoundPage)