import { message } from 'antd'
import React, { useState, useEffect } from 'react'
import ExportDataButton from '../../../../components/ExportDataButton/ExportDataButton'

import useService from '../../../../services/useService'
import './KTOC.scss'
import UnitChart from '../../../../components/UnitChart/UnitChart';

const excelHeaders = [
  { key: 'productName', label: 'Product ID' },
  { key: 'destinationCountry', label: 'Destination country' },
  { key: 'status', label: 'Response status' },
  { key: 'Timestamp', label: 'Timestamp' },
  { key: 'unsupportedComponents', label: 'Unsupported components' },
  { key: 'blobUri', label: 'XML donwload link' },
]

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function KTOC(props) {
  const {
    className = '',
    availableProducts,
  } = props


  const logService = useService('log')

  const [loading, setLoading] = useState(true)
  const [tableEntries, setTableEntries] = useState()
  const [excelExportData, setExcelExportData] = useState()

  useEffect(() => {
    logService.getKTOCEntries().then(result => {
      setTableEntries(result)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })
  }, [logService])

  return (
    <div data-testid="KTOC" className={`KTOC ${className}`}>
      <h2>KTOC</h2>
      <UnitChart
        className="chart-container"
        loading={loading}
        tableEntries={tableEntries}
        availableProducts={availableProducts}
        setExcelExportData={setExcelExportData}
      />
      <ExportDataButton
        filename="KTOC_log_export"
        sheetName="Data"
        data={excelExportData}
        headers={excelHeaders}
      />
    </div>
  )
}

export default KTOC