import React, { useState } from 'react'
import { Button, message, Modal, notification } from 'antd'
import { createErrorMessage } from '../../utils/error-utils'

import './SaveNotification.scss'

const SaveNotification = (props) => {
  const { notificationKey, failedFrontlines, productId, productName, releaseId, frontline, releaseProduct, releaseAllowed } = props

  const [ isReleasing, setIsReleasing ] = useState(false)

  const handleCloseClick = () => {
    notification.close(notificationKey) // Closes the notification with the key
  }

  const handleRelease = () => {
    setIsReleasing(true)
    releaseProduct(frontline, productId, releaseId).then(result => {
      message.success("Released succesfully!")
      notification.close(notificationKey)
      if (result.failedFrontlines) {
        result.failedFrontlines.forEach(item => {
          message.error(`Failed to update the product for ${item.frontlineName}`, 15)
        })
      }
    }).catch(err => {
      console.error(err)
    const errorMessage = createErrorMessage(err, {
      attemptedOperation: 'Release',
      attemptedItem: 'frontline'
    })

    notification.close(notificationKey)
    message.error(errorMessage, 5)
    }).finally(() => {
      setIsReleasing(false)
      notification.close(notificationKey)
    })
  }

  function showConfirm() {
    Modal.confirm({
      title: `Are you sure you want to release ${productName} (${releaseId}) for ${frontline.country.name}?`,
      content: "This action will release the product to the KONE Car Designer.",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRelease()
      }
    })
  }

  const title = failedFrontlines && failedFrontlines.length > 0 ? "However, the product could not be updated for the following frontline countries:" : `The product ${productName} (${releaseId}) has been saved succesfully.`

  return (
    <div className="SaveNotification">
      <h3>{title}</h3>

      { 
        failedFrontlines && failedFrontlines.length > 0 &&
        <div>
          <ul>
            { failedFrontlines.map(fl => (
              <li key={fl.frontlineId}>
                <a 
                  href={window.location.toString().replace('frontline/global/', `frontline/${fl.frontlineId}/`)}
                  target="_blank" rel="noopener noreferrer"
                >{fl.frontlineName}</a> { fl.reason ? fl.reason : null }
              </li>
            ))}
          </ul>
          <p style={{color: "red", fontWeight: 500}}>You should check the offering for the above countries and update them individually.</p>
        </div>
      }

    <div className="buttons-container">
      <Button onClick={handleCloseClick}>Close</Button>
      { releaseAllowed && <Button type="danger" loading={isReleasing} onClick={showConfirm}>Release product</Button>}
    </div>
    </div>
  )
}

/**
 * 
 * @param {Object} props
 * @param {string} props.productId
 * @param {string} props.releaseId
 * @param {string} props.productName
 * @param {Function} props.releaseProduct
 * @param {Object} props.frontline
 * @param {Object[]=} props.failedFrontlines
 * @param {boolean=} props.releaseAllowed
 */
export function notify(props) {
  const { productId, releaseId, productName, releaseProduct, frontline, failedFrontlines, releaseAllowed } = props
  const notificationKey = `success${Date.now()}` // Needed for closing the notification.

      // Opens a success notification.
      notification.success({
        message: "Saving succesful!",
        placement: "topLeft",
        duration: 0,
        key: notificationKey,
        description: (
          <SaveNotification
            notificationKey={notificationKey}  
            productId={productId}
            frontline={frontline}
            productName={productName}
            releaseId={releaseId}
            failedFrontlines={failedFrontlines}
            releaseProduct={releaseProduct}
            releaseAllowed={releaseAllowed}
          ></SaveNotification>)
      })
}

export default SaveNotification