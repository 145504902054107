import React, { useState, useMemo, useContext } from 'react'

import './Web.scss'

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function Web(props) {
  const {
    className = ''
  } = props

  return (
    <div data-testid="Web" className={`Web ${className}`}>
      <h2>Web application</h2>
    </div>
  )
}

export default Web