import React, { useState, useEffect } from 'react'
import { Card, Radio, Select, Switch, ConfigProvider, DatePicker } from 'antd'
import locale from 'antd/lib/locale/en_GB'
import './APIDashboardFilters.scss'
import { getRange } from './api-dashboard-utils'

const selectPlaceholders = {
  date: 'Select day',
  week: 'Select week',
  month: 'Select month',
  year: 'Select year',
  range: 'Select time range',
}

const defaultViewTypes = [
  { name: "Time", value: "time" },
  { name: "Country", value: "country" },
]

function APIDashboardFilters({
  onChange,
  countries = [],
  viewTypes = defaultViewTypes
}) {

  const [viewType, setViewType] = useState(viewTypes[0].value)
  const [stacked, setStacked] = useState(false)
  // const [stackedBy, setStackedBy] = useState('version')
  const [pickerType, setPickerType] = useState('date')
  const [country, setCountry] = useState(null)
  const [time, setTime] = useState(null)
  const [range, setRange] = useState(null)

  useEffect(() => {

    let r
    if (time) {
      r = getRange(time._d, pickerType)
    }
    if (range) {
      r = [range[0]._d, range[1]._d,]
    }

    const filters = {
      viewType,
      stacked,
      pickerType,
      country,
      range: r, // [Date, Date]
    }
    if (typeof onChange === "function") {
      onChange(filters)
    }
  }, [viewType, stacked, pickerType, country, time, range])

  return (
    <div className='APIDashboardFilters'>
      <Card>
        <div className="filter">
          <div className="filterHeader">Approach by</div>
          <div className="filterItem">
            <Radio.Group
              value={viewType} optionType="button"
              onChange={e => setViewType(e.target.value)}
              buttonStyle="solid"
              className="fullWidth"
            >
              {viewTypes.map((item, index) => (
                <Radio.Button key={index} value={item.value} className="halfWidth">{item.name}</Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>

        <div className="filter">
          <div className="filterHeader">Separate the values</div>
          <div className="filterItem">
            <Switch checked={stacked} onChange={value => setStacked(value)} />
          </div>
        </div>

        {/* (viewType === 'country') && stacked &&
          <div className="filter">
            <div className="filterHeader">Stacked by</div>
            <div className="filterItem">
              <Radio.Group onChange={e => setStackedBy(e.target.value)} value={stackedBy} optionType="button" buttonStyle="solid" className="fullWidth">
                <Radio.Button value={'version'} className="halfWidth">Version</Radio.Button>
                <Radio.Button value={'os'} className="halfWidth">OS</Radio.Button>
              </Radio.Group>
            </div>
          </div>
         */}

        {<div className="filter">
          <div className="filterHeader">Select the time period</div>
          <div className="filterItem">
            <Select
              value={pickerType}
              onChange={e => {
                if (e !== pickerType) {
                  // reset time & range if picker type is changed
                  setTime(null)
                  setRange(null)
                }
                setPickerType(e)
              }}
              className="fullWidth"
            >
              <Select.Option value="date">Day</Select.Option>
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="year">Year</Select.Option>
              <Select.Option value="range">Time range</Select.Option>
            </Select>
            <ConfigProvider locale={locale}>
              {/* 
              Dev Note: 
              DatePicker will not work properly if picker type is changed on the go.
              Following code makes sure that new DatePicker instance is created if 
              if type is changed.
               */}
              {pickerType === "date" && <DatePicker
                picker={pickerType}
                placeholder={selectPlaceholders[pickerType]}
                defaultValue={time}
                onChange={setTime}
                className="fullWidth"
              />}
              {pickerType === "week" && <DatePicker
                picker={pickerType}
                placeholder={selectPlaceholders[pickerType]}
                defaultValue={time}
                onChange={setTime}
                className="fullWidth"
              />}
              {pickerType === "month" && <DatePicker
                picker={pickerType}
                placeholder={selectPlaceholders[pickerType]}
                defaultValue={time}
                onChange={setTime}
                className="fullWidth"
              />}
              {pickerType === "year" && <DatePicker
                picker={pickerType}
                placeholder={selectPlaceholders[pickerType]}
                defaultValue={time}
                onChange={setTime}
                className="fullWidth"
              />}
              {pickerType === "range" && <DatePicker.RangePicker
                picker="date"
                defaultValue={range}
                onChange={setRange}
                className="fullWidth"
              />}
            </ConfigProvider>
          </div>
        </div>}

        {countries &&
          <div className="filter">
            <div className="filterHeader">Select country</div>
            <div className="filterItem">
              <Select
                defaultValue={country}
                onChange={value => setCountry(value)}
                className="fullWidth"
              >
                <Select.Option value={null}>All</Select.Option>
                {countries.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        }
      </Card>
    </div>
  )
}

export default APIDashboardFilters