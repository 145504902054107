/**
 * 
 * @param {Object} error - The error object  
 * @param {Object} options 
 * @param {string=} options.message - ??? 
 * @param {string=} options.preMessage
 * @param {string=} options.preWaitAndRefresh
 * @param {string=} options.attemptedItem - The item that was attempted to be updated, but had been updated by someone else
 * @param {string=} options.attemptedOperation - The attempted operation. Will be part of the error message as 'attempted operation' failed.
 */
export function createErrorMessage(error, { 
  message, 
  preMessage, 
  preWaitAndRefresh, 
  attemptedOperation = 'Operation',
  attemptedItem }) {

  const messageToUse = message || `${attemptedOperation} failed`
  if (error.status === 412) {
    return `${messageToUse}: The ${attemptedItem} has been updated by someone else, and the displayed state may no longer be up-to-date. Please refresh the page and try again.`
  }

  if (error.message) {
    return `${preMessage || messageToUse}. ${error.message}`
  }

  return `${preWaitAndRefresh || messageToUse}. Please wait for a while and try again. If the problem persists, refresh the page and try again.`
}

export function createError(err) {
  if (!err.response) return err

  return {
    data: err.response.data,
    status: err.response.status,
    message: err.response.headers["error-message"],
    response: err.response // just in case so old error messages won't crash the app.
  }
}