// import React from 'react'
import ModelAndLayout from './ModelAndLayout'
import Ceiling from './new/Ceiling'
import Walls from './new/Walls'
import Floor from './new/Floor'
import Doors from './new/Doors'
import Signalization from './new/Signalization'
import Accessories from './new/Accessories'
import Services from './new/Services'
import Landing from './Landing'
import './Tabs.scss'

const tabs = [
  { key: "model-and-layout", text: "Model & Layout", Component: ModelAndLayout },
  { key: "ceiling", text: "Ceilings", Component: Ceiling },
  { key: "walls", text: "Walls", Component: Walls },
  { key: "floor", text: "Floor materials", Component: Floor },
  { key: "car-doors", text: "Doors & Frames", Component: Doors },
  { key: "signalization", text: "User Interface", Component: Signalization },
  { key: "accessories", text: "Accessories", Component: Accessories },
  { key: "kcsmServices", text: "Services", Component: Services },
  { key: "landing", text: "Landing", Component: Landing, globalOnly: true },
]

export default tabs