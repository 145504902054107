import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { message, Switch } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import CustomSwitch from '../../components/CustomSwitch'
import ExportDataButton from '../../components/ExportDataButton/ExportDataButton'
import FrontlineCreator from '../../components/FrontlineCreator'
import FrontlinesDisplay from '../../components/FrontlinesDisplay'
import frontlinesCSV from '../../csv/data/frontline'
import { AuthContext } from '../../store/auth'
import { FrontlinesContext } from '../../store/frontlines'
import { createErrorMessage } from '../../utils/error-utils.js'
import './DashboardPage.scss'

const DashboardPage = ({ history }) => {
  const [frontlinesLoading, setFrontlinesLoading] = useState(true)
  const { frontlines, createFrontline, saveFeedbackBannerSettings, loadFrontlines, globalFrontline, saveReleaseRights, saveEnableHighResSettings } =
    useContext(FrontlinesContext)
  const { loggedInUser } = useContext(AuthContext)
  const [toggleLoading, setToggleLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      await loadFrontlines()
      setFrontlinesLoading(false)
    })()
  }, [])

  const handleChange = async (checked, e) => {
    try {
      setToggleLoading(true)
      await saveReleaseRights({ state: checked })

      if (checked) {
        message.success('Releasing allowed succesfully.')
      } else {
        message.success('Releasing disabled succesfully.')
      }
    } catch (err) {
      console.error('>', err)
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the setting',
        attemptedItem: 'settings',
      })

      message.error(errorMessage, 5)
    } finally {
      setToggleLoading(false)
    }
  }
  async function enableHighRaise(checked) {
    try {
      await saveEnableHighResSettings({ show: checked })
      message.success('Settings saved successfully.')
    } catch (err) {
      message.error("errorMessage", 5)
    }

  }
  async function handleBannerChange(checked) {
    try {
      await saveFeedbackBannerSettings({ show: checked })
      message.success('Settings saved successfully.')
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the setting',
        attemptedItem: 'settings',
      })

      message.error(errorMessage, 5)
    }
  }

  if (!loggedInUser) return null
  return (
    <div className="DashboardPage page">
      <h1 className="page__title">Frontlines</h1>
      <FrontlinesDisplay frontlines={frontlines} history={history} loading={frontlinesLoading} />
      {globalFrontline && loggedInUser.role === 'admin' && (
        <div className="bottom-block">
          <div className="switch-container">
            <span className="switch-label">Releasing products to frontlines allowed:</span>
            <Switch
              onChange={handleChange}
              loading={toggleLoading}
              checked={globalFrontline.allowRelease}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </div>
          <div className="switch-container">
            <span className="switch-label">Enable high res</span>
            <CustomSwitch
              onChange={enableHighRaise}
              checked={globalFrontline.setHighResSettings?.show}
            />
          </div>
          <div className="switch-container">
            <span className="switch-label">Show feedback banner:</span>
            <CustomSwitch
              onChange={handleBannerChange}
              checked={globalFrontline.feedbackBannerSettings?.show}
            />
          </div>
          <div className="right-buttons-container">
            <ExportDataButton
              headers={frontlinesCSV.getHeaders()}
              data={frontlinesCSV.getData(frontlines)}
              filename={frontlinesCSV.getName()}
            />
            <FrontlineCreator createFrontline={createFrontline} frontlines={frontlines} />
          </div>
        </div>
      )}
    </div>
  )
}

export default DashboardPage
