import { InboxOutlined, TeamOutlined, ReadOutlined, GlobalOutlined, BlockOutlined, BarChartOutlined } from '@ant-design/icons'

export const MENU_ITEMS = [
  {
    label: 'Frontlines',
    icon: InboxOutlined,
    to: '/',
    adminOnly: false
  },
  {
    label: 'Users',
    icon: TeamOutlined,
    to: '/users',
    adminOnly: true
  },
  {
    label: 'Languages',
    icon: ReadOutlined,
    to: '/languages',
    adminOnly: true
  },
  {
    label: 'Global frontline',
    icon: GlobalOutlined,
    to: '/frontline/global',
    adminOnly: true
  },
  {
    label: 'Release versioning',
    icon: BlockOutlined,
    to: '/releases',
    adminOnly: true
  },
  {
    label: 'RICK',
    icon: BlockOutlined,
    to: '/rick',
    availableForRick:true,
  },
  {
    label: 'APIs',
    icon: BarChartOutlined,
    to: '/logs',
    suboptions: [
      {
        label: 'Dashboard',
        to: '/logs/dashboard'
      },
      {
        label: 'Image generator',
        to: '/logs/image-gen',
        adminOnly: true,
      },
    ]
  },
]