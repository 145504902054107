import React, { useState, useEffect } from 'react'
import { message, Row, Col, Spin } from 'antd'
import useService from '../../../../services/useService'
import useInterval from '../../../../utils/useInterval'
import './RickServers.scss'

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function RickServers(props) {
  const {
    className = ''
  } = props

  return (
    <div data-testid="RickServers" className={`RickServers ${className}`}>
      <div className="content-container">
        <RickServerStatusChecker updateIntervalSeconds={150} />
      </div>
    </div>
  )
}

/* const TABLE_COLUMNS = [
  {
    title: 'Machine Name',
    dataIndex: 'Machine',
    key: 'machine',
  },
  {
    title: 'Server Type',
    dataIndex: 'Type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'status',
    render: status => {
      return (
        <span className={`status-indicator ${status}`} key={status}>
          {status.toUpperCase()}
        </span>
      )
    }
  },
] */

function RickServerStatusChecker(props) {
  const { updateIntervalSeconds } = props

  const logService = useService('log')

  const [status, setStatus] = useState()
  const [loading, setLoading] = useState(true)

  useInterval(() => {
    updateStatus()
  }, updateIntervalSeconds * 1000)

  useEffect(() => {
    updateStatus()
      .finally(() => {
        setLoading(false)
      })
  }, [])

  async function updateStatus() {
    try {
      const result = await logService.getRickServersStatus()
      const formatted = result.map(item => {
        if (!item.Type) return item

        return {
          ...item,
        }

      })
      setStatus(formatted)

    } catch (err) {
      console.error(err)
      message.error('Failed to update VM status')
    }
  }

  return (
    <Spin spinning={loading}>
      <StatusCounter updateIntervalSeconds={updateIntervalSeconds} updateStatus={updateStatus} />
      <Row gutter={16}>
        {status && status.map((server, index) => (
          <Col flex="1" key={index}>
            <MachineStatus key={index} type={server.Type + " Server"} name={server.Machine} status={server.Status} />
          </Col>
        ))}
      </Row>
    </Spin>
  )

  /* return (
    <Card 
      title={"RICK Servers"}
      loading={loading}
      extra={<StatusCounter updateIntervalSeconds={updateIntervalSeconds} updateStatus={updateStatus} />}
      className="machines-container">
      { status ? 
        <MachineStatusTable machine={status} />
        : 'Failed to get data'
      }
    </Card>
  ) */
}

function MachineStatus({ type, name, status }) {
  return (
    <div className='MachineStatus'>
      <div className='MachineStatus-type'>{type}</div>
      <div><span className='MachineStatus-name'>{name}</span> <span className={'MachineStatus-status ' + status}>{status}</span></div>
    </div>
  )
}

/* function MachineStatusTable(props) {
  const { machine, className = '' } = props

  return (
    <Table
      className={`MachineStatusTable ${className}`}
      dataSource={machine}
      pagination={false}
      columns={TABLE_COLUMNS}
    />
  )
} */

function StatusCounter(props) {
  const { updateIntervalSeconds, updateStatus } = props
  const [updating, setUpdating] = useState(true)
  const [seconds, setSeconds] = useState(0)

  useInterval(() => {
    updateProgress()
  }, 1000)

  useEffect(() => {
    updateCount()
  }, [])

  function updateProgress() {
    if (updating) return

    const newProgress = seconds + 1

    if (newProgress > updateIntervalSeconds) {
      updateCount()
    } else {
      setSeconds(newProgress)
    }
  }

  async function updateCount() {
    setUpdating(true)
    updateStatus()
    setUpdating(false)
    setSeconds(0)
  }

  return (
    <div className="QueueStatus">
      <p onClick={updateCount} className="queue-status-title">Status (<span className="update-seconds">updating in {updateIntervalSeconds - seconds}s</span>)</p>
      {/* <Button onClick={updateCount}>Check now</Button>
      <p className="status-checker-text">Updating in <span className="update-seconds">{updateIntervalSeconds - seconds}</span> seconds</p> */}
      {/* <Progress className="progress-bar" trailColor="white" status={'normal'} percent={seconds / updateIntervalSeconds * 100} format={() => {
        // if (!messagesInQueue) return 'Loading'
        return <span className="status-checker-text">{updateIntervalSeconds - seconds} seconds</span>
      }} /> */}
    </div>
  )
}

export default RickServers