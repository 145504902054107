import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios'
import { baseUrl } from '../../config'

export const ConfigContext = React.createContext();

/**
 * Provider for Config calls.
 * Separate context for these so the Config calls used would always have the token auth included.
 */
export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState()

  useEffect(() => {
    if (config) return
    axios.get(`${baseUrl}/config`).then(result => {
      setConfig(result.data)
    })
  }, [])

  return (
    <ConfigContext.Provider value={{
      ...config
    }}>
      {config && children}
    </ConfigContext.Provider>
  )
}
export default ConfigProvider;

export const ConfigConsumer = ConfigContext.Consumer;