import dateFns from 'date-fns'
import { getDateForName } from '../utils'

const SHEETS = {
  SHEET: { 
    key: 'sheet1',
    name: 'Sheet 1',
  }
}

export function getSheets() {
  return Object.values(SHEETS)
}

export function getHeaders() {
  return [
    { key: 'language', label: 'Language' }, 
    { key: 'lastPublished', label: 'Last published' }, 
  ]
}

export function getData(languages = []) {
  const temp = languages
    .filter(x => !x.disabled)
    .map(language => {
      const result = {}

      result.language = language.language

      if (language.lastPublished) {
        result.lastPublished =  dateFns.format(new Date(language.lastPublished), 'MMMM Do, YYYY, HH:mm')
      }

      return result
    })

  return temp
}

function getName() {
  return `cd_languages_${getDateForName()}`
}

export default { getData, getHeaders, getName, getSheets }