import React, { useState, useMemo, useContext, useEffect } from 'react'
import { message } from 'antd'
import { WarningTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import useService from '../../services/useService'
import { createErrorMessage } from '../../utils/error-utils'

import './ImageGenDashboardPage.scss'
import ImageGen from './sections/ImageGen';

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function ImageGenDashboardPage(props) {
  const {
    className = ''
  } = props

  return (
    <div data-testid="ImageGenDashboardPage" className={`ImageGenDashboardPage page ${className}`}>
      <h1 className="page__title">Image Generator</h1>
      <ImageGen />
    </div>
  )
}


export default ImageGenDashboardPage