import React, { useState } from 'react'
import { Select, Switch, message, Spin, Card, Tooltip, Tag } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import lodash from 'lodash'
import dateFns from 'date-fns'
import AsyncButton from '../AsyncButton'
import './LanguageSelection.scss'
import { createErrorMessage } from '../../utils/error-utils'

/**
 * 
 * @param {Object} props 
 * @param {Object} props.frontline 
 * @param {Object[]} props.languages 
 * @param {Function} props.addLanguagesForFrontline 
 * @param {Function} props.removeLanguageFromFrontline 
 * @param {Function} props.publishTranslation 
 * @param {Function} props.revokePublishingRights 
 * @param {Function} props.givePublishingRights
 * @param {Function} props.saveDefaultLanguageForFrontline
 * @param {Function} props.setMobileVisibility
 * @param {Object} props.globalFrontline
 * @param {boolean=} props.admin
 */
const LanguageSelection = (props) => {
  const { 
    frontline,
    globalFrontline,
    languages, 
    addLanguagesForFrontline,
    removeLanguageFromFrontline,
    publishTranslation,
    revokePublishingRights,
    givePublishingRights,
    saveDefaultLanguageForFrontline,
    setMobileVisibility,
    admin
  } = props


  const [ selectedLanguages, setSelectedLanguages ] = useState([])

  const handleSave = async () => {
    try {
      await addLanguagesForFrontline(frontline, selectedLanguages)
      message.success("Saving succesful!")
      setSelectedLanguages([])
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the default language',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  const handleChange = (values) => {
    setSelectedLanguages(values)
  }

  const handleRemove = async (language) => {
    try {  
      await removeLanguageFromFrontline(frontline, language.code)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Removing the language',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  const handlePublish = async (language) => {
    try {
      const result = await publishTranslation(language.code, frontline.hiddenId)
      message.success("Publishing succesful!")
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Publishing',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  const handleSetDefault = async ({ code }) => {

    try {
      await saveDefaultLanguageForFrontline(frontline, code)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the default language',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  if (!globalFrontline || !frontline) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  return (
    <div className="LanguageSelection">
      <Select
        mode="multiple"
        className="language-select"
        placeholder="Add primary languages"
        onChange={handleChange}
        value={selectedLanguages}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        { 
          // Remove languages that have already been set from the selection list.
          lodash.differenceBy(languages, frontline.languages, 'code')
          .map(language => {

          return (
            <Select.Option key={language.code} value={language.code}>{language.language}</Select.Option>
          )
        })}
      </Select>
      <AsyncButton 
        onConfirm={handleSave} 
        type="primary"
        disabled={selectedLanguages.length === 0}
        >Add languages</AsyncButton>
      <div className="current-languages">
        {
          frontline.languages &&
          frontline.languages.map(l => {
            const found = globalFrontline.languages.find(x => x.code === l.code)
            if (found) {
              l.lastPublished = found.lastPublished
              l.showInMobile = found.showInMobile
            }
          return (
            <LanguageCard
              key={l.code}
              language={l}
              countryName={frontline.country.name}
              hiddenId={frontline.hiddenId}
              admin={admin}
              handlePublish={handlePublish}
              handleRemove={handleRemove}
              handleSetDefault={handleSetDefault}
              setMobileVisibility={setMobileVisibility}
              givePublishingRights={code => givePublishingRights(frontline, code)}
              revokePublishingRights={code => revokePublishingRights(frontline, code)}
            />
          )
        })}
      </div>
    </div>
  )
}

/**
 * 
 * @param {Object} props 
 * @param {Object} props.language 
 * @param {string} props.countryName 
 * @param {string} props.hiddenId
 * @param {Function} props.handleRemove 
 * @param {Function} props.givePublishingRights 
 * @param {Function} props.revokePublishingRights 
 * @param {Function} props.setMobileVisibility 
 * @param {Function} props.handlePublish 
 * @param {Function} props.handleSetDefault 
 * @param {boolean} props.admin 
 */
function LanguageCard(props) {
  const { 
    language,
    countryName,
    hiddenId,
    handleRemove,
    givePublishingRights,
    revokePublishingRights,
    setMobileVisibility,
    handlePublish,
    handleSetDefault,
    admin
  } = props

  const [ settingRights, setSettingRights ] = useState(false)
  const [ settingVisibility, setSettingVisibility ] = useState(false)

  const toggleMobileVisibility = async () => {
    setSettingVisibility(true)
    try {
      const visibility = !language.showInMobile
      await setMobileVisibility({ code: language.code, countryName: hiddenId, visibility })

      message.success(`Succesfully ${visibility ? 'enabled' : 'disabled'} publishing rights for ${language.language} for ${countryName}.`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Operation',
        attemptedItem: 'frontline'
      })

      console.error(err)

      message.error(errorMessage, 5)
    } finally {
      setSettingVisibility(false)
    }
  }

  const togglePublishingRights = async () => {
    const hasRights = language.publishingRights
    setSettingRights(true)
    try {

    if (hasRights) {
      await revokePublishingRights(language.code)
    } else {
      await givePublishingRights(language.code)
    }

    message.success(`Succesfully ${hasRights ? 'revoked' : 'added'} publishing rights for ${language.language} for ${countryName}.`)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Operation',
        attemptedItem: 'frontline'
      })

      console.error(err)

      message.error(errorMessage, 5)
    } finally {
      setSettingRights(false)
    }
  }

  return (
    <Card 
      title={language.language}
      className={'language-card' + (language.default ? ' default-language' : '')} key={language.code}
      extra={<div className="extra">
      {
        <AsyncButton
          size="small"
          onConfirm={() => handleRemove(language)}
          disabled={!admin && language.publishingRights}
          disabledTooltipText={`Cannot remove this language because ${countryName} is responsible for publishing it.`}
          confirmText={`Are you sure you want to remove ${language.language} from the list of languages for ${countryName}?`}
          icon={<CloseOutlined />}
          type="danger"
          className="languages-item-remove-button">
        </AsyncButton>
      }
      </div>}
      >
      <div className="card-container">
        {
          admin &&
          <div className="card-container__row">
            <span>Manager publish rights:</span>
            <Switch
              onChange={() => togglePublishingRights(language)}
              checked={language.publishingRights}
              loading={settingRights}
              checkedChildren={<CheckOutlined type="check" />}
              unCheckedChildren={<CloseOutlined type="close" />}
            />
          </div>
        }
        <div className="card-container__row">
          <span>Available in the mobile app:</span>
          <Tooltip
            placement="bottom"
            overlayStyle={{ zIndex: 1000 }} 
            title={!(admin || language.publishingRights) ? 'Insufficient rights' : language.lastPublished ? null : 'Language has never been published'}>
            <Switch
              onChange={() => toggleMobileVisibility()}
              checked={language.showInMobile}
              loading={settingVisibility}
              disabled={!language.lastPublished || !(admin || language.publishingRights)}
              checkedChildren={<CheckOutlined type="check" />}
              unCheckedChildren={<CloseOutlined type="close" />}
            />
          </Tooltip>
        </div>
        <div className="card-container__row">
          <span>Last published:</span> <b>{
            language.lastPublished ?
              dateFns.format(new Date(language.lastPublished), 'MMMM Do, YYYY, HH:mm')
              : "Never"
          }</b>
        </div>
        <div className="card-container__row">
          <AsyncButton
            disabledTooltipText={`No rights to publish this language.`}
            disabled={!(admin || language.publishingRights)}
            confirmText={`Are you sure you want to publish ${language.language} with the translations from KONE translations?`}
            onConfirm={() => handlePublish(language)}
        >Publish to production</AsyncButton>

          {
            language.default ? <Tag color="green" className="default-element">Default</Tag>
            :
            <AsyncButton
              onConfirm={() => handleSetDefault(language)}
              className="languages-item-default-button default-element"
              confirmText={`Are you sure you want to set ${language.language} as the default language for ${countryName}?`}
              >Set as default
            </AsyncButton>
          }
        </div>
      </div>
    </Card>
  )
}

export default LanguageSelection