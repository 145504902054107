export function groupProducts(products, allProducts = []) {
  if (!products || !allProducts) return

  const groupsObject = products.reduce((prev, product) => {
    const original = allProducts.find(x => x.id === product.id) || {}

    const { productReleases, ...rest } = original

    const productToUse = {
      ...product,
      ...rest,
    }

  

    if (!productToUse.offeringLocation) {
      return prev
      // productToUse.offeringLocation = 'Uncategorized'
    }
    if (!prev[productToUse.offeringLocation]) {
      prev[productToUse.offeringLocation] = []
    }

    prev[productToUse.offeringLocation].push(productToUse)
    
    return prev
  }, {})

  return Object.entries(groupsObject)
}