const createTranslationService = (api) => {

  return {
    publish(code, country) {
      return api.post(`/translation/${code}/${country}`)
    },
    getAllForCountry(countryCode) {
      return api.get(`/translation/${countryCode}/all`)
    },
    givePublishingRights(frontline, code) {
      return api.post(`/translation/${frontline.id}/${frontline.hiddenId}/elevate/${code}`)
    },
    revokePublishingRights(frontline, code) {
      return api.post(`/translation/${frontline.id}/${frontline.hiddenId}/revoke/${code}`)
    },
    setMobileVisibility({ code, countryName, visibility }) {
      return api.post(`/translation/${code}/${countryName}/set-mobile-visibility`, { visibility} )
    }
  }
}


export default createTranslationService