import React, { useState, useMemo, useContext, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { message, Card, Radio, Select, DatePicker, ConfigProvider, Switch, } from 'antd'
import moment from 'moment';

import 'moment/locale/en-gb'
import locale from 'antd/lib/locale/en_GB'

import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner'
import useService from '../../../../services/useService'
import countryMapping from '../../../../constants/ktocCountryMapping.json'

import './Mobile.scss'
import ExportDataButton from '../../../../components/ExportDataButton/ExportDataButton';

const { RangePicker } = DatePicker;
const { Option } = Select


const excelHeaders = [
  { key: 'country', label: 'Country' },
  { key: 'platform', label: 'Platform' },
  { key: 'version', label: 'Version' },
  { key: 'Timestamp', label: 'Timestamp' },
]


/////////////////////////////////////////
// constant options part for the chart
const options = {
  animation: {
    duration: 1000,
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
      }
    }
  },
  scales: {
    xAxes: [{
      stacked: true,
      gridLines: { display: false },
    }],
    yAxes: [{
      stacked: true,
      ticks: {
        callback: function (value) { return value; },
      },
    }],
  },
  legend: { display: false }
}
//
/////////////////////////////////////////

const selectPlaceholders = {
  date: 'Select day',
  week: 'Select week',
  month: 'Select month',
  year: 'Select year',
  range: 'Select time range',
}

/**
 * 
 * @param {Object} props 
 * @param {string=} props.className 
 */
function Mobile(props) {
  const {
    className = ''
  } = props

  const [tableEntries, setTableEntries] = useState()
  const [loading, setLoading] = useState(true)
  const logService = useService('log')

  const [viewType, setViewType] = useState('time') // ['time','country']
  // const [showOldLogs, setShowOldLogs] = useState(false)
  const [stackedBy, setStackedBy] = useState('version')
  const [stacked, setStacked] = useState(false)
  const [pickerType, setPickerType] = useState('date')

  const [filters, setFilters] = useState({
    timePeriod: null,
    country: null,
  })

  useEffect(() => {
    if (!logService) return

    logService.getMobileEntries().then(result => {
      /* Example entry:
          {
            "timestamp": "2021-03-25T09:36:51.004Z",
            "version": "4.0.0",
            "country": "Finland",
            "userAgent": "UnityPlayer/2019.4.16f1 (UnityWebRequest/1.0, libcurl/7.52.0-DEV)",
            "platform": "Other"
          }
      */
      setTableEntries(result)
    }).catch(err => {
      console.error(err)
      message.error('Querying data failed', 5)
    }).finally(() => {
      setLoading(false)
    })
  }, [logService])

  const findTimeIndex = (labels, time) => {
    for (var i = labels.length - 1; i > -1; i--) {
      if (time >= labels[i]) {
        return i
      }
    }
  }

  const stringToRGB = (str = '') => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    let rgb = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 255;
      rgb[i] = value;
    }
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.7)`;
  }
  
  const getLabels = (data) => {
    const labels = []
    if (viewType === 'country') {
      data.forEach(item => {
        if (item?.country && item.country !== '' && labels.indexOf(item.country.toUpperCase()) === -1) {
          labels.push(item.country.toUpperCase())
        }
      })

    } else {
      if (!filters.timePeriod) {
        data.forEach(item => {
          if (item?.time) {
            const month = new Date(item.time).getMonth()
            const year = new Date(item.time).getFullYear()
            const label = new Date(year, month)
            if (labels.indexOf(Date.parse(label)) === -1) {
              labels.push(Date.parse(label))
            }
          }
        })
      } else {

        if (pickerType === 'date') {
          for (var i = 0; i < 24; i++) {
            labels.push(filters.timePeriod.from + i * (60 * 60 * 1000))
          }

        } else if (pickerType === 'week' || pickerType === 'month' || pickerType === 'range') {
          var labelTime = filters.timePeriod.from
          var index = 0
          while (labelTime < filters.timePeriod.to) {
            labelTime = filters.timePeriod.from + index * (24 * 60 * 60 * 1000)
            labels.push(labelTime)
            index++
          }

        } else {
          const year = new Date(filters.timePeriod.from).getFullYear()

          for (var i = 0; i < 12; i++) {
            const labelTime = new Date(year, i)
            labels.push(labelTime)
          }

        }

      }

    }

    return labels
  }

  const getChartLabels = (labels) => {
    if (viewType === 'country') {
      return labels.map(item => { return (countryMapping[item] ? countryMapping[item] : item) })
    } else {
      if (!filters.timePeriod) {
        return labels.map(item => {
          const itemDate = new Date(item)
          const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(itemDate)
          const year = itemDate.getFullYear()
          return month + ' ' + year
        })
      } else {

        return labels.map(item => {
          const itemDate = new Date(item)
          const monthText = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(itemDate)
          const month = itemDate.getMonth()
          const year = itemDate.getFullYear()
          const day = itemDate.getDate()
          const hour = itemDate.getHours()
          if (pickerType === 'date') {
            return hour + ':00 - ' + (hour + 1) + ':00'
          } else if (pickerType === 'week' || pickerType === 'month' || pickerType === 'range') {
            return day + '.' + (month + 1) + '.' + year
          }
          return monthText + ' ' + year
        })

      }

    }
  }

  const getDatasets = (labels,data) => {
    const values = []
    if( viewType === 'country') {
      if(!stacked) {

        data.forEach(item => {
          if (item?.country && item.country !== '') {        
            const cIndex = labels.indexOf(item.country.toUpperCase())
            if (values[cIndex]) {
              values[cIndex]++
            } else {
              values[cIndex] = 1
            }
          }
        })
    
        return [{
          label: 'Total',
          data: values,
          backgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'
    
        }]

      } else {

        var type = 'version'
        if(stackedBy === 'os') {
          type = 'platform'
        }
        const values = []
        const stackLabels = []
        data.forEach(item => {
          if (item?.country && item.country !== '') {
            const cIndex = labels.indexOf(item.country.toUpperCase())
            if (values[cIndex]) {
              if (values[cIndex][item[type]]) {
                values[cIndex][item[type]]++
              } else {
                values[cIndex][item[type]] = 1
              }
            } else {
              values[cIndex] = {}
              values[cIndex][item[type]] = 1
            }

            if (stackLabels.indexOf(item[type]) === -1) {
              stackLabels.push(item[type])
            }

          }
        })

        return stackLabels.map(item => {
          return {
            label: item,
            data: values.map(val => {
              if (val[item]) {
                return val[item]
              }
              return 0
            }),
            backgroundColor: stringToRGB(item+type),
            hoverBackgroundColor: stringToRGB(item+type),
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'
          }
        })
      }

    } else {
      if (!stacked) {

        const values = []

        data.forEach(item => {
          if (item?.time) {
            const tIndex = findTimeIndex(labels, item.time)
            if (values[tIndex]) {
              values[tIndex]++
            } else {
              values[tIndex] = 1
            }
          }
        })

        for (var i = 0; i < labels.length; i++) {
          if (!values[i]) {
            values[i] = 0
          }
        }
        return [{
          label: 'Total',
          data: values,
          backgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBackgroundColor: "rgba(55, 160, 225, 0.7)",
          hoverBorderWidth: 2,
          hoverBorderColor: 'lightgrey'

        }]
      } else {

        if(filters.country) {
          var type = 'version'
          if(stackedBy === 'os') {
            type = 'platform'
          }
          const values = labels.map(item => { return {} })
          const stackLabels = []
          data.forEach(item => {
            if (item?.time) {
              const cName = item.country ? item.country : 'Undefined'
              const tIndex = findTimeIndex(labels, item.time)
  
              if (item?.country && item.country === filters.country) {
                // const cIndex = labels.indexOf(item.country.toUpperCase())
                if (values[tIndex][item[type]]) {
                  values[tIndex][item[type]]++
                } else {
                  values[tIndex][item[type]] = 1
                }
    
                if (stackLabels.indexOf(item[type]) === -1) {
                  stackLabels.push(item[type])
                }
              }
            }
          })
  
          return stackLabels.map(item => {
            return {
              label: item,
              data: values.map(val => {
                if (val[item]) {
                  return val[item]
                }
                return 0
              }),
              backgroundColor: stringToRGB(item+type),
              hoverBackgroundColor: stringToRGB(item+type),
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'
            }
          })
        } else {

          const values = labels.map(item => { return {} })
          const stackLabels = []
          data.forEach(item => {
            if (item?.time) {
              const cName = item.country ? item.country : 'Undefined'
              const tIndex = findTimeIndex(labels, item.time)
              if (values[tIndex][cName]) {
                values[tIndex][cName]++
              } else {
                values[tIndex][cName] = 1
              }
  
              if (stackLabels.indexOf(cName) === -1) {
                stackLabels.push(cName)
              }
  
            }
          })
  
          return stackLabels.map(item => {
            const backgroundColorStr = countryMapping[item] || item
            return {
              label: countryMapping[item] ? countryMapping[item] : item,
              data: values.map(val => {
                if (val[item]) {
                  return val[item]
                }
                return 0
              }),
              backgroundColor: stringToRGB(backgroundColorStr),
              hoverBackgroundColor: stringToRGB(backgroundColorStr),
              hoverBorderWidth: 2,
              hoverBorderColor: 'lightgrey'
            }
          })
        }

      }
    }

  }

  const countriesAvailable = useMemo(() => {
    if (!tableEntries) return

    const countries = []
    tableEntries.forEach(item => {
      if (item?.country) {
        let itemCountry = item.country

        if (countries.indexOf(itemCountry) === -1) {
          countries.push(itemCountry)
        }
      }
    })

    return countries.sort()
  }, [tableEntries])

  const filteredData = useMemo( () => {

    if(!tableEntries) return 
    
    return tableEntries.filter( (entry) =>{

/*       if(!showOldLogs) {
        if(entry.platform !== 'Android' && entry.platform !== 'iOS') return false
      } */
      if(entry.platform !== 'Android' && entry.platform !== 'iOS') return false

      if (filters.timePeriod) {
        if (!(Date.parse(entry.Timestamp) >= filters.timePeriod.from && Date.parse(entry.Timestamp) <= filters.timePeriod.to)) return false
      }

      if (filters.country) {
        if (entry.country !== filters.country) return false
      }

      return true

    }).map( (item) => {
      return {
        ...item,
        time: Date.parse(item.Timestamp),
      }

    }).sort(function (a, b) { return a.time - b.time })

  }, [tableEntries, filters]) // , showOldLogs

  const graphData = useMemo(() => {
    if (!filteredData) return

    const labels = getLabels(filteredData)
    const datasets = getDatasets(labels, filteredData)

    return {
      labels: getChartLabels(labels),
      datasets: datasets
    }
  }, [filteredData, viewType, stackedBy, stacked])

  const onDateChange = (dates) => {

    if (!dates) {
      setFilters({ ...filters, timePeriod: null })
    } else {
      var d0, d1
      if (pickerType === 'range') {
        d0 = new Date(dates[0]._d)
        d1 = new Date(dates[1]._d)
      } else {
        const valueDate = new Date(dates._d)
        if (pickerType === 'date') {
          d0 = valueDate
          d1 = valueDate
        } else if (pickerType === 'week') {
          const weekStartCorrection = (valueDate.getDay() === 0 ? 6 : valueDate.getDay())
          const weekEndCorrection = (valueDate.getDay() === 0 ? 0 : (7 - valueDate.getDay()))
          d0 = new Date(valueDate.getFullYear(), valueDate.getMonth(), (valueDate.getDate() - weekStartCorrection))
          d1 = new Date(valueDate.getFullYear(), valueDate.getMonth(), (valueDate.getDate() + weekEndCorrection))
        } else if (pickerType === 'month') {
          d0 = new Date(valueDate.getFullYear(), valueDate.getMonth(), 1)
          d1 = new Date(valueDate.getFullYear(), valueDate.getMonth() + 1, 0)
        } else if (pickerType === 'year') {
          d0 = new Date(valueDate.getFullYear(), 0, 1)
          d1 = new Date(valueDate.getFullYear(), 11, 31)
        }
      }
      const startTime = new Date(d0.getFullYear(), d0.getMonth(), d0.getDate())
      const endTime = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), 23, 59, 59, 999)
      setFilters({
        ...filters,
        timePeriod: {
          from: (Date.parse(startTime)),
          to: Date.parse(endTime),
          rawDates: dates
        }
      })
    }

  }

  const PickerWithType = ({ type }) => {
    if (type === 'date') return <DatePicker onChange={onDateChange} defaultValue={(filters.timePeriod) ? moment(filters.timePeriod.rawDates) : null} placeholder={selectPlaceholders[type]} className="fullWidth" />
    if (type === 'range') return (
      <RangePicker
        picker="date"
        defaultValue={(filters.timePeriod && Array.isArray(filters.timePeriod.rawDates)) ? filters.timePeriod.rawDates.map((date: string) => moment(date._d)) : null}
        onChange={onDateChange}
        className="fullWidth"
      />
    )
    return <DatePicker picker={type} onChange={onDateChange} defaultValue={(filters.timePeriod) ? moment(filters.timePeriod.rawDates) : null} placeholder={selectPlaceholders[type]} className="fullWidth" />

  }

  return (
    <div data-testid="Mobile" className={`Mobile ${className}`}>
      <h2>Mobile application</h2>
      <LoadingSpinner loading={loading}>
        <div className="chartAndFilters">

          <div className="chart">
            <Bar data={graphData} options={options} />
          </div>

          <div className="filters">
            <Card>
              <div className="filter">
                <div className="filterHeader">Approach by</div>
                <div className="filterItem">
                  <Radio.Group onChange={e => setViewType(e.target.value)} value={viewType} optionType="button" buttonStyle="solid" className="fullWidth">
                    <Radio.Button value={'time'} className="halfWidth">Time</Radio.Button>
                    <Radio.Button value={'country'} className="halfWidth">Country</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              {/* <div className="filter">
                <div className="filterHeader">Include old logs</div>
                <div className="filterItem">
                  <Switch checked={showOldLogs} onChange={value => setShowOldLogs(value)} />
                </div>
              </div> */}
              <div className="filter">
                <div className="filterHeader">Separate the values</div>
                <div className="filterItem">
                  <Switch checked={stacked} onChange={value => setStacked(value)} />
                </div>
              </div>
              { (viewType === 'country' || filters.country) && stacked && 
                <div className="filter">
                  <div className="filterHeader">Stacked by</div>
                  <div className="filterItem">
                    <Radio.Group onChange={e => setStackedBy(e.target.value)} value={stackedBy} optionType="button" buttonStyle="solid" className="fullWidth">
                      <Radio.Button value={'version'} className="halfWidth">Version</Radio.Button>
                      <Radio.Button value={'os'} className="halfWidth">OS</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              }
              <div className="filter">
                <div className="filterHeader">Select the time period</div>
                <div className="filterItem">
                  <Select value={pickerType} onChange={setPickerType} className="fullWidth">
                    <Option value="date">Day</Option>
                    <Option value="week">Week</Option>
                    <Option value="month">Month</Option>
                    <Option value="year">Year</Option>
                    <Option value="range">Time range</Option>
                  </Select>
                  <ConfigProvider locale={locale}>
                    <PickerWithType type={pickerType} />
                  </ConfigProvider>
                </div>
              </div>

              {countriesAvailable &&
                <div className="filter">
                  <div className="filterHeader">Select country</div>
                  <div className="filterItem">
                    <Select defaultValue={filters.country} onChange={value => setFilters({ ...filters, country: value })} className="fullWidth" >
                      <Option value={null}>All</Option>
                      {countriesAvailable.map(item => {
                        return (
                          <Option key={item} value={item}>{(countryMapping[item] ? countryMapping[item] : item)}</Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              }

            </Card>

          </div>

        </div>
        <ExportDataButton
          filename="KTOC_log_export"
          sheetName="Data"
          data={filteredData}
          headers={excelHeaders}
        />
      </LoadingSpinner>
    </div>
  )
}

export default Mobile