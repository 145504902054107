import React, { useMemo } from 'react'
import { Breadcrumb, message, Spin } from 'antd'

import './ManageProductPage.scss'
import { Link } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { FrontlinesContext } from '../../store/frontlines'
import { TranslationsContext } from '../../store/translations'
import { APIContext } from '../../store/api'
import { useFrontline } from '../../store/frontlines/useFrontline'

function ManageProductPage(props) {
  const { match, history } = props

  const {
    loadFrontline,
  } = useContext(FrontlinesContext)


  const api = useContext(APIContext)
  const { getText } = useContext(TranslationsContext)
  const [ loading, setLoading ] = useState(true)
  const [ hasDesigns, setHasDesigns ] = useState(false)

  const { productId, releaseId, country } = match.params
  
  const productName = getText(`product-${productId}`)

  const frontline = useFrontline()

  const managingGlobal = useMemo(() => {
    if (!frontline) return false
    return frontline.hiddenId.toLowerCase() === 'global'
  }, [frontline])
  

  useEffect(() => {
    const apiToUse = managingGlobal ? 'globalData' : 'global'
    const packageApi = `/package/${apiToUse}/${productId}?no_release_fallback=true&releaseId=${releaseId}`

    const promises = []
    
    promises.push(loadFrontline({ country: country }))
    promises.push(api.get(packageApi))

    Promise.all(promises).then(([frontline, productData]) => {
      const { designs } = productData
      setHasDesigns(designs && designs.length > 0)
      setLoading(false)
    }, (err) => {
      console.error(err)
      message.error(err.message)
      history.push(`/frontline/${frontline.hiddenId}`)
    })
  }, [country, productId, releaseId])

  if (loading) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  return (
    <div className="ManageProductPage page">
       <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
        {
          !managingGlobal &&
          <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.country.name}/products`}>Products</Link>
        </Breadcrumb.Item>
        }
        <Breadcrumb.Item>
          {productName}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">{frontline.country.name} - {productName} { releaseId ? `(${releaseId})`: '' }</h1>
      <div className="block">
        <h2 className="block__title">
          <Link to={`offering`}>Manage offering</Link>
        </h2>
      </div>
      { hasDesigns && 
      <div className="block">
        <h2 className="block__title">
          <Link to={`predesigns`}>Manage predesigns</Link>
        </h2>
      </div>
      }
      { !managingGlobal &&
      <div className="block">
        <h2 className="block__title">
          <Link to={`local-themes`}>Manage local themes</Link>
        </h2>
      </div>
      }
    </div>
  )
}

export default ManageProductPage