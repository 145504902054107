import { useRef } from 'react'

// Custom error hook to use together with CheckboxGroup.
export const useComponentSelectionError = (disableSaving) => {
  const errorRef = useRef({})

  /**
     * Sets the error in the error object.
     * @param {String} name Name of the error.
     * @param {String} error The error message.
     */
  const handleError = (name, error) => {
    errorRef.current[name] = error
    const filteredErrors = Object.values(errorRef.current).filter(error => error.length > 0)
    disableSaving(filteredErrors.length > 0)
  }

  return {
    handleError,
    errors: errorRef.current
  }
}

export function addCategory(finishes = []) {
  return finishes.map(x => ({
    ...x,
    category: x.categories[0] || 'Uncategorized'
  }))
}