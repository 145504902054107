import KTOC from './KTOC'
import GenDoc from './GenDoc'
import Mobile from './Mobile'
import Web from './Web'
import RICK from './RICK'
import Salesforce from './Salesforce'

export default {
  KTOC,
  GenDoc,
  Mobile,
  Web,
  RICK,
  Salesforce,
}