import React, { useState, useContext } from 'react';
import { Menu, message, Avatar, Popover} from 'antd'
import { InboxOutlined, TeamOutlined, ReadOutlined, GlobalOutlined, BlockOutlined, QuestionCircleOutlined, UserOutlined, BarChartOutlined } from '@ant-design/icons'
import { Link, withRouter, useLocation, useParams } from "react-router-dom";

import { AuthContext } from '../../store/auth'

import './MainNav.scss';
import AsyncButton from '../AsyncButton';
import { MENU_ITEMS } from './menu-definition';

const { SubMenu } = Menu

function MenuItem(props) {
  const { adminOnly, availableForRick, icon: Icon, label, currentPage, to, suboptions = [], ...rest } = props

  const { loggedInUser } = useContext(AuthContext)

  const isAdmin = loggedInUser.role === "admin"
  const isRICK = loggedInUser.role === "rick"

  if (adminOnly && !isAdmin) {
    return null
  }

  if (isRICK && !availableForRick) {
    return null
  }

  if (!isAdmin && !isRICK && availableForRick) {
    return null
  }

  const availableSuboptions = suboptions.filter(x => isAdmin || !x.adminOnly )
  if (availableSuboptions.length > 1) {
    // For some reason ant menu nav does not correctly highlight the
    // main level option so manually setting the required class
    const selected = availableSuboptions.find(x => x.to === currentPage)
    return (
      <SubMenu className={`${selected ? 'ant-menu-submenu-selected': ''} random-class-but-for-some-reason-required`} icon={Icon ? <Icon /> : null} title={label} key={to} {...rest}>
        {
          availableSuboptions.map(option => {
            return <MenuItem key={option.to} {...option} />
          })
        }
      </SubMenu>
    )
  }

  return (
    <Menu.Item {...rest}>
      <Link to={{
        pathname: to,
        state: {
          from: window.location.href
        }
      }}>
        { Icon &&
          <Icon />
        }
        <span>{label}</span>
      </Link>
    </Menu.Item>
  )
}

function MainNav({ history }) {
  // console.log('>>> location', location)
  const [currentPage, setCurrentPage] = useState(() => {
    // Highlight the dashboard after coming from the '/login/success' route.
    if (window.location.hash.includes('/login')) return '/'

    return window.location.hash.replace('#', '')
  })

  const { loggedInUser, signOut } = useContext(AuthContext)

  const handleClick = (e) => {
    setCurrentPage(e.key)
  }

  const handleLogout = async () => {
    try {
      const success = await signOut()
    } catch (err) {
      message.error("Logging out unsuccesful! Try again in a few moments.")
      console.error(err)
    }
  }

  if (!loggedInUser) {
    return (
      null
    )
  }

  const isAdmin = loggedInUser.role === "admin"

  return (
    <div className="MainNav">
      <Menu
        className="MainNav__menu"
        onClick={handleClick}
        selectedKeys={[currentPage]} 
        mode="horizontal">
        {
          MENU_ITEMS.map(item => (
            <MenuItem key={item.to} {...item} currentPage={currentPage} />
          ))
        }
      </Menu>

    <div className="MainNav__extra">
      <div className="helpfile-link-container">
        <a className="helpfile-link" href='https://kone.sharepoint.com/sites/CarDesignerDepoyment/Shared%20Documents/General/Training%20material' target='_blank'> 
          <QuestionCircleOutlined className="helpfile-icon" />
        </a>
      </div>
      <div className="user-avatar-container">
        <Popover
          trigger="click"
          placement="bottom"
          className="user-popover"
          title={loggedInUser.email}
          content={(
            <AsyncButton 
              className="logout-button"
              onConfirm={handleLogout}
              confirmText="Are you sure you want to logout?"
            >
            Logout
            </AsyncButton>
          )}
        >        
          <Avatar className="user-avatar" icon={<UserOutlined />} />
        </Popover>
      </div>
    </div>
  </div>
  )
}

export default withRouter(MainNav)
