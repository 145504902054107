import React, { useState, useMemo, useContext } from 'react';
import createUserService from '../../services/users-api'
import { AuthContext } from '../auth';
import { APIContext } from '../api';

export const UsersContext = React.createContext();


/**
 * Provider for list of users and methods for manipulating the list.
 */
export const UsersProvider = ({children}) => {
  const [users, setUsers] = useState([])
  const api = useContext(APIContext)

  const userService = useMemo(() => createUserService(api), [])

  function updateUserInArray(user) {
    const userIndex = users.findIndex(u => u.id === user.id)
    const newUsers = [...users]
    newUsers[userIndex] = user
    setUsers(newUsers)
  }

  async function loadUsers() {
    const result = await userService.getAll()
    setUsers(result)
    return result
  }

  /**
   * 
   * @param {Object} user 
   * @param {string} user.email 
   * @param {string=} user.role 
   * @param {string[]=} user.frontlinesToAdd 
   */
  async function createUser(user) {
    if (users.find(x => x.email.toLowerCase() === user.email.toLowerCase())) {
      throw new Error(`A user with email ${user.email} already exists.`)
    }

    const result = await userService.create(user)
    setUsers([...users, result])
    return result
  }

  async function addFrontlineForUser(user, frontline) {
    const result = await userService.addFrontline(user, frontline)
    return result
  }

  async function removeFrontlineFromUser(user, frontline) {
    const result = await userService.removeFrontline(user, frontline)
    return result
  }

  async function saveFrontlinesForUser(user, frontlineCountries) {
    const result = await userService.setFrontlines(user, frontlineCountries)
    updateUserInArray(result)
    return result
  }

  async function saveRole(user, role) {
    const result = await userService.setRole(user, role)
    updateUserInArray(result)
    return result
  }

  async function deleteUser(user) {
    await userService.delete(user)
    const newUsers = users.filter(u => u.id !== user.id)
    setUsers(newUsers)
  }

  async function saveFeedbackSubscribtionState(user, frontlineId, newState) {
    return userService.setFeedbackSubscribtion(user, frontlineId, newState)
  }

  return (
    <UsersContext.Provider value={{
      users, loadUsers, createUser, 
      addFrontlineForUser, removeFrontlineFromUser, 
      saveFrontlinesForUser, saveRole, deleteUser, saveFeedbackSubscribtionState
    }}>
      {children}
    </UsersContext.Provider>
  )
}
export default UsersProvider;

export const UsersConsumer = UsersContext.Consumer;

export function useUsersContext() {
  return useContext(UsersContext)
}
