import React from 'react'
import { Form, Breadcrumb, Card, Affix, Button, Spin, Modal, Input, Radio, message, Divider } from 'antd'
import { PlusOutlined, DeleteOutlined, EditTwoTone, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import './ManageLocalDesignsPage.scss'
import { Link } from 'react-router-dom'
import dateFns from 'date-fns'
import { useState, useEffect, useContext, useMemo, useRef } from 'react'
import { FrontlinesContext } from '../../store/frontlines'
import { TranslationsContext } from '../../store/translations'
import { createErrorMessage } from '../../utils/error-utils'
import { ConfigContext } from '../../store/config'

function ManageLocalDesignsPage(props) {
  const { match, history } = props

  const {
    frontline,
    loadFrontline,
    addDesignToLocalTheme,
    removeDesignFromLocalTheme,
    updateLocalDesignName,
  } = useContext(FrontlinesContext)

  const config = useContext(ConfigContext)

  const [ addingDesign, setAddingDesign ] = useState(false)

  const { getText } = useContext(TranslationsContext)

  const { country, productId, themeId } = match.params

  const productName = getText(`product-${productId}`)

  const { pathname } = history.location
  
  const themeName = useMemo(() => {
    if (!frontline) return
    return frontline.themes[productId].find(x => x.id === themeId).name
  }, [frontline])

  const designs = useMemo(() => {
    if (!frontline) return
    return frontline.themes[productId].find(x => x.id === themeId).designs
    // return []
    // const { themes = {} } = frontline

    // return themes[productId] || []
  }, [frontline])
  

  useEffect(() => {
    if (country.toLowerCase() === 'global') {
      history.push('/')
      return
    }

    loadFrontline({ country })
      .then(() => {}, err => {
        console.error('Failed to load frontline:', err)
        history.push('/')
      })
  }, [country])

  

  if (!designs) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  
  return (
    <div className="ManageLocalDesignsPage page">
       <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
          <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/products`}>Products</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/product/${productId}`}>{productName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/product/${productId}/local-themes`}>Local themes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Local designs
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">Local designs for {productName}</h1>
      <Affix style={{ textAlign: 'right' }} offsetTop={80}>
        <div className="affix-content">

        { addingDesign ? 
          <div>
            <DesignFields
              actionText="Create"
              frontline={frontline}
              addDesignToLocalTheme={addDesignToLocalTheme}
              onCancel={() => setAddingDesign(false)}
              productId={productId}
              themeId={themeId}
            />
          </div> :
          <Button 
          type="primary"
          onClick={() => setAddingDesign(true)}
          icon={<PlusOutlined />}>Add a design</Button>
        }
        </div>
      </Affix>
      <div className="block">
        <h2 className="block__title">
          Designs for {themeName}
        </h2>
        <div className="cards-container">
          {
            designs.length === 0 ? <p>No designs for theme</p> :
            designs.map(design => {
              return (
                <DesignCard
                  key={design.id}
                  config={config}
                  productId={productId}
                  frontline={frontline}
                  themeId={themeId}
                  removeDesignFromLocalTheme={removeDesignFromLocalTheme}
                  updateLocalDesignName={updateLocalDesignName}
                  design={design} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.design
 * @param {Object} props.frontline
 * @param {Object} props.config
 * @param {string} props.productId
 * @param {string} props.themeId
 * @param {Function} props.updateLocalDesignName
 * @param {Function} props.removeDesignFromLocalTheme
 */
const DesignCard = props => {
  const { design, config, frontline, productId, themeId, 
    updateLocalDesignName, removeDesignFromLocalTheme } = props
  const [ editingName, setEditingName ] = useState(false)
  const [ designName, setDesignName ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ deleting, setDeleting ] = useState(false)

  function handleEditClick(design) {
    setEditingName(true)
  }
  
  async function handleDelete(design) {
    try {
      await removeDesignFromLocalTheme(frontline, { productId, themeId, designId: design.designId })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Removing the design',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  function handleInputChange(e) {
    setDesignName(e.target.value)
  }

  async function handleOKClick() {
    if (designName.trim().length === 0) {
      setDesignName('')
      setEditingName(false)
      return
    }

    try {
      setLoading(true)
      await updateLocalDesignName(frontline, { productId, themeId, designId: design.designId, newName: designName })

      setDesignName('')
      setEditingName(false)
      setLoading(false)
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Updating the name',
        attemptedItem: 'product'
      })

      message.error(errorMessage, 5)
      setLoading(false)
    }
  }

  function handleCancelClick() {
    setEditingName(false)
    setDesignName('')
  }

  const confirmDelete = (design) => {
    Modal.confirm({
      title: `Are you sure you want to delete the design "${design.name}"?`,
      content: 'This action will permanently delete the design.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleDelete(design)
      },
    })
  }

  return (
    <Card 
      className="card DesignCard" 
      title={editingName ? <div className="design-name-editor">
          <Input 
            onChange={handleInputChange}
            className="design-name-input" 
            placeholder={design.name}></Input> 
          <div className="buttons-container">
            <Button loading={loading} size="small" onClick={handleOKClick} type="primary" icon={<CheckOutlined />} />
            <Button size="small" onClick={handleCancelClick} icon={<CloseOutlined />} />
          </div>
        </div>
      : <div className="design-name">
        {design.name}
        <Button
          type="link"
          onClick={() => handleEditClick(design)}
          icon={<EditTwoTone />}
        />
      </div>}
      actions={[
        <div></div>,
        <div></div>,
        <DeleteOutlined onClick={() => confirmDelete(design)} key="delete" />
      ]}
    >
      <div className="design-image-container">
        <img className="design-image" src={design.image.url} alt="" />
      </div>
      <Divider />
      <a className="design-link"
        target="_blank" rel="noopener noreferrer"
        href={`${config.previewURL}/${design.country.alpha3.toLowerCase()}/${productId}/edit/${design.sapId}`}>
        View design
      </a>
      {/* <p className="designs">{design.image.url}</p> */}
      <p>Created: {dateFns.format(new Date(design.addedToThemeAt), 'MMMM Do, YYYY, HH:mm')}</p>

    </Card>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.frontline
 * @param {string} props.actionText
 * @param {string} props.productId
 * @param {string} props.themeId
 * @param {Function} props.onCancel
 * @param {Function} props.addDesignToLocalTheme
 */
const DesignFields = (props) => {
  const { 
    actionText, 
    onCancel, 
    productId, 
    themeId, 
    frontline,
    addDesignToLocalTheme,
  } = props

  const [designURL, setDesignURL] = useState('')
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputRef = useRef()

  const isValid = (value) => {
    if (value.trim().length === 0) return false
    // if (invalidUrl ???) {
    //   return false
    // }
    return true
  }

  const onInputChange = (e) => {
    setDesignURL(e.target.value)

    setValid(isValid(e.target.value))
  }

  const onConfirm = async () => {
    setLoading(true)

    try {
      await addDesignToLocalTheme(frontline, { productId, themeId, designURL })
      setDesignURL('')
      setLoading(false)
      onCancel()
    } catch (err) {
      setLoading(false)
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Updating the theme',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [inputRef])

  return (
    <div className="DesignFields">
      <Input
        style={{width: "26rem"}}
        className="DesignFields__url-input"
        onChange={onInputChange}
        ref={inputRef}
        value={designURL}
        onPressEnter={onConfirm}
        placeholder="Design URL">
      </Input>
      <div className="DesignFields__buttons">
        <Button
          className="DesignFields__btn"
          onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          className="DesignFields__btn"
          disabled={!valid || loading}
          loading={loading}
          onClick={onConfirm}>
          {actionText}
        </Button>
      </div>
    </div>
  )
}

export default ManageLocalDesignsPage